import { Establishment, getTokenProps, userProps } from '../../interfaces';

export const SET_USER = 'SET_USER';
export const SET_AUTH = 'SET_AUTH';
export const SET_ESTABLISHMENT = 'SET_ESTABLISHMENT';
/**
 * Save passed on data as establishment
 * @param establishment
 * @returns void
 */
export const setUser = (user: userProps) => {
  return { type: SET_USER, payload: user };
};

/**
 * Set token data
 * @param auth
 * @returns
 */
export const setAuth = (auth: getTokenProps) => {
  return { type: SET_AUTH, payload: auth };
};
/**
 *
 * @param establishment
 * @returns
 */
export const setEstablishment = (establishment: Establishment[]) => {
  return { type: SET_ESTABLISHMENT, payload: establishment };
};
