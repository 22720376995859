import React, { FC, useState } from 'react';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button, Heading } from '../../../components';
import OrderRefundCard from '../../../components/orders/OrderRefundCard';
import { toCurrency } from '../../../helpers';
import { Establishment } from '../../../interfaces';
import { OrderProps } from '../../../interfaces/Order';
import { useOrderRefund } from './OrdersRefund.Hooks';

interface Props {
  EstablishmentsData: Establishment;
  updating: boolean;
}

const OrdersRefund: FC<Props> = ({ EstablishmentsData, updating }) => {
  const [search, setSearch] = useState('');
  const { getFetch, fetchResponse, fetchLoading } = useOrderRefund(
    EstablishmentsData.id
  );
  const response = fetchResponse.data || [];
  const filterResponse = response.filter(
    (item: OrderProps) =>
      item.code.toLowerCase().includes(search) ||
      item.user_name.toLowerCase().includes(search) ||
      item.total.toString().toLowerCase().includes(search)
  );
  const { push } = useHistory();

  return (
    <>
      <section className="history__section flex flex-col justify-start items-start w-full h-full">
        <div className="flex justify-between items-center w-full">
          <Heading>Order History</Heading>
          <div className="flex">
            <Button
              type="light"
              extraClasses="flex items-center mr-4"
              action={() => push('/orders')}
            >
              <FaArrowLeft className="mr-4" /> orders
            </Button>
            <Button action={() => getFetch(true)} disabled={fetchLoading}>
              {fetchLoading ? 'fetching' : 'Refresh'}
            </Button>
          </div>
        </div>
        <div className="flex flex-col my-8 w-full">
          <label className="font-bold" htmlFor="search__history">
            Search
          </label>
          <div className="w-full relative mt-4">
            <input
              className="p-4 shadow w-full"
              placeholder="Enter order code, date, price, recipient name"
              id="search__history"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <FaSearch className="text-primary absolute right-4 top-1/2 transform -translate-y-1/2" />
          </div>
        </div>
        <div className="history__container grid grid-cols-2 gap-9 w-full">
          {fetchResponse.data &&
            filterResponse.map((receipt: OrderProps, i: number) => {
              return (
                <>
                  <OrderRefundCard
                    receipt={receipt}
                    establishmentId={EstablishmentsData.id}
                  />
                </>
              );
            })}
        </div>
      </section>
    </>
  );
};

export default OrdersRefund;
