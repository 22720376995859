import React from 'react';
import { Icon } from '../shared/Icons';
import styled from 'styled-components';

interface ModalProps {
  closeModal: Function;
  noButton?: boolean;
  width?: string;
}

const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
  noButton,
  width = '70%'
}) => {
  return (
    <React.Fragment>
      <Overlay className="modal__overlay" onClick={() => closeModal()} />
      <ModalContainer className="modal" width={width}>
        {!noButton && (
          <IconContainer onClick={() => closeModal()}>
            <Icon icon="Times" type="circleFull" color="white" />
          </IconContainer>
        )}
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </React.Fragment>
  );
};

export default Modal;

const Overlay = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: #0000005c;
  left: 0;
  top: 0;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -24px;
  right: -24px;
`;

const ModalContainer = styled.div<{ width: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  background-color: white;
  border-radius: 4px;
  padding: 24px;
  min-width: ${props => props.width};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  flex-direction: column;
  max-height: 90vh;
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
`;
