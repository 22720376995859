import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import { formatText } from '../../utils/Transform';
import ChartCard from '../Charts/ChartCard';
import { postProps } from '../News/New';
import { Container, Icon, Row } from '../shared';
import { Paragraph, Title } from '../Styles/Text';

interface PostProps {
  slug?: string;
}

type PostRouteProps = {
  slug?: string;
};

type WithRouterProps = RouteComponentProps<PostRouteProps>;
type AllProps = WithRouterProps & PostProps;

const Post: React.FC<AllProps> = ({ slug, match }) => {
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const useSlug = slug || match.params.slug;
  /**
   *
   */
  const getPost = useGet(
    apiHeaderAuth(getToken.acces_token),
    `post/show/${useSlug}`,
    true
  );
  /**
   *
   */
  const post: postProps = getPost.fetchResponse;
  return (
    <Container>
      {post.id ? (
        <ChartCard>
          <Title>{post.title}</Title>
          <Paragraph>{post.description}</Paragraph>
          <img src={post.image} alt={post.title} />
          <Row JContent="flex-start">
            <Paragraph margin="0 16px 0 0">
              <Icon icon="User" color={ThemeColors.primaryColor} size={16} /> By{' '}
              {post.author}
            </Paragraph>
            <Paragraph>
              <Icon
                icon="Calendar"
                color={ThemeColors.primaryColor}
                size={16}
              />{' '}
              {post.created_at && post.created_at.split('T')[0]}
            </Paragraph>
          </Row>
          {formatText(post.content).map((item: any) => {
            return (
              <>
                {item.title && <Title>{item.title}</Title>}
                {item.text && <Paragraph>{item.text}</Paragraph>}
              </>
            );
          })}
          <Paragraph>
            With kind regards, <br /> {post.author}
          </Paragraph>
        </ChartCard>
      ) : (
        <p>Loading</p>
      )}
    </Container>
  );
};

export default withRouter(Post);
