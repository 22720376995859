import React, { useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { ThemeColors } from '../../config';
import { useClickOutSide } from '../../hooks/useClickOutside';
import { Column, Icon, Row } from '../shared';
import { Paragraph, Title } from '../Styles/Text';
//
interface PopOverInterface {
  close: () => void;
  title?: string;
  sub?: string;
  width?: string;
  margin?: string;
  top?: string;
  right?: string;
}
//
const PopOver: React.FC<PopOverInterface> = ({
  close,
  title,
  sub,
  width = 'fit-content',
  margin,
  top = '8px',
  right = '85px',
  children
}) => {
  /**
   *
   */
  const popOverRef = useRef(null);
  useClickOutSide(popOverRef, close);
  /**
   *
   */
  return (
    <PopoverLayout
      width={width}
      margin={margin}
      top={top}
      right={right}
      ref={popOverRef}
    >
      <Row margin="0" JContent="flex-start" AItems="flex-start">
        <Column margin="0" justify="flex-start" align="flex-start">
          {title && (
            <Title margin="0 8px 0 0" fontSize="18px">
              {title}
            </Title>
          )}
          {sub && (
            <Paragraph margin="0 0 8px 0" color="#0000006e" fontSize="12px">
              {sub}
            </Paragraph>
          )}
        </Column>
        <IconWrapper onClick={() => close()}>
          <Icon color={ThemeColors.primaryColor} icon="Times" size={16} />
        </IconWrapper>
      </Row>

      {children}
    </PopoverLayout>
  );
};

export default PopOver;
type PopoverLayoutProps = {
  width: string;
  margin?: string;
  top?: string;
  right?: string;
};

const FadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const MoveAnimation = keyframes`
  from { top: -16px; }
  to { top: 0; }
`;

const PopoverLayout = styled.div<PopoverLayoutProps>`
  position: absolute;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 16px;
  top: 0;
  right: ${props => props.right};
  margin: ${props => props.margin || '16px'};
  width: ${props => props.width};
  animation: ${FadeInAnimation} 0.5s linear, ${MoveAnimation} 0.5s linear;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;
