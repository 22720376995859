import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isNullishCoalesce } from 'typescript';
import useFade from '../../hooks/useFade';
import { removeAlert } from '../../redux/actions';
import { Column, Icon, Row } from '../shared';
import {
  AlertSuccess,
  AlertWarning,
  AlertError,
  AlertInfo
} from './Alert.style';

const types = {
  success: AlertSuccess,
  warning: AlertWarning,
  error: AlertError,
  info: AlertInfo
};

export type AlertFields = {
  title: string;
  message: string;
  type: keyof typeof types;
};

interface AlertProps {
  type: keyof typeof types;
  close: Function;
  title: string;
  message: string;
  id?: string;
  show?: boolean;
  removeAlert: Function;
}

const Alert: React.FC<AlertProps> = ({
  type,
  close,
  title,
  message,
  id,
  show = true,
  ...props
}) => {
  const ref = useRef(null);
  useEffect(() => {
    // setTimeout(() => {
    //   //
    //   if (ref && ref.current) {
    //     let newRef: any = ref;
    //     newRef.current.style.opacity = 0;
    //   }
    // }, 4000);
    // setTimeout(() => {
    //   if (ref && ref.current) {
    //     let newRef: any = ref;
    //     newRef.current.style.display = 'none';
    //   }
    // }, 4500);
    // return () => {
    //   cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // create selected element
  const AlertTypes = types[type];
  const [fadeProps] = useFade(show);
  //
  return (
    <AlertTypes ref={ref} id={id} {...fadeProps} className="Alert">
      <Row>
        <IconWrapper>
          <Icon
            color="white"
            icon={type === 'success' ? 'Check' : 'Times'}
            size={16}
          />
        </IconWrapper>
        <Column align="flex-start">
          {title && <AlertTitle>{title}</AlertTitle>}
          {message && <AlertMessage>{message}</AlertMessage>}
        </Column>
      </Row>
      <CrossWrapper onClick={() => close()}>
        <Icon color="white" icon="Times" size={16} />
      </CrossWrapper>
    </AlertTypes>
  );
};

const mapStateToProps = (state: any) => {
  const { Alert, Orders } = state;
  return {
    alerts: Alert,
    orders: Orders
  };
};

const mapDispatchToProps = {
  removeAlert
};

export default connect(mapStateToProps, mapDispatchToProps)(Alert);

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

const AlertTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
`;

const AlertMessage = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-top: 4px;
`;

const CrossWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  svg,
  path {
    color: white;
  }

  cursor: pointer;
`;
