import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import copy from '../../data/copy.json';
import { apiHeaderAuth, tax, toCurrency } from '../../helpers';
import { useGetData } from '../../hooks/useGetData';
import { Column, Content } from '../shared/Layout';

const OrdersTotal = ({ title, url, getToken }) => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [data, setData] = useState([]);
  const [resend, setResend] = useState(true);
  /**
   * Get data
   */
  useGetData(
    apiHeaderAuth(getToken.access_token),
    url,
    resend,
    setResend,
    setData
  );

  /**
   * If orders exist change loading state
   */
  useEffect(() => {
    if (data.data && data.data.length > 0) {
      setTotalOrders(data.data.length);
      let total = 0;
      data.data.map(item => {
        total += Number(item.total) - config.drinks.transactionFee;
      });
      setTotalPrice(total.toFixed(2));
    }
  }, [data]);

  return (
    <Column>
      <Title>{title}</Title>
      <Content>
        <TotalContainer>
          <TotalContainerItem>
            <h1>{copy.orders.totalOrders.title}</h1>
            <TotalOrders>
              <h4>{totalOrders}</h4>
            </TotalOrders>
          </TotalContainerItem>
          <TotalContainerItem>
            <h1>{copy.orders.totalOrders.omzetIncl}</h1>
            <h2>{toCurrency(totalPrice)}</h2>
          </TotalContainerItem>
          <TotalContainerItem>
            <h1>{copy.orders.totalOrders.omzetExcl}</h1>
            <TotalPrices>
              <li>
                {copy.orders.tax.incl} {toCurrency(totalPrice)}
              </li>
              <li>
                {copy.orders.tax.btw} {config.drinks.perecentage + '%'}
              </li>
              <li>
                {copy.orders.tax.btw}{' '}
                {toCurrency(
                  totalPrice - tax(false, totalPrice, config.drinks.perecentage)
                )}
              </li>
              <li>
                {copy.orders.tax.excl}{' '}
                {toCurrency(
                  totalPrice -
                    (totalPrice -
                      tax(false, totalPrice, config.drinks.perecentage))
                )}
              </li>
            </TotalPrices>
          </TotalContainerItem>
        </TotalContainer>
      </Content>
    </Column>
  );
};

OrdersTotal.propTypes = {
  totalOrders: PropTypes.number,
  totalPrice: PropTypes.number
};

export default OrdersTotal;

const TotalContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px;
  padding: 20px;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  height: fit-content;
  border-radius: 4px;

  nav {
    width: 100%;
  }
`;

const TotalContainerItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  color: ${ThemeColors.titleFontColor};
  width: 30%;
  justify-content: center;
  h1 {
    width: 100%;
  }
`;
const TotalOrders = styled.div`
  color: white;
  display: flex;
  justify-content: space-around;
  width: 100px;
  height: 100px;
  font-size: 32px;
  border-radius: 50%;
  background-color: ${ThemeColors.primaryColor};
  align-content: center;
  flex-wrap: wrap;
  p {
    margin: 0px;
  }
  nav {
    width: 100%;
  }
  @media (max-width: 1224px) {
    justify-content: center;
    align-items: center;
    h4 {
      margin: 0;
    }
  }
`;

const TotalPrices = styled.ul`
  width: initial;
  margin-left: 16px;
  li {
    margin: 8px 0;
    display: flex;
    justify-content: space-between;
  }
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 900;
  width: 100%;
  text-align: right;
  margin-right: 120px;
`;
