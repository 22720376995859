/**
 * compares to values and returns a true or false
 * @param {string} a
 * @param {string} b
 *
 * @returns {boolean}
 */

import { AddonProps } from '../interfaces/Addons';
import { ProductProps } from '../interfaces/Product';

const doesInclude = (a: string, b: string) => {
  //console.log(a);
  if (!a || !b) return false;
  return a.toUpperCase().includes(b.toUpperCase());
};

/**
 * check if an item in the array compares to the search values
 * @param {array} a
 * @param {string} b
 *
 * @returns {boolean}
 */

const checkArray = (a: Array<AddonProps>, b: string): boolean | void => {
  a.map(item => {
    return (
      doesInclude(item.name, b) ||
      doesInclude(item.price.toString(), b) ||
      doesInclude(item.description, b)
    );
  });
};

/**
 * places values in the doesInclude and returns a true or false
 * @param {string} searchValue
 * @param {object} data
 * @returns {boolean}
 */
export const SearchFilter = (searchValue: string) => (
  data: ProductProps & AddonProps
) => {
  if (!searchValue) return true;
  //Comparing searchValue with data object with the doesInclude function.
  // Using the || statement to be able to search multiple of data's value's
  return (
    doesInclude(data.name, searchValue) ||
    doesInclude(data.name, searchValue) ||
    doesInclude(data.description, searchValue) ||
    doesInclude(data.price, searchValue) ||
    (data.addons && checkArray(data.addons, searchValue))
  );
};
