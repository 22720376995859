import React, { useState } from 'react';
import { ThemeColors } from '../../../config';
import { OrderProps } from '../../../interfaces/Order';
import { ChartIcon, Column } from '../../shared';
import { Icon } from '../../shared/Icon';
import LineChart from './LineChart';
import ToolTip from './Tooltip';
type dataProps = {
  x: number;
  y: number;
  label: string;
};
interface LineChartContainerProps {
  id: number;
  type: string;
  fetchResponse: any;
  fetchLoading: boolean;
  svgWidth?: number;
}

const LineChartContainer: React.FC<LineChartContainerProps> = ({
  id,
  type,
  fetchResponse,
  fetchLoading,
  svgWidth
}) => {
  const [hoverLoc, setHoverLoc] = useState(null);
  const [activePoint, setActivePoint] = useState(null);

  const convertData = () => {
    return fetchResponse.data
      .filter((filter: OrderProps) => filter.order.length > 0)
      .map((orders: OrderProps, index: number) => {
        let qty = 0;
        //let price = 0;
        orders.order.forEach(item => {
          qty += item.pivot.qty;
          //price += Number(item.price) * Number(item.pivot.qty);
        });
        return {
          x: index,
          y: type === 'orders' ? qty : Number(orders.total),
          label: orders.created_at
        };
      });
  };

  const slimData = () => {
    // create array of dates
    const converted: [dataProps] = convertData();

    // create empty array
    let newConverted: [dataProps] | any = [];

    // check each element
    converted.forEach(element => {
      // split new date to remove seconds
      const formatDate = element.label.split(' ')[1].split(':');
      // create date to compare
      const date = `${element.label.split(' ')[0]} ${formatDate[0]}:${
        formatDate[1]
      }`;
      // check if date already exists in the new array
      if (newConverted.find((item: dataProps) => item.label === date)) {
        // add current elements y value to the new
        newConverted = newConverted.map((dateElement: dataProps) =>
          dateElement.label === date
            ? {
                x: dateElement.x,
                y: dateElement.y + element.y,
                label: dateElement.label
              }
            : dateElement
        );
      }
      // if it doesn't exist add it to the array
      else {
        newConverted = [
          ...newConverted,
          {
            x: element.x,
            y: element.y,
            label: `${date}`
          }
        ];
      }
    });
    return newConverted;
  };

  const handleChartHover = (hoverLoc: any, activePoint: any) => {
    setHoverLoc(hoverLoc);
    setActivePoint(activePoint);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="popup">
          {hoverLoc ? (
            <ToolTip
              hoverLoc={hoverLoc}
              activePoint={activePoint}
              price={type === 'finances'}
            />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="chart">
          {!fetchLoading && fetchResponse.data.length > 0 ? (
            <LineChart
              data={slimData()}
              onChartHover={(a: any, b: any) => handleChartHover(a, b)}
              price={type === 'finances'}
              svgWidth={svgWidth}
            />
          ) : (
            <Column>
              <ChartIcon color="#dd016b36">
                <Icon getIcon="ChartBar" color={ThemeColors.primaryColor} />
              </ChartIcon>
              <h1> No orders found</h1>
            </Column>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineChartContainer;
