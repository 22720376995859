import React from 'react';
import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  Button,
  ChartCard,
  Column,
  Container,
  Heading,
  MainButton,
  OrderCard,
  OrderDetailCard,
  Row
} from '../../components';
import { ThemeColors } from '../../config/themeSettings';
import { OrderProps } from '../../interfaces/Order';
import { FetchOrders, saveOrders, setAlert } from '../../redux/actions';
import { OrdersProps, useOrders, useOrdersData } from './';

const Orders: React.FC<OrdersProps> = ({
  EstablishmentsData,
  orders,
  FetchOrders,
  saveOrders,
  setAlert
}) => {
  //
  const { push } = useHistory();
  //
  const {
    selectedTab,
    setSelectedTab,
    groupedOrders,
    setGroupedOrders,
    selectedOrder,
    setSelectedOrder,
    deliveryTime,
    setDeliveryTime
  } = useOrders(EstablishmentsData, orders);
  //
  const { sendPut, sendPost } = useOrdersData(
    EstablishmentsData,
    deliveryTime,
    selectedOrder,
    setSelectedOrder,
    setGroupedOrders,
    setAlert,
    saveOrders
  );

  const updateOrder = (send: boolean, status?: string) => {
    if (status === 'reject') {
      sendPost(true);
    } else {
      if (
        status === '15 min' ||
        status === '30 min' ||
        status === '45 min' ||
        status === '60 min'
      ) {
        setDeliveryTime(status);
      }
      sendPut(true);
    }
  };

  return (
    <Container>
      <Row JContent="space-between">
        <Heading>Orders</Heading>
        <div className="flex">
          <Button
            type="light"
            extraClasses="flex items-center mr-4"
            action={() => push('/orders/refunded')}
          >
            Refunded <FaArrowRight className="ml-4" />
          </Button>
          <Button
            type="light"
            extraClasses="flex items-center mr-4"
            action={() => push('/orders/history')}
          >
            History <FaArrowRight className="ml-4" />
          </Button>
          <Button action={() => FetchOrders()} disabled={orders.loading}>
            {orders.loading ? (
              <>
                <FaSpinner style={{ marginRight: '8px' }} /> Refreshing
              </>
            ) : (
              'Refresh'
            )}
          </Button>
        </div>
      </Row>

      <Row JContent="flex-start">
        <MainButton
          action={() => setSelectedTab('waiting')}
          type="tab"
          active={selectedTab === 'waiting'}
          color={ThemeColors.error}
        >
          Waiting
          {groupedOrders.waiting && groupedOrders.waiting.length > 0 && (
            <Count color={ThemeColors.error}>
              {groupedOrders.waiting.length}
            </Count>
          )}
        </MainButton>
        <MainButton
          action={() => setSelectedTab('prepairing')}
          type="tab"
          active={selectedTab === 'prepairing'}
          color={ThemeColors.warning}
        >
          Preparing{' '}
          {groupedOrders.prepairing &&
            groupedOrders.prepairing.length > 0 &&
            `(${groupedOrders.prepairing.length})`}
        </MainButton>
        <MainButton
          action={() => setSelectedTab('ready')}
          type="tab"
          active={selectedTab === 'ready'}
          color={ThemeColors.success}
        >
          Ready
          {groupedOrders.ready &&
            groupedOrders.ready.length > 0 &&
            `(${groupedOrders.ready.length})`}
        </MainButton>
        <MainButton
          action={() => setSelectedTab('all')}
          type="tab"
          active={selectedTab === 'all'}
        >
          All
          <Count color={ThemeColors.primaryColor}>
            {groupedOrders.all.length}
          </Count>
        </MainButton>
      </Row>
      <Row AItems="flex-start">
        <ScrollContainer>
          <Column width="100%">
            {groupedOrders[selectedTab] &&
              groupedOrders[selectedTab].map(
                (item: OrderProps, index: number) => {
                  const active = item.id === selectedOrder?.id;
                  const colors = {
                    complete: ThemeColors.success,
                    prepairing: ThemeColors.warning,
                    waiting: ThemeColors.error
                  };
                  const activeColor = colors[item.status];
                  return (
                    <CardWrapper
                      onClick={() => setSelectedOrder(item)}
                      active={active}
                      borderColor={activeColor}
                    >
                      <OrderCard order={item} key={index} />
                    </CardWrapper>
                  );
                }
              )}
          </Column>
        </ScrollContainer>

        <Row AItems="flex-start" margin="0 8px" width="60%">
          {selectedOrder && orders.response.length > 0 ? (
            <OrderDetailCard order={selectedOrder} update={updateOrder} />
          ) : (
            <ChartCard title="No order selected" width="100%" margin="8px 0">
              <Blank src="/assets/svg/blank.svg" />
            </ChartCard>
          )}
        </Row>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  const { Orders } = state;
  return {
    orders: Orders
  };
};
//
const mapDispatchToProps = { FetchOrders, saveOrders, setAlert };
//
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
//
type CardWrapperProps = {
  active: boolean;
  borderColor: string;
};
export const CardWrapper = styled.span<CardWrapperProps>`
  width: 100%;
  margin: 8px 0;
  border-radius: 8px;
  transition: border 0.2s ease, transform 1s ease;
  ${props =>
    props.active &&
    css`
      transform: scale(1.02);
      border: solid 2px;
    `}

  border-color: ${props => props.borderColor};
`;

const Count = styled.span<{ color: string }>`
  background-color: ${props => props.color};
  padding: 8px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -20px;
  top: 0;
  color: white;
`;

export const ScrollContainer = styled.div`
  width: 40%;
  max-height: 100vh;
  overflow-y: scroll;
  padding: 0 8px;
`;

export const Blank = styled.img`
  width: 100%;
`;
