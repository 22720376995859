import React from 'react';
import styled, { css } from 'styled-components';
import { Row } from '../shared';

interface ChartCardProps {
  title?: string;
  width?: string;
  margin?: string;
  btnAction?: Function;
  btnText?: string;
  id?: string;
  borderColor?: string;
  active?: boolean;
}

const ChartCard: React.FC<ChartCardProps> = ({
  title,
  width = 'initial',
  margin,
  children,
  btnAction,
  btnText,
  id,
  borderColor,
  active = true
}) => {
  return (
    <Card
      margin={margin}
      width={width}
      className="Chart__card"
      id={id}
      borderColor={borderColor}
      active={active}
    >
      {title && (
        <Row JContent="space-between">
          <CardTitle>{title}</CardTitle>
        </Row>
      )}
      {children}
    </Card>
  );
};

export default ChartCard;
type CardProps = {
  width: string;
  margin?: string;
  borderColor?: string;
  active?: boolean;
};
const Card = styled.div<CardProps>`
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  margin: ${props => props.margin || '16px'};
  width: ${props => props.width};
  opacity: ${props => (props.active ? 1 : 0.5)};
  ${props =>
    props.borderColor &&
    css`
      border: solid 2px ${props.borderColor};
    `}
`;

const CardTitle = styled.h3`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #011627;
  margin-top: 0;
`;
