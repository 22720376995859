import { config } from '../config';

export const createBaseUrl = () => {
  const url = {
    localIp: 'http://127.0.0.1:8000',
    local: 'rapiobackend.phpmyadmin',
    test: 'test.rapio.store',
    live: 'rapio.store',
    heroku: 'rappio.herokuapp.com',
    legacy: 'rappio.nl'
  };
  if (process.env.NODE_ENV === 'development') {
    //return url.test;
    //return 'club.rapio.store';
    return process.env.REACT_APP_API_PATH;
  } else {
    return process.env.REACT_APP_API_PATH;
  }
};

const createUrl = () => {
  return `${config.security}://${createBaseUrl()}/api/v1/`;
};

export const ApiUrl = createUrl();
