import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThemeColors } from '../../config';
import { apiHeader } from '../../helpers/ApiHelpers';
//import { usePost } from '../../hooks/postData';
import { usePost } from '../../hooks/usePost';
import { Container } from '../shared';
import LoginForm from './LoginForm';
import { setAuth } from '../../redux/actions';
interface LoginProps {
  setAuth: Function;
  setVerified: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Function;
  getUser: Function;
}

const Login: React.FC<LoginProps> = ({
  setAuth,
  setVerified,
  loading,
  setLoading,
  getUser
}) => {
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const [canLogin, setCanLogin] = useState(true);
  const [message, setMessage] = useState('');
  const [formType, setFormType] = useState({ type: 'login', url: 'login' });
  const {
    sendPost,
    postResponse,
    postLoading,
    postError,
    errorResponse
  } = usePost(formType.url, apiHeader, {
    email: email,
    username: email,
    password: password
  });
  /**
   * validate username and password length on password and email change
   */
  useEffect(() => {
    const validEmail = email.length > 4;
    const validPassword = password.length > 4;
    validEmail && validPassword && setCanLogin(false);
  }, [canLogin, email.length, loading, password.length, setEmail, setpassword]);
  /**
   * If logged in was succes create session
   */
  useEffect(
    () => {
      if (
        postResponse &&
        postResponse.status === 200 &&
        formType.type === 'login'
      ) {
        sessionStorage.setItem('user_token', JSON.stringify(postResponse.data));
        setAuth(postResponse.data);
        getUser(postResponse.data.access_token);
        setVerified(false);
      } else if (
        postResponse &&
        postResponse.status === 200 &&
        formType.type === 'forget'
      ) {
        setMessage(postResponse.data.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postResponse]
  );

  useEffect(() => {
    setMessage('');
  }, [formType]);

  useEffect(() => {
    console.log('error', postError, errorResponse);
    if (postError && errorResponse.response && formType.type === 'login') {
      setMessage(
        errorResponse.response.data.message || errorResponse.response.data
      );
    } else if (
      postError &&
      errorResponse.response &&
      formType.type === 'forget'
    ) {
      setMessage(errorResponse.response.data.message);
    }
  }, [postError, errorResponse, formType.type]);

  /**
   * set email value to email input
   * @param {Object} event
   */
  const changeEmail = (event: string) => {
    setEmail(event);
  };
  /**
   * set password value to email input
   * @param {Object} event
   */
  const changePassword = (event: string) => {
    setpassword(event);
  };

  return (
    <Container
      BColor={ThemeColors.menuBackgroundColor}
      JContent="flex-end"
      AItems="flex-end"
      CHeight="100vh"
      BImage="https://rapio.store/media/login-bg.jpg"
    >
      {/* {process.env.NODE_ENV === 'development' && (
        <MainButton action={() => switchTheme()}>Switch Theme</MainButton>
      )} */}
      <LoginForm
        email={email}
        password={password}
        setPassword={changePassword}
        setEmail={changeEmail}
        setSend={sendPost}
        loadingLogin={postLoading}
        //setLoadingLogin={setLoadingLogin}
        message={message}
        canLogin={
          !(
            (email.length > 0 && password.length > 0) ||
            (formType.type === 'forget' && email.length > 0)
          )
        }
        setFormType={setFormType}
        type={formType.type}
        //setLoading={setLoading}
      />
    </Container>
  );
};

const mapDispatchActionCreator = {
  setAuth
};

export default connect(null, mapDispatchActionCreator)(Login);
