import React from 'react';
import styled from 'styled-components';
import { ListHeaderProps } from '../../interfaces/List';
import ListItem from './ListItem';

const ListHeader: React.FC<ListHeaderProps> = ({ headerItems, align }) => {
  return (
    <Header>
      {headerItems.map(headerItem => (
        <ListItem color="#979797" bold={600} align={align}>
          {headerItem}
        </ListItem>
      ))}
    </Header>
  );
};

export default ListHeader;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #d8d8d8;
`;
