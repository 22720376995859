import React, { useEffect } from 'react';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { usePost } from '../../hooks/usePost';
import { Establishment } from '../../interfaces';
import { SeatProps } from '../../interfaces/Seat';
import Input from '../Form/Input';
//
import { Column, MainButton, Row } from '../shared';
import { Icon } from '../shared/Icons';
import { Label } from '../Styles/Text';
import useEditSeat from './EditSeat.Hook';

interface EditOpeningDaysProps {
  seat: SeatProps;
  establishmentData: Establishment;
  setWarning: Function;
  setAlerts: Function;
}

const EditSeat: React.FC<EditOpeningDaysProps> = ({
  seat,
  establishmentData,
  setWarning,
  setAlerts
}) => {
  /**
   * Get stored data
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const { name, setName, seats, setSeats, active, setActive } = useEditSeat(
    seat
  );
  /**
   * define api calls
   */
  const { sendPost, postResponse, errorResponse, postLoading } = usePost(
    `establishment/${establishmentData.id}/seats/edit`,
    apiHeaderAuth(getToken.access_token),
    {
      id: seat.id,
      name: name,
      seats: seats,
      published: active
    }
  );
  /**
   *
   */
  useEffect(() => {
    if (postResponse && postResponse.status && postResponse.status === 200) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to update the opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      errorResponse &&
      errorResponse.response &&
      (errorResponse.response.status === 401 ||
        errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse, errorResponse]);
  /**
   *
   */
  return (
    <Row JContent="space-between" margin="0 0 8px 0" AItems="center">
      <Row JContent="space-between" margin="0 16px 0 0">
        <Input
          inputValue={name}
          setInputValue={setName}
          placeholder="enter your start time"
          label="Name"
          labelColor={ThemeColors.primaryColor}
          // eslint-disable-next-line react/style-prop-object
          style="full"
          containerWidth="40%"
        />
        <Input
          inputValue={seats}
          setInputValue={setSeats}
          placeholder="enter your closing time"
          label="Seats"
          labelColor={ThemeColors.primaryColor}
          // eslint-disable-next-line react/style-prop-object
          style="full"
          containerWidth="40%"
        />
      </Row>

      <Column>
        <Label align="center">Active</Label>
        <MainButton
          type="switch"
          active={active === 1}
          action={() => setActive(active === 1 ? 0 : 1)}
        />
      </Column>

      <MainButton
        type="mainSmallest"
        action={() => sendPost(true)}
        disabled={postLoading}
      >
        {postLoading ? (
          <Icon full={true} type="spinning" icon="Spinner" />
        ) : (
          'Save'
        )}
      </MainButton>
    </Row>
  );
};

export default EditSeat;
