import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { ApiUrl } from '../utils/CreatUrl';

/**
 * Custom hook that performs an basic post request with given parameters
 * @string url
 * @object postData
 * @boolean send
 * @function setSend
 * @returns object
 */
export const usePost = (url: string, header: any, postData: any) => {
  const firstUpdate = useRef(true);
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [errorResponse, setErrorResponse] = useState<any>({});
  const request = async () => {
    if (!firstUpdate.current || send) {
      setLoading(true);
      try {
        const response = await axios.post(ApiUrl + url, postData, {
          headers: header
        });
        setResponse(response);
        setLoading(false);
        setSend(false);
      } catch (error) {
        setLoading(false);
        setErrorResponse(error);
        setError(true);
        setSend(false);
        console.log('error', error);
      }
    }
    firstUpdate.current = false;
  };

  useEffect(() => {
    send && request();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]);

  return {
    postResponse: response,
    postLoading: loading,
    postError: error,
    sendPost: setSend,
    errorResponse: errorResponse
  };
};
