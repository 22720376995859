import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import { firstLetterCapital } from '../../utils/Transform';
import ChartCard from '../Charts/ChartCard';
import Input from '../Form/Input';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import EditSeat from '../Seats/EditSeat';
import { Column, Container, MainButton, Row, Warning } from '../shared';
import { Icon } from '../shared/Icons';
import { Paragraph, Title } from '../Styles/Text';
import { useTableData } from './Table.Data';
import {
  closeWarning,
  removeOpeningDays,
  searchInputOpeningDays,
  turnOffTables,
  turnOnTables
} from './Table.Functions';
import { useTable } from './Table.Hooks';
import { OpenDaysProps } from './Table.Interfaces';

const TableList: React.FC<OpenDaysProps> = ({
  EstablishmentsData,
  setAlerts
}) => {
  /**
   *
   */
  const {
    warning,
    setWarning,
    establishmentOpeningDays,
    setEstablishmentOpeningDays,
    searchOpeningDays,

    filteredOpeningDays,
    setFilteredOpeningDays,
    enableTables,
    setEnableTables,
    selectedOpeningDays,
    setSelectedOpeningDays,
    selectedUpdate,

    searchPostals,

    setFilteredPostal,
    selectedPostal
  } = useTable(EstablishmentsData);
  /**
   *
   *
   */
  const {
    getEstablishmentTables,

    postAddSeat,
    postRemoveSeat,

    updateEstablishment,

    EnableTables
  } = useTableData(
    EstablishmentsData,
    selectedOpeningDays,
    selectedPostal,
    selectedUpdate,
    searchPostals,
    searchOpeningDays,
    setAlerts,
    setFilteredOpeningDays,
    setFilteredPostal
  );

  return (
    <Container>
      {warning.show && (
        <Warning
          type={warning.type}
          close={() => closeWarning(warning, setWarning)}
        >
          {warning.message}
        </Warning>
      )}
      <ChartCard width="100%">
        <Title margin="0 0 16px 0">Tables</Title>
        <Row>
          <Span>
            <MainButton
              type={enableTables ? 'main' : 'ghost'}
              action={() => turnOnTables(setEnableTables, EnableTables)}
              disabled={enableTables}
            >
              {updateEstablishment.postLoading ? (
                <Icon full={true} type="spinning" icon="Spinner" />
              ) : (
                'Yes'
              )}
            </MainButton>
          </Span>
          <Span>
            <MainButton
              type={enableTables ? 'ghost' : 'main'}
              action={() => turnOffTables(setEnableTables, EnableTables)}
              disabled={!enableTables}
            >
              {updateEstablishment.postLoading ? (
                <Icon full={true} type="spinning" icon="Spinner" />
              ) : (
                'No'
              )}
            </MainButton>
          </Span>
        </Row>
      </ChartCard>

      {enableTables && (
        <Row AItems="flex-start">
          <Column margin="0 8px 0 0">
            <ChartCard title="Tables" width="100%">
              <Input
                inputValue={establishmentOpeningDays}
                setInputValue={(e: any) =>
                  searchInputOpeningDays(
                    e,
                    getEstablishmentTables,
                    setEstablishmentOpeningDays,
                    setFilteredOpeningDays
                  )
                }
                placeholder="Search for a table"
                label="Tables"
                labelColor={ThemeColors.primaryColor}
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="100%"
              />

              <List headerItems={['Tables', '']} align="left">
                <Column>
                  <Paragraph
                    margin="16px 0 0 0"
                    color={ThemeColors.primaryColor}
                    fontWeight="bold"
                  >
                    Add table
                  </Paragraph>
                  <FaPlusCircle
                    onClick={() => postAddSeat.sendPost(true)}
                    style={{
                      color: ThemeColors.primaryColor,
                      fontSize: 32,
                      margin: '16px 0 16px 0'
                    }}
                  />
                </Column>
                {filteredOpeningDays.length > 0 &&
                  filteredOpeningDays
                    //.sort((a: any, b: any) => a.day_id - b.day_id)
                    .map((item: any) => (
                      <ItemContainer>
                        <Column>
                          <Row JContent="space-between">
                            <ListItem align="left">
                              Table: <b>{firstLetterCapital(item.name)}</b>
                            </ListItem>
                            <Icon
                              icon="MinusCircle"
                              action={() =>
                                removeOpeningDays(
                                  item,
                                  setSelectedOpeningDays,
                                  postRemoveSeat
                                )
                              }
                            />
                          </Row>
                          <EditSeat
                            establishmentData={EstablishmentsData}
                            seat={item}
                            setWarning={setWarning}
                            setAlerts={setAlerts}
                          />
                        </Column>
                      </ItemContainer>
                    ))}
              </List>
            </ChartCard>
          </Column>
        </Row>
      )}
    </Container>
  );
};

export default TableList;

const Span = styled.span`
  margin: 0 16px;
  width: 100%;
`;
