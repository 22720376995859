import React from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../../config';
import { toCurrency } from '../../../helpers';
//import './ToolTip.css';

interface ToolTipProps {
  hoverLoc: any;
  activePoint: any;
  price: boolean;
}

const ToolTip: React.FC<ToolTipProps> = ({ hoverLoc, activePoint, price }) => {
  const svgLocation = document
    .getElementsByClassName('linechart')[0]
    .getBoundingClientRect();

  let placementStyles = {
    width: '0px',
    left: 0
  };

  let width = 100;
  placementStyles.width = width + 'px';
  placementStyles.left = hoverLoc + svgLocation.left - width * 2;

  return (
    <Hover className="hover" style={placementStyles}>
      <div className="date">{activePoint.d}</div>
      <Price className="price">
        {price ? toCurrency(activePoint.p) : activePoint.p}
      </Price>
    </Hover>
  );
};

export default ToolTip;

const Hover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 5px;
  border-radius: 4px;
  background-color: ${ThemeColors.primaryColor};
  color: white;
  top: 50px;
`;

const Price = styled.div`
  font-weight: 700;
`;
