import styled, { css, keyframes } from 'styled-components';

const FadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const MoveAnimation = keyframes`
  from { bottom: -16px; }
  to { bottom: 0; }
`;

export const AlertBase = styled.div`
  color: #fff;
  font-family: Roboto, sans-serif;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 330px;
  min-height: 72px;
  position: relative;
  padding: 16px;
  animation: ${FadeInAnimation} 0.5s linear, ${MoveAnimation} 0.5s linear;
  transition: opacity 1s ease;
  margin: 8px 0;
`;

export const AlertSuccess = styled(AlertBase)`
  background: #06d98e;

  svg,
  path {
    color: #06d98e;
  }
`;

export const AlertWarning = styled(AlertBase)`
  background: #ffc046;

  svg,
  path {
    color: #ffc046;
  }
`;

export const AlertError = styled(AlertBase)`
  background: #ff7575;

  svg,
  path {
    color: #ff7575;
  }
`;

export const AlertInfo = styled(AlertBase)`
  background: #2196f3;

  svg,
  path {
    color: #2196f3;
  }
`;

export const AlertWrapper = styled.div<{ show?: boolean }>`
  position: fixed;
  top: 80px;
  right: -100%;
  z-index: 200;
  transition: right 1s ease;
  ${({ show }) =>
    show &&
    css`
      right: 16px;
    `}
`;
