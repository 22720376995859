import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config } from '../../config/config';
import copy from '../../data/copy.json';
import { apiHeaderAuth, toCurrency } from '../../helpers';
import { useGetData } from '../../hooks/useGetData';
import { Establishment } from '../../interfaces';
import { MainButton } from '../shared/Buttons';
import { Column, Container, Input, Row } from '../';

// const Container = styled.div``;

interface EventsProps {
  EstablishmentsData: Establishment;
}

const Events: React.FC<EventsProps> = ({ EstablishmentsData }) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [resend, setResend] = useState(true);
  useGetData(
    apiHeaderAuth(getToken.access_token),
    `events/establishment/${EstablishmentsData.id}`,
    resend,
    setResend,
    setData,
    setLoading
  );
  const EventInputs = DummyText.event.inputs;

  const [eventData, setEventData] = useState(EventInputs.event.placeholder);
  const [lineUpData, setLineUpData] = useState(EventInputs.lineUp.placeholder);
  const [entranceData, setEntranceData] = useState<String>(
    EventInputs.entrance.placeholder
  );
  const [descriptionData, setDescriptionData] = useState(
    EventInputs.description.placeholder
  );

  useEffect(() => {
    if (!loading) {
      setEventData(data.data[0].event);
      setLineUpData(data.data[0].lineUp);
      setEntranceData(data.data[0].entrance);
      setDescriptionData(data.data[0].description);
    }
  }, [data, loading]);

  return !loading ? (
    <Container FDirection="row" JContent="space-around">
      <Column size="40%">
        <Input
          label={EventInputs.event.name}
          //placeholder={EventInputs.event.placeholder}
          placeholder={eventData}
          setInputValue={setEventData}
        />
        <Row>
          <Input
            label={EventInputs.lineUp.name}
            //placeholder={EventInputs.lineUp.placeholder}
            placeholder={lineUpData}
            setInputValue={setLineUpData}
          />

          <Input
            label={EventInputs.entrance.name}
            //placeholder={EventInputs.entrance.placeholder}
            placeholder={toCurrency(
              Number(entranceData.replace(/,/g, '.'))
            ).toString()}
            setInputValue={setEntranceData}
          />
        </Row>
        <Input
          label={EventInputs.description.name}
          //placeholder={EventInputs.description.placeholder}
          placeholder={descriptionData}
          setInputValue={setDescriptionData}
          style="fullTextArea"
        />
        <ButtonContainer>
          <MainButton text={DummyText.event.buttons.changeCurrent.text} />
        </ButtonContainer>
      </Column>
      <Column size="40%">
        <ButtonContainer>
          <EventImage src="https://i.imgur.com/JkT0Dbr.png" />
          <MainButton text={DummyText.event.buttons.changeImage.text} />
        </ButtonContainer>
      </Column>
    </Container>
  ) : (
    <Container>
      <h1>{copy.general.loading}</h1>
    </Container>
  );
};

export default Events;

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const EventImage = styled.img`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`;

const DummyText = {
  event: {
    inputs: {
      event: {
        name: 'Event',
        placeholder: "What's the party's name",
        type: 'text'
      },
      lineUp: {
        name: 'Line up',
        placeholder: "Who's playing Tonight?",
        type: 'text'
      },
      entrance: {
        name: 'Entrance',
        placeholder: '10',
        type: 'number'
      },
      description: {
        name: 'Description',
        placeholder: 'What can you tell ... ',
        type: 'text'
      }
    },
    buttons: {
      changeCurrent: {
        text: 'Change'
      },
      changeImage: {
        text: 'Change'
      }
    }
  }
};
