import styled from 'styled-components';

export const WarningBase = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 24px;
  margin: 16px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
`;

export const WarningAlert = styled(WarningBase)`
  background-color: #f35050;
`;

export const WarningSucces = styled(WarningBase)`
  background-color: #8bbf9f;
`;
