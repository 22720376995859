import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ButtonMenu, Column, MainButton, Row } from '../../components/shared';
import * as Interface from '../../interfaces';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import List, { ItemContainer } from '../../components/List/List';
import Modal from '../../components/modal/Modal';
import Input from '../../components/Form/Input';
import ListItem from '../../components/List/ListItem';
import { ProductProps } from '../../interfaces/Product';
import { MdCancel, MdEdit } from 'react-icons/md';
import ChartCard from '../../components/Charts/ChartCard';
import { AlertProp } from '../../interfaces/Alert';
import { AlertWrapper } from '../../components/Alert/Alert.style';
import Alert from '../../components/Alert/Alert';
import { Icon } from '../../components/shared/Icon';
import { ApiUrl } from '../../utils/CreatUrl';
import { setAlert } from '../../redux/actions';
import { connect } from 'react-redux';
import { Heading } from '../../components/Styles/Text';
type MenuItemObject = {
  id: number;
  name: string;
  type: string;
};
interface MenuItemsProps {
  EstablishmentsData: Interface.Establishment;
  setAlert: Function;
}

const MenuItems: React.FC<MenuItemsProps> = ({
  EstablishmentsData,
  setAlert
}) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);
  //const [alerts, setAlerts] = useState<AlertProp[]>();
  const [menuItems, setMenuItems] = useState([]);
  const [modalData, setModalData] = useState({
    show: false,
    title: '',
    url: `establishment/${EstablishmentsData.id}/addon/create`,
    type: 'post'
  });
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('New product');
  const [requestType, setRequestType] = useState('add');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [itemId, setItemId] = useState<number>();

  const getMenuItems = async () => {
    try {
      const response = await axios.get(
        `${ApiUrl}establishment/${EstablishmentsData.id}/menu_items`
      );
      setMenuItems(response.data);
    } catch (error) {}
  };

  const changeMenuItems = async () => {
    try {
      const response = await axios.post(
        `${ApiUrl}establishment/${EstablishmentsData.id}/menu_items/${requestType}`,
        {
          id: itemId,
          name: name,
          type: type,
          icon: 'none'
        },
        { headers: restHeader }
      );
      const newAlert: AlertProp = {
        title: 'Menu item updated',
        message: `Successfully updated ${name}`,
        type: 'success'
      };
      setAlert(newAlert);
      getMenuItems();
    } catch (error) {
      const newAlert: AlertProp = {
        title: 'Failed to make change',
        message: `${error.response.data.message}`,
        type: 'error'
      };
      setAlert(newAlert);
    }
  };

  useEffect(() => {
    getMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestType === 'remove') {
      AlertPrompt('Are you sure you want to remove this addon?');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  const AlertPrompt = (msg: string) => {
    const r = window.confirm('Are you sure you want to' + msg);
    if (r) {
      changeMenuItems();
    } else {
      setRequestType('');
    }
  };

  return (
    <Container>
      <Row JContent="space-between" margin="16px 48px">
        <Heading>Menu Items</Heading>

        <ButtonMenu>
          {/* <Input
            label="Search"
            placeholder="enter product name"
            setInputValue={changeSearchValue}
          /> */}
          <MainButton
            type="mainSmall"
            text="Add new menu item"
            action={() => {
              setShowModal(!showModal);
              setModalTitle('Create Menu item');
              setRequestType('add');
              setItemId(0);
              setName('');
              setType('');
            }}
          />
        </ButtonMenu>
      </Row>
      <ChartCard title="" width="100%">
        <List headerItems={['name', 'type', 'actions']}>
          {menuItems.length > 0 ? (
            menuItems.map((menuItem: MenuItemObject, index: number) => {
              return (
                <ItemContainer>
                  <ListItem>{menuItem.name}</ListItem>
                  <ListItem>{menuItem.type}</ListItem>
                  <ActionsContainer>
                    <Action
                      onClick={() => {
                        setShowModal(!showModal);
                        setModalTitle('Edit ' + menuItem.name);
                        setRequestType('edit');
                        setItemId(menuItem.id);
                        setName(menuItem.name);
                        setType(menuItem.type ? menuItem.type.toString() : '');
                      }}
                    >
                      <MdEdit size={30} />
                    </Action>
                    <Action
                      onClick={() => {
                        setItemId(menuItem.id);
                        setRequestType('remove');
                      }}
                    >
                      <MdCancel size={30} />
                    </Action>
                  </ActionsContainer>
                </ItemContainer>
              );
            })
          ) : (
            <ItemContainer>
              <p>No Menu items found</p>
            </ItemContainer>
          )}
          {showModal && (
            // true
            <Modal
              closeModal={() => {
                setShowModal(false);
              }}
              noButton={true}
              width="20%"
            >
              <Row width="100%" JContent="space-between">
                <h2>{modalTitle}</h2>
                <IconWrapper
                  onClick={() => {
                    setShowModal(false);
                    setModalData({ ...modalData, show: false });
                  }}
                >
                  <Icon
                    color={ThemeColors.primaryColor}
                    getIcon="Times"
                    size={32}
                  />
                </IconWrapper>
              </Row>

              <Input
                type="name"
                label="name"
                placeholder="Write addon name..."
                inputValue={name}
                setInputValue={setName}
              />
              <Input
                label="type"
                placeholder="Add a type"
                inputValue={type}
                setInputValue={setType}
              />
              <Row margin="16px 0 0 0">
                <MainButton
                  type="mainSmall"
                  action={() => {
                    changeMenuItems();
                    setShowModal(false);
                  }}
                >
                  {requestType === 'add'
                    ? 'create menu item'
                    : 'Edit menu item'}
                </MainButton>
              </Row>
            </Modal>
          )}
        </List>
      </ChartCard>
    </Container>
  );
};

const mapDispatchToProps = { setAlert };

export default connect(null, mapDispatchToProps)(MenuItems);

// const ItemContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   border-radius: 27px;
//   margin: 16px 0;
// `;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justtify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Select = styled.select`
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;

const Addons = styled.p`
  list-style: none;
  margin: 8px 0;
  background-color: #e9b07f;
  padding: 8px;
  border-radius: 24px;
  color: white;
  text-align: center;
`;

const AddonsList = styled.div`
  margin: 8px 0;
`;

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  width: 200px;
`;
const IconWrapper = styled.span`
  cursor: pointer;
`;
