import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeColors } from '../../config';

interface BurgerMenuProps {
  setOpen: Function;
  open: boolean;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({ setOpen, open }) => {
  return (
    <Container onClick={() => setOpen(!open)}>
      <Burger open={open} />
    </Container>
  );
};

export default BurgerMenu;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -40px;
  cursor: pointer;
  background-color: ${ThemeColors.primaryColor};
  margin: 10px 0;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;
const Burger = styled.div<{ open: boolean }>`
  background-color: white;
  height: 4px;
  width: ${props => (props.open ? '25px' : '20px')};
  transform: ${props => (props.open ? 'rotate(45deg)' : 'rotate(0deg)')};
  transition: all 0.2s ease;
  border-radius: 50px;

  &::before {
    display: block;
    content: '';
    position: relative;
    top: ${props => (props.open ? 0 : '-10px')};
    background-color: white;
    height: 4px;
    width: 25px;
    transform: ${props => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: all 0.2s ease;
    border-radius: 50px;
  }

  &::after {
    display: block;
    content: '';
    position: relative;
    top: 5px;
    background-color: white;
    height: 4px;
    width: 15px;
    opacity: ${props => (props.open ? 0 : 1)};
    transition: all 0.2s ease;
    border-radius: 50px;
  }
`;
