import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { Orders, Establishment, User, Alert } from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));
const rootReducer = combineReducers({ Orders, Establishment, Alert, User });

const store = createStore(rootReducer, composedEnhancer);

export default store;
