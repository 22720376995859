import React, { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import { OrderProps } from '../../interfaces/Order';
import { ProductProps } from '../../interfaces/Product';
import List, { ActionsContainer, ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import PopOver from '../PopOver/PopOver';
import { ChartIcon, Column } from '../shared';
import { Icon } from '../shared/Icon';
interface StatProductsProps {
  id: number;
  orders: [OrderProps];
}
const StatProducts: React.FC<StatProductsProps> = ({ id, orders }) => {
  let history = useHistory();

  const [products, setProducts] = useState([]);
  const [openPopOver, setOpenPopOver] = useState(0);
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const getProducts = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${id}/products/all`
  );

  useEffect(() => {
    getProducts.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProducts(getProducts.fetchResponse);
  }, [getProducts.fetchResponse]);

  return (
    <List headerItems={['product', 'type', 'orderd', 'stock', ' ']}>
      {products.length > 0 ? (
        products.map((product: ProductProps, index) => {
          let count = 0;
          orders.forEach((order: OrderProps) => {
            if (order.order.length > 0) {
              order.order.forEach(orderProduct => {
                if (orderProduct.id === product.id) {
                  count += orderProduct.pivot.qty;
                }
              });
            }
          });
          return (
            <ItemContainer>
              <ListItem>{product.name}</ListItem>
              <ListItem>{product.type}</ListItem>
              <ListItem>{count}</ListItem>
              <ListItem>
                <span
                  style={{
                    color:
                      product.qty > 10 && product.qty < 100
                        ? 'black'
                        : product.qty > 100
                        ? 'green'
                        : 'red'
                  }}
                >
                  {product.qty}
                </span>
              </ListItem>
              <ActionsContainer>
                <Action onClick={() => setOpenPopOver(product.id)}>
                  <MdMoreVert size={30} />
                </Action>
                {/* <Action
              onClick={() => {
                Alert('Are you sure you want to remove this addon?');
                setItemId(product.id);
                setModalPostUrl(
                  `establishment/${EstablishmentsData.id}/addon/remove`
                );
                setPackage({ id: product.id });
              }}
            >
              <MdCancel size={30} />
            </Action> */}
                {product.id === openPopOver && (
                  <PopOver
                    title={`Options`}
                    //sub="Options"
                    close={() => setOpenPopOver(0)}
                  >
                    {/* <ButtonPopOver>
                      Show
                      <Icon
                        color={ThemeColors.primaryColor}
                        icon="ChevronRight"
                        size={16}
                      />
                    </ButtonPopOver> */}
                    <ButtonPopOver
                      onClick={() => history.push(`/products/${product.id}`)}
                    >
                      Edit
                      <Icon
                        color={ThemeColors.primaryColor}
                        getIcon="ChevronRight"
                        size={16}
                      />
                    </ButtonPopOver>
                  </PopOver>
                )}
              </ActionsContainer>
            </ItemContainer>
          );
        })
      ) : (
        <Column>
          <ChartIcon color="rgba(67,146,241,0.1)">
            <Icon getIcon="Hamburger" color="rgb(67, 146, 241)" />
          </ChartIcon>
          <h1> No products found</h1>
        </Column>
      )}
    </List>
  );
};

export default StatProducts;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.5s ease;
  position: relative;
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #d3d3d373;
    transition: all 0.5s ease;
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }
  &:hover {
    background-color: #d3d3d373;
    &:after {
      width: 50px;
      height: 50px;
    }
  }
`;

const ButtonPopOver = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0 8px 0;
  cursor: pointer;
  border-bottom: solid 1px #00000033;
`;
