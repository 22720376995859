import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';
type IconProps = {
  full?: boolean;
  margin?: string;
};
export const IconContainerBase = styled.div<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 8px 0;
  /* margin-top: 16px; */
  cursor: pointer;
  background-color: ${props => (props.full ? 'white' : 'none')};
  border-radius: ${props => (props.full ? '50%' : 'none')};
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};
`;

export const IconContainerCircle = styled(IconContainerBase)`
  border-radius: 50%;
  border: 3px solid ${ThemeColors.primaryColor};
`;

export const IconContainerCircleFull = styled(IconContainerBase)`
  border-radius: 50%;
  border: 3px solid white;
  background-color: ${ThemeColors.primaryColor};
`;

export const IconContainerSpinning = styled(IconContainerBase)`
  animation: spin 4s linear infinite;
  width: 30px;
  height: 30px;
  padding: 4px;
  margin-top: 8px;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
