import { FC } from 'react';
import styled, { css } from 'styled-components';

const Headings = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6'
};

interface HeadingProps {
  type?: keyof typeof Headings;
  extraClasses?: string;
  size?: string;
}

export const Heading: FC<HeadingProps> = ({
  children,
  extraClasses = '',
  type = 'h1',
  size = 'text-4xl'
}) => {
  //const Head: any = <h1 />;
  return (
    <Head className={`${size} font-bold ${extraClasses}`} as={type}>
      {children}
    </Head>
  );
};

const Head = styled.h1`
  font-family: Montserrat-Black, sans-serif;
`;

type TextProps = {
  fontWeight?: string;
  fontSize?: string;
  color?: string;
  margin?: string;
  padding?: string;
  align?: string;
};

export const Title = styled.h1<TextProps>`
  font-family: Montserrat-Black, sans-serif;
  ${props =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

export const Paragraph = styled.p<TextProps>`
  font-family: Roboto, sans-serif;
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  ${props =>
    props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
    ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
    ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`;

export const Label = styled(Paragraph)`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  width: 200px;
`;
