import { useEffect } from 'react';
import { apiHeaderAuth } from '../../../helpers';
import { useGet } from '../../../hooks';
import { usePost } from '../../../hooks/usePost';
import { getTokenProps } from '../../../interfaces/Token';

export const useOrderRefund = (establishmentId: number) => {
  const getToken: getTokenProps = JSON.parse(
    sessionStorage.getItem('user_token') || '{}'
  );

  const {
    getFetch,
    fetchResponse,
    fetchError,
    fetchErrorMessage,
    fetchLoading
  } = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${establishmentId}/order/refunded`,
    true
  );

  return {
    getFetch,
    fetchResponse,
    fetchError,
    fetchErrorMessage,
    fetchLoading
  };
};
