import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';

interface PageProps {
  open: boolean;
  left: number;
}

export const Page: React.FC<PageProps> = ({ children, open, left }) => {
  return (
    <PageLayout open={open} left={left}>
      {children}
    </PageLayout>
  );
};

interface ContainerProps {
  JContent?: string;
  AItems?: string;
  CPosition?: string;
  CDisplay?: string;
  FDirection?: string;
  CWidth?: string;
  CHeight?: string;
  BColor?: string;
  FWrap?: string;
  BImage?: string;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  JContent,
  AItems,
  CPosition,
  CDisplay,
  FDirection,
  CWidth,
  CHeight,
  BColor,
  FWrap,
  BImage
}) => {
  return (
    <ContainerLayout
      JContent={JContent}
      AItems={AItems}
      CPosition={CPosition}
      CDisplay={CDisplay}
      FDirection={FDirection}
      CWidth={CWidth}
      CHeight={CHeight}
      BColor={BColor}
      FWrap={FWrap}
      BImage={BImage}
    >
      {children}
    </ContainerLayout>
  );
};

interface InputProps {
  type?: string;
  placeholder?: string;
  action: Function;
  full?: boolean;
  fullWidth?: boolean;
  noMargin?: boolean;
  title?: string;
  value?: string;
  textField?: boolean;
  color?: string;
  smallInput?: boolean;
  titleColor?: string;
}

// const Input: React.FC<InputProps> = ({
//   type,
//   placeholder,
//   action,
//   full,
//   fullWidth,
//   noMargin,
//   title,
//   value,
//   textField,
//   color,
//   smallInput,
//   titleColor
// }) => {
//   return (
//     <InputContainer fullWidth={fullWidth}>
//       {title && <InputLayoutTitle color={titleColor}>{title}</InputLayoutTitle>}
//       <InputContainer fullWidth={fullWidth} noMargin={noMargin}>
//         <InputLabel full={full} />
//         <InputLayout
//           smallInput={smallInput}
//           fullWidth={fullWidth}
//           type={type}
//           color={color}
//           placeholder={placeholder}
//           onKeyUp={(e: any) => action(e.target.value)}
//           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
//             action(e.target.value)
//           }
//           value={value}
//           textField={textField}
//         />
//       </InputContainer>
//     </InputContainer>
//   );
// };

interface SelectProps {
  type?: string;
  placeholder?: string;
  action: Function;
  full?: boolean;
  fullWidth?: boolean;
  items?: any;
  title?: string;
  titleColor?: string;
  JContent?: string;
  AItems?: string;
}

export const Select: React.FC<SelectProps> = ({
  type,
  placeholder,
  action,
  full,
  fullWidth,
  items,
  title,
  titleColor,
  JContent = 'flex-start',
  AItems = 'flex-start'
}) => {
  return (
    <ColumnLayout align={AItems} justify={JContent}>
      {title && <InputLayoutTitle color={titleColor}>{title}</InputLayoutTitle>}

      <InputContainer fullWidth={fullWidth}>
        <InputLabel full={full} />
        <SelectLayout onChange={e => action(e.target.value)}>
          <option value="" disabled selected>
            {placeholder}
          </option>
          {items &&
            items.map((item: any) => (
              <option value={item.type}>{item.type}</option>
            ))}
        </SelectLayout>
      </InputContainer>
    </ColumnLayout>
  );
};

interface GradiantCardProps {}

export const GradiantCard: React.FC<GradiantCardProps> = ({ children }) => {
  return <GradiantCardLayout>{children}</GradiantCardLayout>;
};

interface ColumnProps {
  justify?: string;
  align?: string;
  size?: string;
  FWrap?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  radius?: string;
  width?: string;
}

export const Column: React.FC<ColumnProps> = ({
  children,
  justify,
  align,
  size,
  FWrap,
  padding,
  margin,
  bgColor,
  radius,
  width
}) => {
  return (
    <ColumnLayout
      justify={justify}
      align={align}
      size={size}
      FWrap={FWrap}
      padding={padding}
      margin={margin}
      bgColor={bgColor}
      width={width}
    >
      {children}
    </ColumnLayout>
  );
};

interface RowLayout {
  JContent?: string;
  AItems?: string;
  BColor?: string;
  FWrap?: string;
  padding?: string;
  margin?: string;
  width?: string;
  radius?: string;
}

export const Row: React.FC<RowLayout> = ({
  children,
  JContent,
  AItems,
  BColor,
  FWrap,
  padding,
  margin,
  width,
  radius
}) => {
  return (
    <RowLayout
      JContent={JContent}
      AItems={AItems}
      BColor={BColor}
      FWrap={FWrap}
      padding={padding}
      margin={margin}
      width={width}
      radius={radius}
    >
      {children}
    </RowLayout>
  );
};

interface ButtonMenuProps {
  margin?: string;
}

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  children,
  margin = '0'
}) => {
  return <ButtonMenuLayout margin={margin}>{children}</ButtonMenuLayout>;
};

interface ContentProps {
  width?: string;
  minWidth?: string;
  margin?: string;
  padding?: string;
}

export const Content: React.FC<ContentProps> = ({
  children,
  width,
  minWidth,
  margin,
  padding
}) => {
  return (
    <ContentLayout
      margin={margin}
      padding={padding}
      className="content"
      width={width}
      minWidth={minWidth}
    >
      {children}
    </ContentLayout>
  );
};

const PageLayout = styled.div<{ open: boolean; left: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 140px);
  min-height: 100vh;
  transition: all 0.2s ease;
  padding-right: 4px;
  border-top-left-radius: 27px;
  border-bottom-left-radius: 27px;
  background-color: #e8e8e8;
  box-shadow: 0 2px 6px 4px rgba(255, 255, 255, 0.08);
  left: ${props => (props.open ? '240px' : '140px')};
  z-index: 100;
  padding: 32px;
`;

type ContainerLayoutProps = {
  JContent?: string;
  AItems?: string;
  CPosition?: string;
  CDisplay?: string;
  FDirection?: string;
  CWidth?: string;
  CHeight?: string;
  BColor?: string;
  FWrap?: string;
  BImage?: string;
};

const ContainerLayout = styled.div<ContainerLayoutProps>`
  ${({
    JContent,
    AItems,
    CPosition,
    CDisplay,
    FDirection,
    CWidth,
    CHeight,
    BColor,
    FWrap
  }) => css`
    position: ${CPosition || 'relative'};
    display: ${CDisplay || 'flex'};
    justify-content: ${JContent || 'center'};
    align-items: ${AItems || 'center'};
    flex-direction: ${FDirection || 'column'};
    width: ${CWidth || '100%'};
    height: ${CHeight || '100%'};
    background-color: ${BColor || 'none'};
    flex-wrap: ${FWrap || ''};
  `};
  ${props =>
    props.BImage &&
    css`
      background-image: url(${props.BImage});
      background-size: 80%;
      background-position: left;
      background-repeat: no-repeat;
    `}
`;

type ColumnLayoutProps = {
  justify?: string;
  align?: string;
  size?: string;
  FWrap?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  radius?: string;
  width?: string;
};

const ColumnLayout = styled.div<ColumnLayoutProps>`
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: ${props => (props.size ? props.size + '%' : '100%')};
  height: 100%;
  flex-direction: column;
  ${({ padding, margin, bgColor, radius, width }) => css`
    ${padding &&
    css`
      padding: ${padding};
    `};
    ${margin &&
    css`
      margin: ${margin};
    `}
    ${bgColor &&
    css`
      background-color: ${bgColor};
    `}
    ${radius &&
    css`
      border-radius: ${radius};
    `}
    ${width &&
    css`
      width: ${width};
    `}
  `}
`;
type RowLayoutProps = {
  JContent?: string;
  AItems?: string;
  BColor?: string;
  FWrap?: string;
  padding?: string;
  margin?: string;
  width?: string;
  radius?: string;
};
const RowLayout = styled.div<RowLayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  position: relative;
  ${({
    JContent,
    AItems,
    BColor,
    FWrap,
    padding,
    margin,
    width,
    radius
  }) => css`
    justify-content: ${JContent || 'center'};
    align-items: ${AItems || 'center'};
    background-color: ${BColor || 'none'};
    flex-wrap: ${FWrap || ''};
    padding: ${padding || ''};
    margin: ${margin || ''};
    ${width &&
    css`
      width: ${width};
    `}
    ${radius &&
    css`
      border-radius: ${radius};
    `}
  `};
`;

const InputContainer = styled.div<{ fullWidth?: boolean; noMargin?: boolean }>`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : 'initial')};
  margin: ${props => (props.noMargin ? '0' : '8px 8px')};
  h2 {
    color: white;
  }
`;

const InputLabel = styled.label<{ full?: boolean }>`
  display: block;
  position: absolute;
  left: 0;
  width: ${props => (props.full ? '100%' : '30px')};
  height: 100%;
  background-color: ${ThemeColors.primaryColor};
  z-index: 0;
  border-radius: ${props => (props.full ? '50px' : 'inherit')};
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  transition: all 0.5s ease;
  margin: 0;
`;

type InputLayoutProps = {
  smallInput?: boolean;
  color?: string;
  textField?: boolean;
  fullWidth?: boolean;
};

const InputLayout = styled.input<InputLayoutProps>`
  position: relative;
  text-align: center;
  border-style: solid;
  border-color: ${ThemeColors.primaryColor};
  background-color: transparent;
  border-width: initial;
  border-radius: 50px;
  font-size: ${props => (props.smallInput ? '16px' : '24px')};
  padding: 13px;
  z-index: 1;
  color: ${props => props.color || 'white'};
  width: ${props =>
    props.fullWidth ? '100%' : (props.smallInput && '150px') || 'initial'};
  height: ${props => (props.textField ? '200px' : 'initial')};
  &::placeholder {
    color: ${ThemeColors.placeHolderFontColor};
  }
  &:focus {
    outline: none;
  }
`;

const SelectLayout = styled.select<{ full?: boolean; fullWidth?: boolean }>`
  position: relative;
  text-align: center;
  border-style: solid;
  border-color: ${ThemeColors.primaryColor};
  background-color: transparent;
  border-width: initial;
  border-radius: 50px;
  font-size: 24px;
  padding: 13px;
  z-index: 1;
  color: ${props => (props.full ? 'white' : 'white')};
  width: ${props => (props.fullWidth ? '100%' : 'initial')};
  &:focus {
    outline: none;
  }
`;

const GradiantCardLayout = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 10px;
  padding: 20px;
  width: 100%;
  flex-wrap: wrap;
  background: linear-gradient(
    rgb(4, 3, 3) 80%,
    ${ThemeColors.primaryColor} 200%
  );
  align-content: center;
  height: fit-content;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;

  nav {
    width: 100%;
  }
`;

const InputLayoutTitle = styled.p`
  opacity: 0.42;
  color: ${props => props.color || '#303030'};
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-left: 30px;
`;

const ButtonMenuLayout = styled.div<{ margin: string }>`
  /* position: absolute; */
  top: 100px;
  right: 16px;
  padding: 20px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: ${props => props.margin};

  a {
    margin: 0 16px;
  }
`;
export const Space = styled.span`
  padding: 24px;
  margin: 16px 0;
  height: 50px;
`;

type ContentLayoutProps = {
  width?: string;
  minWidth?: string;
  margin?: string;
  padding?: string;
};

const ContentLayout = styled.div<ContentLayoutProps>`
  border-radius: 27px;
  background-color: #ffffff;
  padding: ${props => props.padding || '24px'};
  margin: ${props => props.margin || '24px 0'};
  min-width: ${props => props.minWidth || '90%'};
  width: ${props => props.width || 'intial'};
  @media (max-width: 1024px) {
    margin: 24px 0;
  }
`;

export const Tag = styled.span<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 50px;
  min-width: 250px;
  padding: 8px 16px;
  background-color: ${ThemeColors.primaryColor};
  color: ${ThemeColors.primaryFontColor};
  font-family: 'PT sans', sans-serif;
  font-weight: bold;
  font-size: 16px;
  transition: opacity 0.5s ease;
  opacity: ${props => (props.show ? '1' : '0')};
  max-height: 40px;
  margin-right: 8px;
`;

export const ChartIcon = styled.div<{ color?: string }>`
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  background: ${props => props.color || '#fce5f0'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
