import React, { useEffect, useState } from 'react';
import { MdMoreVert } from 'react-icons/md';
import styled from 'styled-components';
import { config } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import { Establishment } from '../../interfaces';
import ChartCard from '../Charts/ChartCard';
import List, { ActionsContainer, ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';

type employeeProps = {
  name: string;
  email: string;
  phonenumber: string;
  role: string;
};
interface EmployeesProps {
  EstablishmentsData: Establishment;
  setEstablishmentData: Function;
  getEstablishment: Function;
  setAlerts: Function;
}
const Employees: React.FC<EmployeesProps> = ({
  EstablishmentsData,
  setEstablishmentData,
  getEstablishment,
  setAlerts
}) => {
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  /**
   *
   */
  const [employeeGroups, setEmployeeGroups] = useState<any>({
    owners: [],
    managers: [],
    employees: []
  });
  /**
   *
   */
  const { fetchResponse, getFetch } = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${EstablishmentsData.id}/employee/show/all`
  );
  /**
   *
   */
  useEffect(() => {
    getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchResponse && fetchResponse.length > 0) {
      let newData: any = {
        owners: [],
        managers: [],
        employees: []
      };
      fetchResponse.forEach((employee: employeeProps) => {
        switch (employee.role) {
          case 'owner':
            newData = {
              owners: [...newData.owners, employee],
              managers: newData.managers,
              employees: newData.employees
            };
            break;
          case 'manager':
            newData = {
              owners: newData.owners,
              managers: [...newData.managers, employee],
              employees: newData.employees
            };
            break;
          case 'employee':
            newData = {
              owners: newData.owners,
              managers: newData.managers,
              employees: [...newData.employees, employee]
            };
            break;
        }
      });
      setEmployeeGroups(newData);
    }
  }, [fetchResponse]);

  return fetchResponse && fetchResponse.length > 0 ? (
    <>
      {employeeGroups.owners.length > 0 && (
        <ChartCard width="100%" title="Owner">
          <List headerItems={['name', 'email', 'phonenumber', '']}>
            {employeeGroups.owners.map(
              (employee: employeeProps, index: number) => {
                return (
                  <ItemContainer>
                    <ListItem>{employee.name}</ListItem>
                    <ListItem>{employee.email}</ListItem>
                    <ListItem>{employee.phonenumber}</ListItem>
                    <ActionsContainer>
                      <Action>
                        <MdMoreVert size={30} />
                      </Action>
                    </ActionsContainer>
                  </ItemContainer>
                );
              }
            )}
          </List>
        </ChartCard>
      )}
      {employeeGroups.managers.length > 0 && (
        <ChartCard width="100%" title="Manager">
          <List headerItems={['name', 'email', 'phonenumber', '']}>
            {employeeGroups.managers.map(
              (employee: employeeProps, index: number) => {
                return (
                  <ItemContainer>
                    <ListItem>{employee.name}</ListItem>
                    <ListItem>{employee.email}</ListItem>
                    <ListItem>{employee.phonenumber}</ListItem>

                    <ActionsContainer>
                      <Action>
                        <MdMoreVert size={30} />
                      </Action>
                    </ActionsContainer>
                  </ItemContainer>
                );
              }
            )}
          </List>
        </ChartCard>
      )}
      {employeeGroups.employees.length > 0 && (
        <ChartCard width="100%" title="Employee">
          <List headerItems={['name', 'email', 'phonenumber', '']}>
            {employeeGroups.employees.map(
              (employee: employeeProps, index: number) => {
                return (
                  <ItemContainer>
                    <ListItem>{employee.name}</ListItem>
                    <ListItem>{employee.email}</ListItem>
                    <ListItem>{employee.phonenumber}</ListItem>

                    <ActionsContainer>
                      <Action>
                        <MdMoreVert size={30} />
                      </Action>
                    </ActionsContainer>
                  </ItemContainer>
                );
              }
            )}
          </List>
        </ChartCard>
      )}
    </>
  ) : (
    <p>fetching</p>
  );
};

export default Employees;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.5s ease;
  position: relative;
  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #d3d3d373;
    transition: all 0.5s ease;
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }
  &:hover {
    background-color: #d3d3d373;
    &:after {
      width: 50px;
      height: 50px;
    }
  }
`;
