import React from 'react';
import { ThemeColors } from '../../config';
import * as FontAwesome from 'react-icons/fa';

export const Icon = ({
  color = ThemeColors.primaryColor,
  getIcon,
  size = 50
}) => {
  return React.createElement(FontAwesome[`Fa${getIcon}`], {
    size: size,
    color: color
  });
};
