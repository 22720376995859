import axios from 'axios';
import { config } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { ApiUrl } from '../../utils/CreatUrl';

export const SAVE_ESTABLISHMENT = 'SAVE_ESTABLISHMENT';
export const SELECT_ESTABLISHMENT = 'SELECT_ESTABLISHMENT';
export const FETCH_ESTABLISHMENT = 'FETCH_ESTABLISHMENT';

/**
 * Save passed on data as establishment
 * @param establishment
 * @returns void
 */
export const saveEstablishment = (establishment: any) => {
  return { type: SAVE_ESTABLISHMENT, payload: establishment };
};

/**
 * Save passed on data as establishment
 * @param establishment
 * @returns void
 */
export const selectEstablishment = (establishment: any) => {
  return { type: SELECT_ESTABLISHMENT, payload: establishment };
};

/**
 * Fetch establishment data
 * @param id
 * @returns void
 */
export const FetchEstablishment = (token?: string) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');

  return function (dispatch: Function) {
    const url = `${ApiUrl}establishment/employee/establishments`;
    dispatch({
      type: SAVE_ESTABLISHMENT,
      payload: {
        loading: true,
        error: false,
        errorResponse: {}
      }
    });
    // Fetch establishments related to the user
    axios
      .get(url, {
        headers: apiHeaderAuth(token || getToken.access_token)
      })
      .then(response => {
        //Dispatch data to the store

        dispatch({
          type: SAVE_ESTABLISHMENT,
          payload: {
            loading: false,
            error: false,
            errorResponse: { message: '' },
            response: response.data.data
          }
        });
      })
      .catch(error => {
        console.log(error);
        //
        dispatch({
          type: SAVE_ESTABLISHMENT,
          payload: {
            loading: false,
            error: true,
            errorResponse: error
          }
        });
      });
  };
};
