import axios from 'axios';
import { config } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { ApiUrl } from '../../utils/CreatUrl';
import * as Interface from '../../interfaces';
import store from '../Store';
export const SAVE_ORDERS = 'SAVE_ORDERS';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const LOADING_ORDERS = 'LOADING_ORDERS';

export const saveOrders = (orders: any) => {
  return { type: SAVE_ORDERS, payload: orders };
};

export const FetchOrders = (id: number) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const storedEstablishment = localStorage.getItem('establishment');

  const parsedEstablishment: Interface.Establishment = JSON.parse(
    storedEstablishment || '{}'
  );

  return function (dispatch: Function) {
    if (id || parsedEstablishment.id) {
      dispatch({
        type: SAVE_ORDERS,
        payload: {
          loading: true,
          error: false,
          errorResponse: {}
        }
      });
      axios
        .get(
          `${ApiUrl}establishment/${
            id || parsedEstablishment.id
          }/orders/active`,
          {
            headers: apiHeaderAuth(getToken.access_token)
          }
        )
        .then(response => {
          dispatch({
            type: SAVE_ORDERS,
            payload: {
              loading: false,
              error: false,
              response: response.data.data
            }
          });
        })
        .catch(error => {
          console.log(error);
          dispatch({
            type: SAVE_ORDERS,
            payload: {
              loading: false,
              error: true,
              errorResponse: error,
              firstLoad: false
            }
          });
        });
    } else {
      dispatch({
        type: SAVE_ORDERS,
        payload: {
          loading: false,
          error: true,
          errorResponse: { message: 'Establishment id not found' }
        }
      });
    }
  };
};
