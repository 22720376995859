import React from 'react';
import copy from '../../data/copy.json';
import Input from '../Form/Input';
import { ButtonMenu, MainButton } from '../shared';
//
interface ProductSearchProps {
  changeSearchValue: Function;
  newProduct: Function;
}
//
const ProductSearch: React.FC<ProductSearchProps> = ({
  changeSearchValue,
  newProduct
}) => {
  return (
    <>
      <ButtonMenu margin="0 8px 0 0">
        <Input
          style="full"
          label="Search"
          placeholder="enter product name"
          setInputValue={changeSearchValue}
        />
      </ButtonMenu>
      <ButtonMenu>
        <MainButton
          type="mainSmall"
          text={copy.buttons.newProduct.text}
          action={() => newProduct()}
        />
      </ButtonMenu>
    </>
  );
};

export default ProductSearch;
