import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ThemeColors } from '../../../config';

interface BarChartProps {
  subject: string;
  count: number;
  width: string;
  focus?: boolean;
  action?: Function;
}

const BarChart: React.FC<BarChartProps> = ({
  subject,
  count,
  width,
  focus = false,
  action = () => {}
}) => {
  return (
    <BarContainer onClick={() => action()}>
      <BarText>{subject}</BarText>
      <BarBarContainer>
        <Bar focus={focus} width={width} />
      </BarBarContainer>
      <BarCount focus={focus}>{count}</BarCount>
    </BarContainer>
  );
};

export default BarChart;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px dashed #d8d8d8;
  width: 100%;
`;

const BarBarContainer = styled.div`
  width: 75%;
`;

const widenAnimation = (width: string) => keyframes`
    0% {
      width: 0%;
    }

    100% {
      width: ${width};
    }
`;

const Bar = styled.div<{ width: string; focus: boolean }>`
  height: 25px;
  border-radius: 4px;
  background-color: ${ThemeColors.primaryColor};
  margin: 8px 0;
  transition: opacity 0.5s ease;
  opacity: ${props => (props.focus ? 1 : '0.5')};
  animation: ${props => widenAnimation(props.width)} 0.5s ease forwards;
`;

const BarCount = styled.p<{ focus: boolean }>`
  margin: 0;
  color: ${ThemeColors.primaryColor};
  font-weight: bold;
  margin: 8px 0;
  margin-left: 8px;
  width: 5%;
  opacity: ${props => (props.focus ? 1 : '0')};
`;

const BarText = styled.p`
  width: 25%;
  border-right: 1px dashed #d8d8d8;
  margin: 8px 0;
`;
