import React from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';
import { Column, MainButton, Row } from '../shared';
import { Icon } from '../shared/Icons';
import { Paragraph, Title } from '../Styles/Text';

export type postProps = {
  id: number;
  slug: string;
  title: string;
  sub_title: string;
  image: string;
  thumbnail: string;
  description: string;
  content: string;
  author: string;
  created_at: string;
  updated_at: string;
};
interface NewProps {
  post: postProps;
  updating: boolean;
}
const New: React.FC<NewProps> = ({ post, updating }) => {
  let history = useHistory();

  return (
    <Container>
      <Row>
        <Column align="flex-start" justify="flex-start" width="50%">
          <Title margin="0">{post.title}</Title>
          <Paragraph>{post.description}</Paragraph>
          <MainButton
            type="link"
            action={() => history.push(`/post/${post.slug}`)}
          >
            Read more <Icon icon="ArrowRight" color="white" margin="0px" />
          </MainButton>
        </Column>
        <Column align="flex-end" justify="flex-end" width="50%">
          <PostImage src={post.image} />
        </Column>
      </Row>
    </Container>
  );
};

export default New;

type ContainerProps = {
  image?: any;
};

const Container = styled.div<ContainerProps>`
  position: relative;
  background-color: ${ThemeColors.primaryColor};
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  color: ${ThemeColors.lightFontColor};
  /* overflow: hidden; */
  ${({ image }) => css`
    ${image &&
    css`
      background-image: url(${image});
      background-size: 100%;
      background-position: center;
    `}
  `}
`;

const PostImage = styled.img`
  width: 400px;
  position: absolute;
  right: 0;
  top: 0;
`;
