import styled from 'styled-components';

export const InputLayout = styled.input<{ value: string | any }>`
  border-radius: 4px;
  padding: 8px;
  border: solid 2px
    ${props => (props.value && props.value.length > 0 ? 'black' : 'gray')};
  width: 200px;
  min-height: 40px;
`;

export const InputFull = styled(InputLayout)`
  width: 100%;
`;

export const TextFull = styled(InputLayout)`
  min-height: 150px;
  width: 100%;
`;
