import React, { useEffect, useState } from 'react';
import { MdCancel, MdEdit, MdAddCircle } from 'react-icons/md';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { toCurrency, SearchFilter, apiHeaderAuth } from '../../helpers';
import { useGetData, usePost, usePutData } from '../../hooks';
import { AddonProps, AddonsComponentProps } from '../../interfaces/Addons';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import Modal from '../modal/Modal';
import Input from '../../components/Form/Input';
import { MainButton } from '../shared/Buttons';
import { ButtonMenu, Row, Warning } from '../shared';
import copy from '../../data/copy.json';
import { ProductProps } from '../../interfaces/Product';
import ChartCard from '../Charts/ChartCard';

const Products: React.FC<AddonsComponentProps> = ({ EstablishmentsData }) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);

  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [addonsData, setAddonsData] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState<Array<AddonProps>>([]);
  const [removeAddons, setRemoveAddons] = useState<Array<AddonProps>>([]);
  const [filterData, setFilterData] = useState([]);
  const [Package, setPackage] = useState({});
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('');
  const [brand, setBrand] = useState('');
  const [qty, setQty] = useState(0);
  const [soldOut, setSoldOut] = useState(0);
  const [loading, setLoading] = useState(true);
  const [resend, setResend] = useState(true);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong.');
  const [editAddon, setEditAddon] = useState<ProductProps>();
  const [sendPut, setSendPut] = useState(false);
  const [editAddonId, setEditAddonId] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('New product');
  const [modalPostUrl, setModalPostUrl] = useState(
    `establishment/${EstablishmentsData.id}/product/create`
  );
  const [sendType, setSendType] = useState('post');
  const [itemId, setItemId] = useState(0);
  useGetData(
    restHeader,
    `establishment/${EstablishmentsData.id}/addons/all`,
    resend,
    setResend,
    ~~setAddonsData,
    setLoading
  );

  const getData = useGetData(
    restHeader,
    `establishment/${EstablishmentsData.id}/products/all`,
    resend,
    setResend,
    setData,
    setLoading
  );
  const response = usePost(
    restHeader,
    modalPostUrl,
    {
      id: itemId,
      name: name,
      description: description,
      price: price,
      type: type,
      brand: brand,
      qty: qty,
      addons: selectedAddons,
      sold_out: soldOut
    },
    send,
    setData,
    () => {
      setSend(false);
      setResend(true);
    },
    null,
    setErrorMessage,
    setError,
    setErrorMessage
  );

  const updateProduct = usePutData(
    restHeader,
    `establishment/${EstablishmentsData.id}/product/edit/${editAddonId}`,
    {
      id: itemId,
      name: name,
      description: description,
      price: price,
      type: type,
      brand: brand,
      qty: qty,
      addons: selectedAddons,
      removeAddons: removeAddons,
      sold_out: soldOut
    },
    sendPut,
    null,
    setResend,
    setError,
    setErrorMessage,
    setSendPut
  );

  /**
   * Populate filter data
   */
  useEffect(() => {
    setFilterData(data);
  }, [data]);

  /**
   * Filters data with the SearchFilter func and updates setFilterData
   * @param {string} event
   */
  const changeSearchValue = (event: string) => {
    setSearch(event);
    const newData = data.filter(SearchFilter(event));
    setFilterData(newData);
  };

  /**
   * pops an Alert  with an confirm button
   * if confirmed change order status
   *
   * @param {Object} item
   * @param {String} value
   * @param {String} msg
   */
  const Alert = (msg: string) => {
    if (window.confirm('Are you sure you want to' + msg)) {
      setSend(true);
    }
  };

  useEffect(() => {
    if (send || sendPut) {
      setResend(true);
    }
  }, [send, sendPut]);
  return (
    <Container>
      {error && (
        <Warning
          type={
            errorMessage.includes('New') || errorMessage.includes('updated')
              ? 'succes'
              : 'alert'
          }
          close={() => setError(false)}
        >
          {errorMessage}
        </Warning>
      )}
      <Row JContent="flex-end" margin="16px 48px">
        <ButtonMenu>
          <Input
            label="Search"
            placeholder="enter product name"
            setInputValue={changeSearchValue}
          />
          <MainButton
            type="mainSmall"
            text={copy.buttons.newProduct.text}
            action={() => {
              setShowModal(!showModal);
              setModalPostUrl(
                `establishment/${EstablishmentsData.id}/product/create`
              );
              setName('');
              setDescription('');
              setPrice('');
              setSendType('post');
              setType('');
              setBrand('');
              setQty(0);
              setSelectedAddons([]);
              setRemoveAddons([]);
            }}
          />
        </ButtonMenu>
      </Row>
      <ChartCard title="Products" width="100%">
        <List
          headerItems={[
            'name',
            'description',
            'type',
            'addons',
            'qty',
            'sold out',
            'price',
            'actions'
          ]}
        >
          {filterData.length > 0 ? (
            filterData.map((product: ProductProps, index: number) => {
              let addonDescription: string = '';
              for (let i = 0; i < product.addons.length; i++) {
                addonDescription += `${product.addons[i].name}, `;
              }
              return (
                <ItemContainer>
                  <ListItem>{product.name}</ListItem>
                  <ListItem>{product.description}</ListItem>
                  <ListItem>{product.type}</ListItem>
                  <ListItem>{addonDescription}</ListItem>
                  <ListItem>
                    <span
                      style={{
                        color:
                          product.qty > 10 && product.qty < 100
                            ? 'black'
                            : product.qty > 100
                            ? 'green'
                            : 'red'
                      }}
                    >
                      {product.qty}
                    </span>
                  </ListItem>
                  <ListItem color={product.sold_out ? 'red' : 'green'}>
                    {product.sold_out ? 'yes' : 'no'}
                  </ListItem>
                  <ListItem>
                    {product.price && toCurrency(Number(product.price))}
                  </ListItem>
                  <ActionsContainer>
                    <Action
                      onClick={() => {
                        setShowModal(!showModal);
                        setModalTitle('Edit ' + product.name);
                        setEditAddon(product);
                        setModalPostUrl(
                          `establishment/${EstablishmentsData.id}/product/edit/${product.id}`
                        );
                        setItemId(product.id);
                        setEditAddonId(product.id);
                        setName(product.name);
                        setDescription(product.description);
                        setPrice(product.price ? product.price.toString() : '');
                        setType(product.type || '');
                        setBrand(product.brand || '');
                        setQty(product.qty);
                        setSelectedAddons(product.addons);
                        setSoldOut(product.sold_out ? 1 : 0);
                        setSendType('edit');
                      }}
                    >
                      <MdEdit size={30} />
                    </Action>
                    {/* <Action
                    onClick={() => {
                      Alert('Are you sure you want to remove this addon?');
                      setItemId(product.id);
                      setModalPostUrl(
                        `establishment/${EstablishmentsData.id}/addon/remove`
                      );
                      setPackage({ id: product.id });
                    }}
                  >
                    <MdCancel size={30} />
                  </Action> */}
                  </ActionsContainer>
                </ItemContainer>
              );
            })
          ) : (
            <ItemContainer>
              <p>No products found</p>
            </ItemContainer>
          )}
          {showModal && (
            // true
            <Modal
              closeModal={() => {
                setShowModal(false);
              }}
            >
              <h2>{modalTitle}</h2>
              <Input
                type="name"
                label="name"
                placeholder="Write product name..."
                inputValue={name}
                setInputValue={setName}
              />
              <Input
                label="price"
                placeholder="Add a price"
                inputValue={price}
                setInputValue={setPrice}
              />
              <Input
                label="description"
                placeholder="Write a descrition..."
                type="textarea"
                inputValue={description}
                setInputValue={setDescription}
              />
              <Input
                label="qty"
                placeholder="add the product qty"
                type="number"
                inputValue={qty}
                setInputValue={setQty}
              />
              <Label>sold out</Label>

              <Select
                name="set product to sold out"
                onChange={(e: any) => {
                  setSoldOut(e.target.value);
                }}
              >
                <option value={1} selected={soldOut === 1}>
                  yes
                </option>
                <option value={0} selected={soldOut === 0}>
                  no
                </option>
              </Select>

              <Label>addons</Label>

              <Select
                name="add Addons"
                onChange={(e: any) => {
                  const element: AddonProps = addonsData[e.target.value];

                  setSelectedAddons([...selectedAddons, element]);
                  setRemoveAddons(
                    removeAddons.filter(filter => filter.id !== element.id)
                  );
                }}
              >
                <option value="" selected disabled>
                  No addons selected
                </option>
                {addonsData.map((addon: AddonProps, index: number) => (
                  <option value={index}>{addon.name}</option>
                ))}
              </Select>
              <AddonsList>
                {selectedAddons.map((addon: AddonProps) => (
                  <Addons
                    onClick={() => {
                      setRemoveAddons([...removeAddons, addon]);
                      setSelectedAddons(
                        selectedAddons.filter(filter => filter.id !== addon.id)
                      );
                    }}
                  >
                    {addon.name}
                    <ButtonIcon>
                      <MdCancel />
                    </ButtonIcon>
                  </Addons>
                ))}
              </AddonsList>
              {removeAddons.length > 0 && (
                <>
                  <Label>addons will be removed</Label>
                  {removeAddons.length > 0 && (
                    <AddonsList>
                      {removeAddons.map((addon: AddonProps) => (
                        <Addons
                          onClick={() => {
                            setSelectedAddons([...selectedAddons, addon]);
                            setRemoveAddons(
                              removeAddons.filter(
                                filter => filter.id !== addon.id
                              )
                            );
                          }}
                        >
                          {addon.name}{' '}
                          <ButtonIcon>
                            <MdAddCircle />
                          </ButtonIcon>
                        </Addons>
                      ))}
                    </AddonsList>
                  )}
                </>
              )}
              <Input
                label="type"
                placeholder="add a type"
                inputValue={type}
                setInputValue={setType}
              />
              <Input
                label="brand"
                placeholder="Add a brand"
                inputValue={brand}
                setInputValue={setBrand}
              />
              <MainButton
                type="mainSmall"
                action={() => {
                  sendType === 'post' ? setSend(true) : setSendPut(true);
                  setShowModal(false);
                }}
              >
                {sendType === 'post' ? 'create product' : 'Edit product'}
              </MainButton>
            </Modal>
          )}
        </List>
      </ChartCard>
    </Container>
  );
};

export default Products;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Select = styled.select`
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;

const Addons = styled.p`
  position: relative;
  list-style: none;
  margin: 8px 0;
  background-color: ${ThemeColors.primaryColor};
  padding: 8px;
  border-radius: 24px;
  color: white;
  text-align: center;
  padding-right: 32px;
  cursor: pointer;
`;

const AddonsList = styled.div`
  margin: 8px 0;
`;

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  width: 200px;
`;

const ButtonIcon = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
`;
