// const ApiUrl = 'http://api.rapio.nl/public_html/'
// const ApiUrl = 'http://backend.rapio/'
const ApiUrl = 'http://rappio.nl/';
const currentTheme = localStorage.getItem('theme');
const ClubTheme = 'club';
const RestaurantTheme = 'restaurant';

/**
 * Colors
 */
const RapioOrange = '#E9B07F';
const RapioPink = '#dd016b';
const White = '#fff';
const TranslucentWhite = '#ffffff38';
const Black = '#000';
const TranslucentBlack = '#00000073';
const LightGrey = '#7b7c81';
const LightGrey2 = '#5e6063';
const LightGrey3 = '#A09C9C';
const LightGrey4 = '#737377';
const LightGrey5 = '#919191';
const LightGrey6 = '#d8d8d8';
const Grey = '#26262';
const DarkGrey = '#525252';
const DarkGrey2 = '#161616';
const DarkBlue = '#14171b';
const DarkBlue2 = '#181b21';
const DarkBlue3 = '#011627';
const BordoRed = '#30011e';
const red = '#F44336';
const green = 'green';
const Dark = '#1C1C1C';

export const config = {
  apiUrl: ApiUrl,
  activeTheme: currentTheme || RestaurantTheme,
  lang: 'nl-NL',
  currency: 'EUR',
  env: 'local',
  security: 'https',
  ver: 'v1',
  broadcasting: {
    broadcaster: 'pusher',
    key: 'rapio',
    cluster: 'eu',
    endpoint: '/broadcasting/auth',
    accept: 'application/json'
  },
  drinks: {
    perecentage: 9,
    spiritPercentage: 21,
    transactionFee: 0.49
  },
  themes: {
    club: {
      icons: {
        tabIcons: {
          icon1: 'glass-stange',
          icon2: 'beer',
          icon3: 'glass',
          icon4: 'beer'
        },
        navigationIcons: {},
        settingsIcons: {}
      },
      //Colors
      colors: {
        dark: {
          primaryColor: RapioPink,
          secondaryColor: White,
          //Fonts
          primaryFontColor: White,
          secondaryFontColor: RapioPink,
          thirdFontColor: DarkGrey,
          priceFontColor: LightGrey,
          placeHolderFont: LightGrey4,
          inActiveFontColor: LightGrey2,
          titleFadeFontColor: '#ffffff38',
          placeHolderFontColor: LightGrey3,
          cardsFontColor: White,
          footerFontColor: Grey,
          warningFontColor: red,
          succesFontColor: green,
          lightFontColor: White,
          titleFontColor: Dark,

          //Gradient
          linearGradientColor: 'rgba(0, 0, 0, 0.2)',
          linearGradientFadeColor: 'transparent',
          //Background
          primaryBackgroundColor: Black,
          secondaryBackgroundColor: White,
          thirdBackgroundColor: DarkBlue,
          fourthBackgroundColor: BordoRed,
          fifthBackgroundColor: DarkBlue3,
          sixthBackgroundColor: LightGrey6,
          menuBackgroundColor: DarkBlue3,
          placeHolderBackgroundColor: LightGrey4,
          productBackgroundColor: DarkBlue,
          shoppingCartBackgroundColor: TranslucentWhite,
          translucentBackgroundColor: TranslucentWhite,
          translucentBlackBackgroundColor: TranslucentBlack,
          inputBackgroundColor: DarkGrey2,
          inactiveProductGroupBackgroundColor: '#eca565',
          primaryColorFade: '#dd006b26',
          //Shadow
          shadowColor: DarkBlue2,
          secondaryShadowColor: LightGrey5,
          thirdShadowBackgroundColor: White,
          addonsBackground: DarkBlue,
          addonsShadow: DarkBlue2,
          addonsListBackgroundColor: '#18181c',
          cardFadeColor: RapioPink,
          loginBackgroundImage: '../assets/images/home-bg-1.png',
          sessionBackgroundImage: ''
        },
        light: {
          primaryColor: RapioPink,
          secondaryColor: White,
          //Fonts
          primaryFontColor: White,
          secondaryFontColor: RapioPink,
          thirdFontColor: DarkGrey,
          priceFontColor: LightGrey,
          placeHolderFont: LightGrey4,
          inActiveFontColor: LightGrey2,
          titleFadeFontColor: '#ffffff38',
          placeHolderFontColor: LightGrey3,
          cardsFontColor: White,
          footerFontColor: Grey,
          warningFontColor: red,
          succesFontColor: green,
          lightFontColor: White,
          titleFontColor: Dark,
          //Gradient
          linearGradientColor: 'rgba(0, 0, 0, 0.2)',
          linearGradientFadeColor: 'transparent',
          //Background
          primaryBackgroundColor: Black,
          secondaryBackgroundColor: White,
          thirdBackgroundColor: DarkBlue,
          fourthBackgroundColor: BordoRed,
          fifthBackgroundColor: DarkBlue3,
          sixthBackgroundColor: LightGrey6,
          menuBackgroundColor: DarkBlue3,
          placeHolderBackgroundColor: LightGrey4,
          productBackgroundColor: DarkBlue,
          shoppingCartBackgroundColor: TranslucentWhite,
          translucentBackgroundColor: TranslucentWhite,
          translucentBlackBackgroundColor: TranslucentBlack,
          inputBackgroundColor: DarkGrey2,
          inactiveProductGroupBackgroundColor: '#eca565',
          primaryColorFade: '#dd006b26',
          //Shadow
          shadowColor: DarkBlue2,
          secondaryShadowColor: LightGrey5,
          thirdShadowBackgroundColor: White,
          addonsBackground: DarkBlue,
          addonsShadow: DarkBlue2,
          addonsListBackgroundColor: '#18181c',
          cardFadeColor: RapioPink,
          loginBackgroundImage: '../assets/images/home-bg-1.png',
          sessionBackgroundImage: ''
        }
      }
    },
    restaurant: {
      icons: {
        tabIcons: {
          icon1: 'bowl',
          icon2: 'beer',
          icon3: 'beer',
          icon4: 'cake'
        },
        navigationIcons: {},
        settingsIcons: {}
      },
      colors: {
        light: {
          //Colors
          primaryColor: RapioOrange,
          secondaryColor: White,
          //Fonts
          primaryFontColor: Black,
          secondaryFontColor: RapioOrange,
          thirdFontColor: DarkGrey,
          lightFontColor: White,
          priceFontColor: LightGrey,
          titleFadeFontColor: '#00000038',
          placeHolderFont: LightGrey4,
          inActiveFontColor: LightGrey2,
          placeHolderFontColor: LightGrey3,
          cardsFontColor: White,
          footerFontColor: Grey,
          warningFontColor: red,
          succesFontColor: green,
          inactiveProductGroupFontColor: TranslucentBlack,
          //Gradient
          linearGradientColor: '#fff',
          linearGradientFadeColor: 'rgba(255, 255, 255, 0)',
          // BG
          primaryBackgroundColor: White,
          secondaryBackgroundColor: Black,
          thirdBackgroundColor: LightGrey6,
          fourthBackgroundColor: BordoRed,
          fifthBackgroundColor: LightGrey6,
          sixthBackgroundColor: LightGrey4,
          // Specifik BG COLOR
          menuBackgroundColor: DarkBlue,
          placeHolderBackgroundColor: LightGrey4,
          productBackgroundColor: White,
          shoppingCartBackgroundColor: TranslucentBlack,
          translucentBackgroundColor: TranslucentWhite,
          translucentBlackBackgroundColor: TranslucentBlack,
          inputBackgroundColor: LightGrey6,
          inactiveProductGroupBackgroundColor: '#fee7d4c2',
          primaryColorFade: '#E9B07F26',
          //Shadow
          shadowColor: DarkBlue2,
          secondaryShadowColor: LightGrey5,
          thirdShadowBackgroundColor: White,
          addonsBackground: DarkBlue,
          addonsShadow: DarkBlue2,
          addonsListBackgroundColor: '#18181c',
          loginBackgroundImage: '../assets/images/home-bg-1.png',
          cardFadeColor: '#3a2d21',
          sessionBackgroundImage: ''
        },
        dark: {
          //Colors
          primaryColor: RapioOrange,
          secondaryColor: White,
          //Fonts
          primaryFontColor: Black,
          secondaryFontColor: RapioOrange,
          thirdFontColor: DarkGrey,
          lightFontColor: White,
          priceFontColor: LightGrey,
          titleFadeFontColor: '#00000038',
          placeHolderFont: LightGrey4,
          inActiveFontColor: LightGrey2,
          placeHolderFontColor: LightGrey3,
          cardsFontColor: White,
          footerFontColor: Grey,
          warningFontColor: red,
          succesFontColor: green,
          inactiveProductGroupFontColor: TranslucentBlack,
          //Gradient
          linearGradientColor: '#fff',
          linearGradientFadeColor: 'rgba(255, 255, 255, 0)',
          // BG
          primaryBackgroundColor: White,
          secondaryBackgroundColor: Black,
          thirdBackgroundColor: LightGrey6,
          fourthBackgroundColor: BordoRed,
          fifthBackgroundColor: LightGrey6,
          sixthBackgroundColor: LightGrey4,
          // Specifik BG COLOR
          menuBackgroundColor: DarkBlue,
          placeHolderBackgroundColor: LightGrey4,
          productBackgroundColor: White,
          shoppingCartBackgroundColor: TranslucentBlack,
          translucentBackgroundColor: TranslucentWhite,
          translucentBlackBackgroundColor: TranslucentBlack,
          inputBackgroundColor: LightGrey6,
          inactiveProductGroupBackgroundColor: '#fee7d4c2',
          primaryColorFade: '#E9B07F26',
          //Shadow
          shadowColor: DarkBlue2,
          secondaryShadowColor: LightGrey5,
          thirdShadowBackgroundColor: White,
          addonsBackground: DarkBlue,
          addonsShadow: DarkBlue2,
          addonsListBackgroundColor: '#18181c',
          loginBackgroundImage: '../assets/images/home-bg-1.png',
          cardFadeColor: '#3a2d21',
          sessionBackgroundImage: ''
        }
      }
    }
  }
};
