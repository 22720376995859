import axios from 'axios';
import { useEffect } from 'react';
import { ApiUrl } from '../utils/CreatUrl';

/**
 * Get data from url
 * @param {String} url
 * @param {Boolean} resend
 * @returns {Object}
 */
export const useGetData = (
  header,
  url,
  resend = false,
  setResend,
  setData,
  setLoading
) => {
  useEffect(
    () => {
      //loading && Request();
      resend && Request();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resend]
  );
  /**
   * Perform get request
   */
  async function Request() {
    setLoading(true);
    const API = ApiUrl + url;
    await axios
      .get(API, { headers: header })
      .then(response => {
        //API REQUEST
        setData && setData(response.data);
        setResend && setResend(false);
        setLoading(false);
      })
      .catch(error => {
        console.log('get error', API, error, header);
        console.log(
          'get error message',
          error.response && error.response.data.message
        );
        // eslint-disable-next-line no-self-assign
      });
  }
};
