import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { config } from '../../config/config';
import { useGet } from '../../hooks';
import * as Interface from '../../interfaces';
import { AlertProp } from '../../interfaces/Alert';
import { setAlert } from '../../redux/actions';
import Delivery from '../Delivery/Delivery';
import Employees from '../Employees/Employees';
import Establishment from '../establishments/Establishment';
import OpenDays from '../OpenDays/OpenDays';
import PickUp from '../PickUp/PickUp';
import { Container, MainButton, Row } from '../shared';
import { Heading } from '../Styles/Text';
import TableList from '../tables/TableList';

interface SettingsProps {
  EstablishmentsData: Interface.Establishment;
  setEstablishmentData: Function;
  updating: boolean;
  setAlert: Function;
}
type SettingsRouteProps = {
  selected: string;
};

type WithRouterProps = RouteComponentProps<SettingsRouteProps>;

type AllProps = WithRouterProps & SettingsProps;

const Settings: React.FC<AllProps> = ({
  EstablishmentsData,
  setEstablishmentData,
  updating,
  match,
  setAlert
}) => {
  let history = useHistory();
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const [selectedTab, setSelectedTab] = useState('company');
  /**
   *
   */
  const { getFetch, fetchResponse } = useGet(
    {},
    `establishment/${EstablishmentsData.slug}/show`
  );
  //
  //const { alerts, setAlerts } = useAlert();
  /**
   *
   */
  useEffect(() => {
    if (fetchResponse.data.length > 0) {
      //getFetch(true);
      setEstablishmentData(fetchResponse.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponse]);
  //
  useEffect(() => {
    if (match.params.selected) {
      setSelectedTab(match.params.selected);
    }
  }, [match.params]);
  /**
   *
   */
  const updateAlerts = (alert: AlertProp) => {
    setAlert(alert);
  };

  const activeTab = () => {
    switch (selectedTab) {
      case 'delivery':
        return (
          <Delivery
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
      case 'pick up':
        return (
          <PickUp
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
      case 'opening days':
        return (
          <OpenDays
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
      case 'tables':
        return (
          <TableList
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
      case 'notifications':
        return;
      case 'company':
        return (
          <Establishment
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
      default:
        return (
          <Employees
            EstablishmentsData={EstablishmentsData}
            setEstablishmentData={setEstablishmentData}
            getEstablishment={getFetch}
            setAlerts={updateAlerts}
          />
        );
    }
  };

  return (
    <Container>
      <title> Rapio | Settings - {selectedTab}</title>
      <Row JContent="space-between" margin="16px 48px">
        <Heading>Settings</Heading>
      </Row>
      <Row JContent="flex-start">
        <MainButton
          action={() => history.push('/settings/company')}
          type="tab"
          active={selectedTab === 'company'}
        >
          Company
        </MainButton>
        {/* <MainButton
          action={() => setSelectedTab('employees')}
          type="tab"
          active={selectedTab === 'employees'}
        >
          Employees
        </MainButton> */}
        <MainButton
          action={() => history.push('/settings/delivery')}
          type="tab"
          active={selectedTab === 'delivery'}
        >
          Delivery
        </MainButton>
        <MainButton
          action={() => history.push('/settings/pick up')}
          type="tab"
          active={selectedTab === 'pick up'}
        >
          Pick up
        </MainButton>
        <MainButton
          action={() => history.push('/settings/opening days')}
          type="tab"
          active={selectedTab === 'opening days'}
        >
          Opening days
        </MainButton>
        <MainButton
          action={() => history.push('/settings/tables')}
          type="tab"
          active={selectedTab === 'tables'}
        >
          Tables
        </MainButton>
        {/* <MainButton
          action={() => setSelectedTab('notifications')}
          type="tab"
          active={selectedTab === 'notifications'}
        >
          Notifications
        </MainButton> */}
      </Row>
      {/* <AlertWrapper>
        {alerts &&
          alerts.length > 0 &&
          alerts.map((alert: AlertProp) => (
            <Alert
              title={alert.title}
              message={alert.message}
              type={alert.type}
              close={() => {
                if (alerts.length > 0) {
                  const filteredAlerts: AlertProp[] = alerts.filter(
                    filter => filter.id !== alert.id
                  );
                  setAlerts(filteredAlerts);
                }
              }}
            />
          ))}
      </AlertWrapper> */}
      {activeTab()}
    </Container>
  );
};

const mapDispatchToProps = { setAlert };

export default withRouter(connect(null, mapDispatchToProps)(Settings));
