import React from 'react';
import styled from 'styled-components';
import { ListItemProps } from '../../interfaces/List';

const ListItem: React.FC<ListItemProps> = ({
  bold,
  color,
  children,
  align
}) => {
  return (
    <Item bold={bold} color={color} align={align}>
      {children}
    </Item>
  );
};

export default ListItem;

const Item = styled.p<{ bold?: number; color?: string; align?: string }>`
  width: 25%;
  text-align: ${props => props.align || 'center'};
  color: ${props => props.color || '#011627'};
  font-weight: ${props => props.bold || 'normal'};
`;
