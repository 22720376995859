import React from 'react';
import { MdAddCircle, MdCancel } from 'react-icons/md';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import { AddonProps } from '../../interfaces/Addons';
import Input from '../Form/Input';
import Modal from '../modal/Modal';
import { Column, Icon, MainButton, Row } from '../shared';
import { Label } from '../Styles/Text';

type ProductDataProps = {
  id: number;
  name?: string;
  description?: string;
  price?: string;
  type?: string;
  image?: string;
  brand?: string;
  qty?: number;
  addons?: [AddonProps] | [];
  removeAddons?: [AddonProps] | [];
  sold_out?: number;
  tax?: string;
};

interface ProductModalProps {
  product: ProductDataProps;
  title: string;
  closeModal: Function;
  editProduct: Function;
  selectedAddons: any;
  removeAddons: any;
  submit: Function;
  setSelectedAddons: Function;
  setRemoveAddons: Function;
  addonsData: any;
  selectImage: (imageFile: any) => Promise<void>;
}

const ProductModal: React.FC<ProductModalProps> = ({
  product,
  title,
  closeModal,
  editProduct,
  submit,
  selectedAddons,
  setSelectedAddons,
  removeAddons,
  setRemoveAddons,
  addonsData,
  selectImage
}) => {
  const setName = (e: any) => {
    editProduct({ ...product, name: e });
  };
  const setPrice = (e: any) => {
    editProduct({ ...product, price: e });
  };
  const setDescription = (e: any) => {
    editProduct({ ...product, description: e });
  };
  const setQty = (e: any) => {
    editProduct({ ...product, qty: e });
  };
  const setSoldOut = (e: any) => {
    editProduct({ ...product, sold_out: e });
  };
  const setType = (e: any) => {
    editProduct({ ...product, type: e });
  };
  const setBrand = (e: any) => {
    editProduct({ ...product, brand: e });
  };
  const setTax = (e: any) => {
    editProduct({ ...product, tax: e });
  };

  return (
    <Modal noButton={true} closeModal={closeModal} width="30%">
      <Row JContent="space-between">
        <h2>{title}</h2>
        <IconWrapper onClick={() => closeModal()}>
          <Icon color={ThemeColors.primaryColor} icon="Times" size={32} />
        </IconWrapper>
      </Row>
      <Row JContent="flex-start" AItems="flex-start">
        <Column margin="0 16px 0 0">
          <Image
            id="upload_image"
            src={product.image ? product.image : '/assets/svg/image.svg'}
          />
          <Row JContent="center" margin="16px 0">
            <input
              type="file"
              id="myFile"
              name="filename"
              accept="image/*"
              onChange={e => selectImage(e)}
            />
          </Row>
        </Column>
        <Column justify="flex-start" align="flex-start" margin="0 8px 0 0">
          <Input
            type="name"
            label="Name"
            placeholder="Write product name..."
            inputValue={product.name}
            setInputValue={setName}
          />
          <Input
            step="1"
            type="number"
            label="Price"
            placeholder="Add a price"
            inputValue={product.price}
            setInputValue={setPrice}
          />
          <Input
            label="Qty"
            placeholder="add the product qty"
            type="number"
            inputValue={product.qty}
            setInputValue={setQty}
          />
          <Input
            label="Type"
            placeholder="add a type"
            inputValue={product.type}
            setInputValue={setType}
          />
          <Input
            label="Brand"
            placeholder="Add a brand"
            inputValue={product.brand}
            setInputValue={setBrand}
          />
        </Column>
        <Column justify="flex-start" align="flex-start">
          <Label>Sold out</Label>
          <Row JContent="flex-start">
            <MainButton
              type="toggle"
              action={() => setSoldOut(0)}
              active={product.sold_out === 0}
            >
              no
            </MainButton>
            <MainButton
              type="toggle"
              action={() => setSoldOut(1)}
              active={product.sold_out === 1}
            >
              yes
            </MainButton>
          </Row>

          <Label>Addons</Label>

          <Select
            name="add Addons"
            onChange={(e: any) => {
              const element: AddonProps = addonsData[e.target.value];

              setSelectedAddons([...selectedAddons, element]);
              setRemoveAddons(
                removeAddons.filter((filter: any) => filter.id !== element.id)
              );
            }}
          >
            <option value="" selected disabled>
              No addons selected
            </option>
            {addonsData &&
              addonsData.length &&
              addonsData.length > 0 &&
              addonsData.map((addon: AddonProps, index: number) => (
                <option value={index}>{addon.name}</option>
              ))}
          </Select>
          {/*  */}
          <Label>Tax</Label>

          <Select
            name="Select Tax"
            onChange={(e: any) => {
              setTax(e.target.value);
            }}
            value={product.tax}
          >
            <option value="" selected disabled>
              No Tax selected
            </option>
            <option value={0.9}>0.9</option>
            <option value={0.21}>0.21</option>
          </Select>
          <AddonsList>
            {selectedAddons.map((addon: AddonProps) => (
              <Addons
                onClick={() => {
                  setRemoveAddons([...removeAddons, addon]);
                  setSelectedAddons(
                    selectedAddons.filter(
                      (filter: AddonProps) => filter.id !== addon.id
                    )
                  );
                }}
              >
                {addon.name}
                <ButtonIcon>
                  <MdCancel />
                </ButtonIcon>
              </Addons>
            ))}
          </AddonsList>
          {removeAddons.length > 0 && (
            <>
              <Label>addons will be removed</Label>
              {removeAddons.length > 0 && (
                <AddonsList>
                  {removeAddons.map((addon: AddonProps) => (
                    <Addons
                      onClick={() => {
                        setSelectedAddons([...selectedAddons, addon]);
                        setRemoveAddons(
                          removeAddons.filter(
                            (filter: AddonProps) => filter.id !== addon.id
                          )
                        );
                      }}
                    >
                      {addon.name}{' '}
                      <ButtonIcon>
                        <MdAddCircle />
                      </ButtonIcon>
                    </Addons>
                  ))}
                </AddonsList>
              )}
            </>
          )}
        </Column>
      </Row>
      <Row JContent="flex-start" width="100%">
        <Input
          label="description"
          placeholder="Write a descrition..."
          type="textarea"
          inputValue={product.description}
          setInputValue={setDescription}
          // eslint-disable-next-line react/style-prop-object
          style="fullTextArea"
          containerWidth="100%"
        />
      </Row>
      <Row margin="16px 0">
        <MainButton
          type="mainSmall"
          action={() => {
            submit(product.id > 0 ? 'put' : 'post');
            closeModal();
          }}
        >
          {product.id > 0 ? 'Edit product' : 'create product'}
        </MainButton>
      </Row>
    </Modal>
  );
};

export default ProductModal;

const Select = styled.select`
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;

const Addons = styled.p`
  position: relative;
  list-style: none;
  margin: 8px 0;
  background-color: ${ThemeColors.primaryColor};
  padding: 8px;
  border-radius: 4px;
  color: white;
  text-align: center;
  padding-right: 32px;
  cursor: pointer;
  width: 200px;
`;

const AddonsList = styled.div`
  margin: 8px 0;
  width: 100%;
`;

const ButtonIcon = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  max-width: 250px;
  margin-right: 16px;
  border-radius: 4px;
`;
