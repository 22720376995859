import React, { FC } from 'react';
import styled from 'styled-components';
import { FetchOrders, saveOrders, setAlert } from '../../redux/actions';
import { OrdersProps, useOrders, useOrdersData } from '.';
import { connect } from 'react-redux';
import {
  Button,
  ChartCard,
  Heading,
  OrderCard,
  OrderDetailCard
} from '../../components';
import { Blank, CardWrapper, ScrollContainer } from './Orders.View';
import { OrderProps } from '../../interfaces/Order';
import { ThemeColors } from '../../config';
import { FaArrowRight, FaSpinner } from 'react-icons/fa';
import { useHistory } from 'react-router';

const OrdersFast: FC<OrdersProps> = ({
  EstablishmentsData,
  orders,
  FetchOrders,
  saveOrders,
  setAlert
}) => {
  const {
    selectedTab,
    setSelectedTab,
    groupedOrders,
    setGroupedOrders,
    selectedOrder,
    setSelectedOrder,
    deliveryTime,
    setDeliveryTime
  } = useOrders(EstablishmentsData, orders);
  //
  const { sendPut, sendPost } = useOrdersData(
    EstablishmentsData,
    deliveryTime,
    selectedOrder,
    setSelectedOrder,
    setGroupedOrders,
    setAlert,
    saveOrders
  );
  const updateOrder = (send: boolean, status?: string) => {
    if (status === 'reject') {
      sendPost(true);
    } else {
      sendPut(true);
    }
  };

  const { push } = useHistory();

  return (
    <>
      <section>
        <div className="">
          <div className="flex justify-between">
            <Heading>Orders</Heading>
            <div className="flex">
              <Button
                type="light"
                extraClasses="flex items-center mr-4"
                action={() => push('/orders/history')}
              >
                History <FaArrowRight className="ml-4" />
              </Button>
              <Button action={() => FetchOrders()} disabled={orders.loading}>
                {orders.loading ? (
                  <>
                    <FaSpinner style={{ marginRight: '8px' }} />
                  </>
                ) : (
                  'Refresh'
                )}
              </Button>
            </div>
          </div>
          <div className="flex">
            <div className="w-2/5">
              <div className="px-4 w-full h-full max-h-screen overflow-y-scroll">
                <div className="flex flex-col h-full">
                  {groupedOrders[selectedTab] &&
                    groupedOrders[selectedTab].map(
                      (item: OrderProps, index: number) => {
                        const active = item.id === selectedOrder?.id;
                        const colors = {
                          complete: ThemeColors.success,
                          prepairing: ThemeColors.warning,
                          waiting: ThemeColors.error
                        };
                        const activeColor = colors[item.status];
                        return (
                          <CardWrapper
                            onClick={() => setSelectedOrder(item)}
                            active={active}
                            borderColor={activeColor}
                          >
                            <OrderCard order={item} key={index} />
                          </CardWrapper>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
            <div className="w-3/5">
              {selectedOrder && orders.response.length > 0 ? (
                <OrderDetailCard order={selectedOrder} update={updateOrder} />
              ) : (
                <ChartCard
                  title="No order selected"
                  width="100%"
                  margin="8px 0"
                >
                  <Blank src="/assets/svg/blank.svg" />
                </ChartCard>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { Orders } = state;
  return {
    orders: Orders
  };
};
//
const mapDispatchToProps = { FetchOrders, saveOrders, setAlert };
//
export default connect(mapStateToProps, mapDispatchToProps)(OrdersFast);
