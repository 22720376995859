import React, { FC } from 'react';
import { Button } from '..';
import { apiHeaderAuth, toCurrency } from '../../helpers';
import { usePost } from '../../hooks/usePost';
import { getTokenProps } from '../../interfaces';
import { OrderProps } from '../../interfaces/Order';

interface Props {
  receipt: OrderProps;
  establishmentId: number;
}

const OrderRefundCard: FC<Props> = ({ receipt, establishmentId }) => {
  const dates = receipt.created_at && receipt.created_at.split(' ');

  const getToken: getTokenProps = JSON.parse(
    sessionStorage.getItem('user_token') || '{}'
  );
  const { sendPost, postResponse, postLoading } = usePost(
    `establishment/${establishmentId}/order/refund/cancel/${receipt.id}`,
    apiHeaderAuth(getToken.access_token),
    {}
  );

  return (
    <>
      <div
        className="grid gap-2 grid-cols-2 bg-white shadow my-8  p-6 "
        key={receipt.code}
      >
        <p className="font-bold">
          Name:
          <br /> {receipt.user_name}
        </p>
        {dates && (
          <p className="italic text-right">
            {`${dates[1] ? dates[1] : ''} `}
            <br /> {dates[0]}
          </p>
        )}
        <p className="font-bold">
          Code: <br /> {receipt.code}
        </p>
        <div className="col-start-1 col-end-3 border-t pt-2">
          {receipt.order.map((order, i) => {
            return (
              <>
                <ul>
                  <li className="flex justify-between my-2">
                    <p>
                      {order.pivot.qty} x {order.name}
                    </p>
                    <p>{toCurrency(Number(order.pivot.total))}</p>
                  </li>
                </ul>
              </>
            );
          })}
          <div className="flex justify-between items-center">
            <div>
              <p className="mt-8">Total: {toCurrency(Number(receipt.total))}</p>
              <p className="italic opacity-60">excl. service and payment fee</p>
            </div>
            {receipt.refund_id && (
              <Button
                type="light"
                extraClasses="h-12"
                disabled={postLoading}
                action={() => sendPost(true)}
              >
                Cancel refund
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderRefundCard;
