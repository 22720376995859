import { useEffect, useState } from 'react';
import { config } from '../../config';
import { AddonProps } from '../../interfaces/Addons';
import { AlertProp } from '../../interfaces/Alert';
import { ProductProps } from '../../interfaces/Product';
import { ErrorProps, ProductDataProps } from './Products.Interface';
import { Establishment } from '../../interfaces';

//
//
const useProduct = (EstablishmentsData: Establishment) => {
  const [image, setImage] = useState<any>({ contentType: '' });
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [addonsData, setAddonsData] = useState([]);
  //
  const [filterData, setFilterData] = useState<[ProductProps] | [] | never[]>(
    []
  );
  //
  const [selectedAddons, setSelectedAddons] = useState<Array<AddonProps>>([]);
  const [removeAddons, setRemoveAddons] = useState<Array<AddonProps>>([]);
  const [error, setError] = useState<ErrorProps>({
    show: false,
    type: 'alert',
    message: ''
  });

  //
  const [modalData, setModalData] = useState({
    show: false,
    title: '',
    url: `establishment/${EstablishmentsData.id}/product/create`
  });
  //
  /**
   * Populate filter data
   */
  useEffect(() => {
    setFilterData(data);
  }, [data]);

  //
  return {
    image,
    setImage,
    search,
    setSearch,
    data,
    setData,
    addonsData,
    setAddonsData,
    filterData,
    setFilterData,
    selectedAddons,
    setSelectedAddons,
    removeAddons,
    setRemoveAddons,
    error,
    setError,
    modalData,
    setModalData
  };
};

export default useProduct;
