import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { toCurrency, SearchFilter, apiHeaderAuth } from '../../helpers';
import { useGetData, usePost, usePutData } from '../../hooks';
import { AddonProps, AddonsComponentProps } from '../../interfaces/Addons';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import Modal from '../modal/Modal';
import Input from '../../components/Form/Input';
import { MainButton } from '../shared/Buttons';
import { ButtonMenu, Column, Icon, Row, Warning } from '../shared';
import copy from '../../data/copy.json';
import ChartCard from '../Charts/ChartCard';
import { Label } from '../Styles/Text';

const Addons: React.FC<AddonsComponentProps> = ({ EstablishmentsData }) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);
  //
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [Package, setPackage] = useState({});
  //
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [type, setType] = useState('');
  const [multiple, setMultiple] = useState(0);
  const [required, setRequired] = useState(0);
  //
  const [loading, setLoading] = useState(true);
  const [resend, setResend] = useState(true);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong.');
  const [editAddon, setEditAddon] = useState<AddonProps>();
  //
  const [sendPut, setSendPut] = useState(false);
  const [editAddonId, setEditAddonId] = useState<number>();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('New Addon');
  const [modalPostUrl, setModalPostUrl] = useState(
    `establishment/${EstablishmentsData.id}/addon/create`
  );
  const [sendType, setSendType] = useState('post');
  const [itemId, setItemId] = useState(0);
  const getData = useGetData(
    restHeader,
    `establishment/${EstablishmentsData.id}/addons/all`,
    resend,
    setResend,
    setData,
    setLoading
  );
  const response = usePost(
    restHeader,
    modalPostUrl,
    { id: itemId, name: name, description: description, price: price },
    send,
    setData,
    () => {
      setSend(false);
      setResend(true);
    },
    null,
    setErrorMessage,
    setError,
    setErrorMessage
  );

  const updateProduct = usePutData(
    restHeader,
    `establishment/${EstablishmentsData.id}/addon/edit/${editAddonId}`,
    { id: itemId, name: name, description: description, price: price },
    sendPut,
    null,
    setResend,
    setError,
    setErrorMessage,
    setSendPut
  );

  /**
   * Populate filter data
   */
  useEffect(() => {
    setFilterData(data);
  }, [data]);

  /**
   * Filters data with the SearchFilter func and updates setFilterData
   * @param {string} event
   */
  const changeSearchValue = (event: string) => {
    setSearch(event);
    const newData = data.filter(SearchFilter(event));
    setFilterData(newData);
  };

  /**
   * pops an Alert  with an confirm button
   * if confirmed change order status
   *
   * @param {Object} item
   * @param {String} value
   * @param {String} msg
   */
  const Alert = (msg: string) => {
    if (window.confirm('Are you sure you want to' + msg)) {
      setSend(true);
    }
  };

  return (
    <Container>
      {error && (
        <Warning
          type={
            errorMessage.includes('New') || errorMessage.includes('updated')
              ? 'succes'
              : 'alert'
          }
          close={() => setError(false)}
        >
          {errorMessage}
        </Warning>
      )}
      <Row JContent="flex-end" margin="16px 48px">
        <ButtonMenu margin="0 8px 0 0">
          <Input
            label="Search"
            placeholder="enter addon name"
            setInputValue={changeSearchValue}
          />
        </ButtonMenu>
        <ButtonMenu>
          <MainButton
            type="mainSmall"
            text={copy.buttons.newProduct.text}
            action={() => {
              setShowModal(!showModal);
              setModalPostUrl(
                `establishment/${EstablishmentsData.id}/addon/create`
              );
              setName('');
              setDescription('');
              setPrice('');
              setSendType('post');
            }}
          />
        </ButtonMenu>
      </Row>
      <ChartCard title="Addons" width="100%">
        <List headerItems={['name', 'description', 'price', 'type', 'actions']}>
          {filterData.length > 0 ? (
            filterData.map((addon: AddonProps, index: number) => {
              return (
                <ItemContainer>
                  <ListItem>{addon.name}</ListItem>
                  <ListItem>{addon.description}</ListItem>
                  <ListItem>{addon.price && toCurrency(addon.price)}</ListItem>
                  <ListItem>{addon.type}</ListItem>

                  <ActionsContainer>
                    <Action
                      onClick={() => {
                        setShowModal(!showModal);
                        setModalTitle('Edit ' + addon.name);
                        setEditAddon(addon);
                        setModalPostUrl(
                          `establishment/${EstablishmentsData.id}/addon/edit/${addon.id}`
                        );
                        setItemId(addon.id);
                        setEditAddonId(addon.id);
                        setName(addon.name);
                        setDescription(addon.description);
                        setPrice(addon.price ? addon.price.toString() : '');
                        setSendType('edit');
                      }}
                    >
                      <MdEdit size={30} />
                    </Action>
                    {/* <Action
                    onClick={() => {
                      Alert('Are you sure you want to remove this addon?');
                      setItemId(addon.id);
                      setModalPostUrl(
                        `establishment/${EstablishmentsData.id}/addon/remove`
                      );
                      setPackage({ id: addon.id });
                    }}
                  >
                    <MdCancel size={30} />
                  </Action> */}
                  </ActionsContainer>
                </ItemContainer>
              );
            })
          ) : (
            <ItemContainer>
              <h1>No Addons found</h1>
            </ItemContainer>
          )}
          {showModal && (
            // true
            <Modal
              noButton={true}
              closeModal={() => {
                setShowModal(false);
              }}
              width="30%"
            >
              <Row JContent="space-between" width="100%">
                <h2>{modalTitle}</h2>
                <IconWrapper onClick={() => setShowModal(false)}>
                  <Icon
                    color={ThemeColors.primaryColor}
                    icon="Times"
                    size={32}
                  />
                </IconWrapper>
              </Row>
              <Column
                justify="flex-start"
                align="flex-start"
                width="100%"
                margin="0 0 8px 0"
              >
                <Input
                  type="name"
                  label="name"
                  placeholder="Write addon name..."
                  inputValue={name}
                  setInputValue={setName}
                />
                <Input
                  label="price"
                  placeholder="Add a price"
                  inputValue={price}
                  setInputValue={setPrice}
                  type="number"
                />
                <Input
                  label="description"
                  placeholder="Write a descrition..."
                  type="textarea"
                  inputValue={description}
                  setInputValue={setDescription}
                  style="fullTextArea"
                  containerWidth="100%"
                />
                <Input
                  label="type"
                  placeholder="Add a type"
                  inputValue={price}
                  setInputValue={setPrice}
                />
                <Column>
                  <Label>Multiple</Label>
                  <Row JContent="flex-start">
                    <MainButton
                      type="toggle"
                      action={() => setMultiple(0)}
                      active={multiple === 0}
                    >
                      no
                    </MainButton>
                    <MainButton
                      type="toggle"
                      action={() => setMultiple(1)}
                      active={multiple === 1}
                    >
                      yes
                    </MainButton>
                  </Row>
                </Column>
                <Column>
                  <Label>Required</Label>
                  <Row JContent="flex-start">
                    <MainButton
                      type="toggle"
                      action={() => setRequired(0)}
                      active={required === 0}
                    >
                      no
                    </MainButton>
                    <MainButton
                      type="toggle"
                      action={() => setRequired(1)}
                      active={required === 1}
                    >
                      yes
                    </MainButton>
                  </Row>
                </Column>
              </Column>

              <MainButton
                type="mainSmall"
                action={() => {
                  sendType === 'post' ? setSend(true) : setSendPut(true);
                  setResend(true);
                  setShowModal(false);
                }}
              >
                {sendType === 'post' ? 'create addon' : 'Edit addon'}
              </MainButton>
            </Modal>
          )}
        </List>
      </ChartCard>
    </Container>
  );
};

export default Addons;

// const ItemContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   border-radius: 27px;
//   background-color: #f4f4f4;
//   margin: 16px 0;
// `;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;
