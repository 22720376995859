import axios from 'axios';
import { useEffect, useState } from 'react';
import { ApiUrl } from '../utils/CreatUrl';

/**
 * Get data from url
 * @param {String} url
 * @param {Boolean} resend
 * @returns {Object}
 */
export const useGet = (header: any, url: string, get?: boolean) => {
  const [response, setResponse] = useState<any>({ data: [] });
  const [loading, setLoading] = useState(true);
  const [send, setSend] = useState(get || false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  /**
   * Perform get request
   */
  async function Request() {
    setLoading(true);
    const API = ApiUrl + url;
    await axios
      .get(API, { headers: header })
      .then(response => {
        //API REQUEST
        setResponse(response.data);
        setSend(false);
        setLoading(false);
      })
      .catch(error => {
        setSend(false);
        console.log('get error', API, error, header);
        console.log('get error message', error.response);
        setErrorMessage(error.response);
        setError(true);
        // eslint-disable-next-line no-self-assign
      });
  }
  /**
   * Fetch data
   */
  useEffect(
    () => {
      //loading && Request();
      send && Request();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [send]
  );
  return {
    fetchResponse: response,
    fetchLoading: loading,
    getFetch: setSend,
    fetchError: error,
    fetchErrorMessage: errorMessage
  };
};
