import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ThemeColors } from '../../config';

const Header = ({ children, open, left }) => {
  return (
    <HeaderContainer open={open} left={left}>
      {children}
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  left: PropTypes.number
};

export default Header;

const HeaderContainer = styled.div`
  position: relative;
  background-color: ${ThemeColors.menuBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  z-index: 11;
  padding-left: 32px;
  padding-right: 16px;
  @media (max-width: 1024px) {
    height: 65px;
  }
`;
