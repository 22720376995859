/**
 * Functions
 */

import { DayProps, PostalProps } from './Table.Interfaces';
import * as Interface from '../../interfaces';
import { SeatProps } from '../../interfaces/Seat';

//
export const removeOpeningDays = (
  e: DayProps,
  setSelectedOpeningDays: any,
  postRemoveOpeningDays: any
) => {
  setSelectedOpeningDays({
    id: e.id
  });
  postRemoveOpeningDays.sendPost(true);
};
//
export const addOpeningDays = (
  e: DayProps,
  EstablishmentsData: Interface.Establishment,
  setSelectedOpeningDays: any,
  postAddOpeningDays: any
) => {
  setSelectedOpeningDays({
    day_id: e.id,
    establishment_id: EstablishmentsData.id
  });
  postAddOpeningDays.sendPost(true);
};
//
export const closeWarning = (warning: any, setWarning: Function) => {
  setWarning({ ...warning, show: false });
};
//
export const searchInputOpeningDays = (
  e: string,
  getEstablishmentOpeningDays: any,
  setEstablishmentOpeningDays: Function,
  setFilteredOpeningDays: Function
) => {
  const filtered = getEstablishmentOpeningDays.fetchResponse.filter(
    (seat: SeatProps) => {
      return seat.name.toLowerCase().includes(e.toLowerCase());
    }
  );
  setEstablishmentOpeningDays(e);
  setFilteredOpeningDays(filtered);
};
//
export const searchPostal = (
  e: string,
  getEstablishmentPostals: any,
  setEstablishmentPostalcodes: Function,
  setFilteredPostal: Function
) => {
  const filtered = getEstablishmentPostals.fetchResponse.filter(
    (postal: PostalProps) => {
      return postal.postcode.toLowerCase().includes(e.toLowerCase());
    }
  );
  setEstablishmentPostalcodes(e);
  setFilteredPostal(filtered);
};
//
export const removePostal = (
  e: PostalProps,
  setSelectedPostal: Function,
  postRemovePostal: any
) => {
  setSelectedPostal(e);
  postRemovePostal.sendPost(true);
};
//
export const addPostal = (
  e: PostalProps,
  EstablishmentsData: any,
  setSelectedPostal: Function,
  postAddPostal: any
) => {
  setSelectedPostal({
    postcode_id: e.id,
    establishment_id: EstablishmentsData.id
  });
  postAddPostal.sendPost(true);
};

export const turnOnTables = (setEnableTables: Function, EnableTables: any) => {
  setEnableTables(true);
  EnableTables.sendPost(true);
};

export const turnOffTables = (
  setEnableTables: Function,
  DisableTables: any
) => {
  setEnableTables(false);
  DisableTables.sendPost(true);
};
