import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { OrderProps } from '../interfaces/Order';
import { ThemeColors } from '../config';
import { Icon } from '../components/shared/Icons';
import { useHistory } from 'react-router-dom';
import { OrderReducerPayload } from '../redux/reducers/Orders.Reducer';

interface OrderCounterProps {
  orders: OrderReducerPayload;
}

const OrderCounter: React.FC<OrderCounterProps> = ({ orders }) => {
  let history = useHistory();

  let filteredOrders: any = {
    waiting: [],
    preparing: [],
    ready: []
  };
  orders.response.forEach((order: OrderProps) => {
    switch (order.status) {
      case 'waiting':
        filteredOrders.waiting = [...filteredOrders.waiting, order];
        break;
      case 'prepairing':
        filteredOrders.preparing = [...filteredOrders.preparing, order];
        break;
      case 'complete':
        filteredOrders.ready = [...filteredOrders.ready, order];
        break;
    }
  });

  return (
    <Container>
      <Count>Waiting: {filteredOrders.waiting.length}</Count>
      <Count>Preparing: {filteredOrders.preparing.length}</Count>
      <Count>Ready: {filteredOrders.ready.length}</Count>
      <Icon
        icon="ArrowRight"
        color="white"
        margin="0px"
        action={() => history.push('/orders')}
      />
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  const { Orders } = state;

  return {
    orders: Orders
  };
};

export default connect(mapStateToProps)(OrderCounter);

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: ${ThemeColors.primaryColor};
  padding: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  max-height: 40px;
`;

const Count = styled.div`
  background-color: #0000001a;
  font-size: 12px;
  border-radius: 4px;
  color: white;
  padding: 8px;
  margin: 0 8px;
  font-weight: bold;
  height: 100%;
`;
