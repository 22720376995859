import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../../config';
import { toCurrency } from '../../../helpers';
type dataProps = {
  x: number;
  y: number;
  label: string;
};
interface LineChartProps {
  data: [dataProps];
  onChartHover: Function;
  color?: string;
  pointRadius?: number;
  svgHeight?: number;
  svgWidth?: number;
  xLabelSize?: number;
  yLabelSize?: number;
  price: boolean;
}

const getSvgWidth2 = document.body.offsetWidth - document.body.offsetWidth / 4;

const LineChart: React.FC<LineChartProps> = ({
  data,
  color = ThemeColors.primaryColor,
  pointRadius = 5,
  svgHeight = 300,
  svgWidth = getSvgWidth2,
  xLabelSize = 20,
  yLabelSize = 80,
  onChartHover = () => {},
  price
}) => {
  const [hoverLoc, setHoverLoc] = useState<any>(null);
  const [activePoint, setActivePoint] = useState<any>(null);
  // GET X & Y || MAX & MIN
  const getX = () => {
    return {
      min: data[0].x,
      max: data[data.length - 1].x
    };
  };
  const getY = () => {
    return {
      min: data.reduce((min, p) => (p.y < min ? p.y : min), data[0].y),
      max: data.reduce((max, p) => (p.y > max ? p.y : max), data[0].y)
    };
  };
  // GET SVG COORDINATES
  const getSvgX = (x: number) => {
    return yLabelSize + (x / getX().max) * (svgWidth - yLabelSize);
  };
  const getSvgY = (y: number) => {
    const gY = getY();
    return (
      ((svgHeight - xLabelSize) * gY.max - (svgHeight - xLabelSize) * y) /
      (gY.max - gY.min)
    );
  };
  // BUILD SVG PATH
  const makePath = () => {
    let pathD = 'M ' + getSvgX(data[0].x) + ' ' + getSvgY(data[0].y) + ' ';

    pathD += data
      .map((point, i) => {
        return 'L ' + getSvgX(point.x) + ' ' + getSvgY(point.y) + ' ';
      })
      .join('');

    return (
      <>
        <defs>
          <filter id="dropshadow" height="130%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="6" />
            <feOffset dx="6" dy="6" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        <LinechartPath
          className="linechart_path"
          d={pathD}
          fill={ThemeColors.primaryColor}
          style={{
            stroke: color
            // filter: 'url(#dropshadow)'
          }}
        />
      </>
    );
  };
  // BUILD SHADED AREA
  const makeArea = () => {
    let pathD = 'M ' + getSvgX(data[0].x) + ' ' + getSvgY(data[0].y) + ' ';

    pathD += data
      .map((point, i) => {
        return 'L ' + getSvgX(point.x) + ' ' + getSvgY(point.y) + ' ';
      })
      .join('');

    const x = getX();
    const y = getY();
    pathD +=
      'L ' +
      getSvgX(x.max) +
      ' ' +
      getSvgY(y.min) +
      ' ' +
      'L ' +
      getSvgX(x.min) +
      ' ' +
      getSvgY(y.min) +
      ' ';

    return (
      <LinechartArea className="linechart_area" d={pathD} fill="url(#grad1)" />
    );
  };
  // BUILD GRID AXIS
  const makeAxis = () => {
    const x = getX();
    const y = getY();

    return (
      <LinechartAxis className="linechart_axis">
        <line
          x1={getSvgX(x.min) - yLabelSize}
          y1={getSvgY(y.min)}
          x2={getSvgX(x.max)}
          y2={getSvgY(y.min)}
          strokeDasharray="5"
        />
        <line
          x1={getSvgX(x.min) - yLabelSize}
          y1={getSvgY(y.max)}
          x2={getSvgX(x.max)}
          y2={getSvgY(y.max)}
          strokeDasharray="5"
        />
      </LinechartAxis>
    );
  };
  const makeLabels = () => {
    const padding = 5;
    return (
      <LinechartLabel className="linechart_label">
        {/* Y AXIS LABELS */}
        <text
          transform={`translate(${yLabelSize / 2}, 20)`}
          textAnchor="middle"
        >
          {price ? toCurrency(getY().max) : getY().max}
        </text>
        <text
          transform={`translate(${yLabelSize / 2}, ${
            svgHeight - xLabelSize - padding
          })`}
          textAnchor="middle"
        >
          {price ? toCurrency(getY().min) : getY().min}
        </text>
        {/* X AXIS LABELS */}
        <text
          transform={`translate(${yLabelSize}, ${svgHeight})`}
          textAnchor="start"
        >
          {data[0].label}
        </text>
        <text
          transform={`translate(${svgWidth}, ${svgHeight})`}
          textAnchor="end"
        >
          {data[data.length - 1].label}
        </text>
      </LinechartLabel>
    );
  };
  // FIND CLOSEST POINT TO MOUSE
  const getCoords = (e: any) => {
    const svgLocation = document
      .getElementsByClassName('linechart')[0]
      .getBoundingClientRect();
    const adjustment = (svgLocation.width - svgWidth) / 2; //takes padding into consideration
    const relativeLoc = e.clientX - svgLocation.left - adjustment;

    let svgData: any = [];
    data.forEach((point, i) => {
      svgData.push({
        svgX: getSvgX(point.x),
        svgY: getSvgY(point.y),
        d: point.label,
        p: point.y
      });
    });

    let closestPoint = {};
    for (let i = 0, c = 500; i < svgData.length; i++) {
      if (Math.abs(svgData[i].svgX - hoverLoc) <= c) {
        c = Math.abs(svgData[i].svgX - hoverLoc);
        closestPoint = svgData[i];
      }
    }

    if (relativeLoc - yLabelSize < 0) {
      stopHover();
    } else {
      setHoverLoc(relativeLoc);
      setActivePoint(closestPoint);
      onChartHover(relativeLoc, closestPoint);
    }
  };
  // STOP HOVER
  const stopHover = () => {
    setHoverLoc(null);
    setActivePoint(null);
    onChartHover(null, null);
  };
  // MAKE ACTIVE POINT
  const makeActivePoint = () => {
    return (
      <LinechartPoint
        className="linechart_point"
        style={{ stroke: color }}
        r={pointRadius}
        cx={activePoint.svgX}
        cy={activePoint.svgY}
      />
    );
  };
  // MAKE HOVER LINE
  const createLine = () => {
    return (
      <line
        className="hoverLine"
        x1={hoverLoc}
        y1={-8}
        x2={hoverLoc}
        y2={svgHeight - xLabelSize}
      />
    );
  };

  return (
    <LinechartSvg
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      className={'linechart'}
      onMouseLeave={() => stopHover()}
      onMouseMove={e => getCoords(e)}
      onClick={e => getCoords(e)}
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop
            offset="50%"
            style={{ stopColor: ThemeColors.primaryColor, stopOpacity: 1 }}
          />
          <stop
            offset="100%"
            style={{ stopColor: '#ffffff', stopOpacity: 1 }}
          />
        </linearGradient>
      </defs>
      <g>
        {makeAxis()}
        {makePath()}
        {makeArea()}
        {makeLabels()}
        {hoverLoc ? createLine() : null}
        {hoverLoc ? makeActivePoint() : null}
      </g>
    </LinechartSvg>
  );
};

export default LineChart;

const LinechartSvg = styled.svg`
  padding: 8px;
`;

const LinechartPath = styled.path`
  stroke-width: 3;
  fill: none;
`;

const LinechartAxis = styled.g`
  stroke: #bdc3c7;
`;

const LinechartPoint = styled.circle`
  fill: #fff;
  stroke-width: 2;
`;

const LinechartArea = styled.path`
  padding: 8px;
  /* fill: ${ThemeColors.primaryColor}; */
  stroke: none;
  opacity: 0.4;
`;

const LinechartLabel = styled.g`
  fill: ${ThemeColors.primaryColor};
  font-weight: 700;
`;
