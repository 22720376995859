import { css as ecss } from '@emotion/core';
import { ThemeColors } from '../../config';

import styled, { css } from 'styled-components';

/**
 * Styling
 */

export const override = ecss`
display: block;
margin: 0 auto;
border-color: white;
`;

type BtnBaseProps = {
  full: boolean;
  disabled: boolean;
  active?: boolean;
  color?: string;
};

export const BtnBase = styled.a<BtnBaseProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.full ? '50%' : 'initial')};
  min-width: 250px;
  color: white;
  font-size: 24px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  /* margin: 16px 0; */
  pointer-events: ${props => (props.disabled ? 'none' : 'initial')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  transition: all 1s ease;
`;

export const BtnMain = styled(BtnBase)`
  background-color: ${ThemeColors.primaryColor};
`;

export const BtnMainSmall = styled(BtnMain)`
  min-width: 150px;
  font-size: 16px;
`;

export const BtnMainSmallest = styled(BtnMainSmall)`
  min-width: 50px;
`;

export const BtnSecondary = styled(BtnBase)`
  border: solid 3px ${ThemeColors.primaryColor};
  color: ${ThemeColors.primaryColor};
  box-shadow: none;
`;

export const BtnLink = styled(BtnBase)`
  border: none;
  text-decoration: underline;
  margin: 0 !important;
  padding: 0;
  box-shadow: none;
  width: initial !important;
  min-width: initial;
  &:hover {
    font-size: 28px;
  }
`;

export const BtnTab = styled(BtnBase)`
  position: relative;
  font-weight: bold;
  font-size: 20px;
  min-width: initial;
  border-radius: 0;
  box-shadow: none;
  border: none;
  color: ${ThemeColors.titleFontColor};
  transition: all 0.5s ease;
  opacity: ${props => (!props.active ? 0.5 : 1)};
  margin-top: 0;
  border-bottom: solid 2px;
  border-bottom-color: transparent;
  padding: 16px 0;
  margin-left: 40px;

  ${props =>
    props.active &&
    css`
      border-bottom: solid 2px;
      color: ${props.color || ThemeColors.primaryColor};
      border-bottom-color: ${props.color || ThemeColors.primaryColor};
    `}

  &:hover {
    opacity: 1;
    color: ${props => props.color || ThemeColors.primaryColor};
    border-bottom-color: ${props => props.color || ThemeColors.primaryColor};
  }
`;

export const BtnToggle = styled(BtnBase)`
  color: black;
  font-size: 12px;
  min-width: 50px;
  padding: 8px;
  margin: 0 8px 4px 8px;
  ${props =>
    props.active &&
    css`
      background-color: ${ThemeColors.primaryColor};
      color: white;
    `}
`;

const buttonSize = '35px';

export const BtnSwitch = styled(BtnBase)`
  min-width: 75px;
  border-radius: 30px;
  height: 20px;
  padding: 0;
  &::after {
    content: ' ';
    position: relative;
    width: ${buttonSize};
    height: ${buttonSize};
    border-radius: 50%;
    background-color: ${ThemeColors.primaryColor};
    transition: all 1s ease;
    left: -50%;

    ${props =>
      props.active &&
      css`
        left: 50%;
      `}
  }
`;

export const BtnLight = styled(BtnBase)`
  min-width: 125px;
  box-shadow: none;
  background-color: ${ThemeColors.primaryColorFade};
  color: ${ThemeColors.primaryColor};
  font-weight: bold;
  font-size: 14px;
  padding: 12px 4px;
`;
