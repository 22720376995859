import React from 'react';
import styled, { css } from 'styled-components';
import { InputLayout, InputFull, TextFull } from './Input.style';
const inputMap = {
  default: InputLayout,
  full: InputFull,
  fullTextArea: TextFull
};

interface InputProps {
  label?: string;
  placeholder: string;
  inputValue?: string | number;
  setInputValue: Function;
  type?: string;
  style?: keyof typeof inputMap;
  labelColor?: string;
  containerWidth?: string;
  disabled?: boolean;
  step?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  placeholder,
  inputValue,
  setInputValue,
  type,
  style = 'default',
  labelColor,
  containerWidth,
  disabled = false,
  ...props
}) => {
  const asType: any = type === 'textarea' ? 'textarea' : 'input';
  const Type = inputMap[style];
  return (
    <InputContainer width={containerWidth}>
      {label && <Label color={labelColor}>{label}</Label>}
      <Type
        type={type}
        as={asType}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e: { target: { value: any } }) =>
          setInputValue(e.target.value)
        }
        disabled={disabled}
        {...props}
      />
    </InputContainer>
  );
};

export default Input;

const Label = styled.p<{ color?: string }>`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
`;

const InputContainer = styled.div<{ width?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;
