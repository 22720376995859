import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import copy from '../../data/copy.json';
import Card from '../cards/Card';

const Home: React.FC = () => {
  return (
    <Container>
      {copy.menuItems.map((item, index) => {
        return (
          item.text.toLowerCase() !== 'home' && (
            <Link key={index} to={item.url}>
              <Card type="tile">
                <h1>{item.text}</h1>
              </Card>
            </Link>
          )
        );
      })}
    </Container>
  );
};

export default Home;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-decoration: none;
  }
`;
