import React from 'react';
import { ClipLoader } from 'react-spinners';
import {
  BtnMain,
  BtnSecondary,
  BtnMainSmall,
  BtnLink,
  BtnTab,
  override,
  BtnMainSmallest,
  BtnToggle,
  BtnSwitch,
  BtnLight
} from './Buttons.style';

const buttonMap = {
  main: BtnMain,
  ghost: BtnSecondary,
  mainSmall: BtnMainSmall,
  mainSmallest: BtnMainSmallest,
  link: BtnLink,
  tab: BtnTab,
  toggle: BtnToggle,
  switch: BtnSwitch,
  light: BtnLight
};

interface MainButtonProps {
  text?: string;
  link?: string;
  action?: Function;
  disabled?: boolean;
  full?: boolean;
  loading?: boolean;
  type?: keyof typeof buttonMap;
  icon?: string;
  active?: boolean;
  color?: string;
  extraClass?: string;
}

export const MainButton: React.FC<MainButtonProps> = ({
  children,
  text,
  link,
  action = () => {},
  disabled = false,
  full = false,
  loading,
  type = 'main',
  icon,
  active,
  color,
  extraClass
}) => {
  const Btn = buttonMap[type];

  return (
    <Btn
      href={link}
      onClick={() => action()}
      disabled={disabled}
      full={full}
      active={active}
      color={color}
      className={extraClass}
    >
      {loading ? (
        <ClipLoader
          css={override}
          size={10}
          //size={"150px"} this also works
          color={'#fff'}
          loading={loading}
        />
      ) : (
        text || children
      )}
    </Btn>
  );
};

export const TailWindButtons = {
  main: 'bg-primary text-white rounded py-2 px-5',
  ghost: '',
  mainSmall: '',
  mainSmallest: '',
  link: '',
  tab: '',
  toggle: '',
  switch: '',
  light: 'bg-primary-light text-primary  rounded py-2 px-5'
};

interface Props {
  type?: keyof typeof TailWindButtons;
  disabled?: boolean;
  action?: Function;
  extraClasses?: string;
}

export const Button: React.FC<Props> = ({
  children,
  type = 'main',
  extraClasses = '',
  disabled,
  action = () => {}
}) => {
  const selectedButton = TailWindButtons[type];
  return (
    <button
      onClick={() => action()}
      className={`${selectedButton} transition-opacity duration-1000 ${
        disabled ? 'opacity-50' : 'opacity-100'
      } ${extraClasses}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
