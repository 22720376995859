import React, { useEffect, useState } from 'react';
import { config, ThemeColors } from '../../config';
import { useGet } from '../../hooks/useGet';
import { usePost } from '../../hooks/usePost';
import * as Interface from '../../interfaces';
import { firstLetterCapital } from '../../utils/Transform';
import ChartCard from '../Charts/ChartCard';
import Input from '../Form/Input';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import { Column, Container, Row, Warning } from '../shared';
import { Icon } from '../shared/Icons';
import EditOpeningDay from './EditOpeningDay';

const typesProps = {
  alert: 'alert',
  succes: 'succes'
};

type AlertProps = {
  type: keyof typeof typesProps;
  show: boolean;
  message: string;
};

type PostalProps = {
  id: number;
  postcode: string;
  city: string;
};

export type DayProps = {
  id: number;
  day_id: number;
  day: string;
  opening_hours: string;
  closing_hours: string;
  start_time_delivery: string;
  start_time_pick_up: string;
};

interface OpenDaysProps {
  EstablishmentsData: Interface.Establishment | any;
  setEstablishmentData: Function;
  getEstablishment: Function;
  setAlerts: Function;
}

const OpenDays: React.FC<OpenDaysProps> = ({
  EstablishmentsData,
  setAlerts
}) => {
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const apiHeaderAuth = (token: string, contentType: string) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}` //token
    };
    return header;
  };
  /**
   * States
   */
  const [warning, setWarning] = useState<AlertProps>({
    show: false,
    type: 'succes',
    message: ''
  });
  const [establishmentOpeningDays, setEstablishmentOpeningDays] = useState('');
  const [searchOpeningDays] = useState('');
  const [filteredOpeningDays, setFilteredOpeningDays] = useState([]);

  const [selectedOpeningDays, setSelectedOpeningDays] = useState<any>();
  const [selectedUpdate] = useState<any>({
    package: {},
    type: ''
  });
  const [establishmentPostalcodes, setEstablishmentPostalcodes] = useState('');
  const [searchPostals, setSearchPostals] = useState('');
  const [filteredPostals, setFilteredPostal] = useState([]);
  const [selectedPostal, setSelectedPostal] = useState<any>();

  /**
   *
   */
  const getEstablishmentOpeningDays = useGet(
    {},
    `establishment/${EstablishmentsData.id}/opening_days/show`
  );
  //
  const getOpeningDays = useGet({}, `establishment/opening_days/show/all`);
  //
  const postAddOpeningDays = usePost(
    `establishment/${EstablishmentsData.id}/opening_days/add`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedOpeningDays
  );
  //
  const postRemoveOpeningDays = usePost(
    `establishment/${EstablishmentsData.id}/opening_days/remove`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedOpeningDays
  );
  //
  const updateEstablishment = usePost(
    `establishment/${EstablishmentsData.id}/update/${selectedUpdate.type}`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedUpdate.package
  );
  //
  const getEstablishmentPostals = useGet(
    {},
    `establishment/${EstablishmentsData.id}/postalcodes/show`
  );
  //
  const getPostals = useGet({}, `search/postalcode/${searchPostals}`);
  //
  const postAddPostal = usePost(
    `establishment/${EstablishmentsData.id}/postal/add`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedPostal
  );
  //
  const postRemovePostal = usePost(
    `establishment/${EstablishmentsData.id}/postal/remove`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedPostal
  );
  /**
   *
   *
   */
  useEffect(() => {
    getEstablishmentOpeningDays.getFetch(true);
    getOpeningDays.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  useEffect(() => {
    if (getEstablishmentOpeningDays.fetchResponse) {
      setFilteredOpeningDays(getEstablishmentOpeningDays.fetchResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEstablishmentOpeningDays.fetchResponse]);
  //
  useEffect(() => {
    if (searchOpeningDays.length > 0) {
      getOpeningDays.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpeningDays]);
  //
  useEffect(() => {
    getEstablishmentOpeningDays.getFetch(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveOpeningDays.postResponse, postAddOpeningDays.postResponse]);
  //
  useEffect(() => {
    getEstablishmentOpeningDays.getFetch(true);
    if (
      postRemoveOpeningDays.postResponse &&
      postRemoveOpeningDays.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to remove an opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postRemoveOpeningDays.errorResponse &&
      postRemoveOpeningDays.errorResponse.response &&
      (postRemoveOpeningDays.errorResponse.response.status === 401 ||
        postRemoveOpeningDays.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postRemoveOpeningDays.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveOpeningDays.postResponse, postRemoveOpeningDays.errorResponse]);
  //
  useEffect(() => {
    getEstablishmentOpeningDays.getFetch(true);
    if (
      postAddOpeningDays.postResponse &&
      postAddOpeningDays.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to add an opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postAddOpeningDays.errorResponse &&
      postAddOpeningDays.errorResponse.response &&
      (postAddOpeningDays.errorResponse.response.status === 401 ||
        postAddOpeningDays.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postAddOpeningDays.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postAddOpeningDays.postResponse, postAddOpeningDays.errorResponse]);
  //
  useEffect(() => {
    updateEstablishment.sendPost(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate]);
  //
  useEffect(() => {
    if (
      updateEstablishment.postResponse &&
      updateEstablishment.postResponse.status &&
      updateEstablishment.postResponse.status === 200
    ) {
      //   localStorage.setItem(
      //     'establishment',
      //     JSON.stringify({
      //       ...establishmentStore,
      //       delivery: enableDelivery
      //     })
      //   );
      //setEstablishmentData({ ...establishmentStore, delivery: enableDelivery });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEstablishment.postResponse]);

  useEffect(() => {
    getEstablishmentPostals.getFetch(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  useEffect(() => {
    if (getEstablishmentPostals.fetchResponse) {
      setFilteredPostal(getEstablishmentPostals.fetchResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEstablishmentPostals.fetchResponse]);
  //
  useEffect(() => {
    if (searchPostals.length > 0) {
      getPostals.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPostals]);
  //
  useEffect(() => {
    getEstablishmentPostals.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemovePostal.postResponse, postAddPostal.postResponse]);
  /**
   * Functions
   */
  //
  const removeOpeningDays = (e: DayProps) => {
    setSelectedOpeningDays({
      id: e.id
    });
    postRemoveOpeningDays.sendPost(true);
  };
  //
  const addOpeningDays = (e: DayProps) => {
    setSelectedOpeningDays({
      opening_day_id: e.id,
      establishment_id: EstablishmentsData.id
    });
    postAddOpeningDays.sendPost(true);
  };
  //
  const closeWarning = () => {
    setWarning({ ...warning, show: false });
  };
  //
  const searchInputOpeningDays = (e: string) => {
    const filtered = getEstablishmentOpeningDays.fetchResponse.filter(
      (day: DayProps) => {
        return day.day.toLowerCase().includes(e.toLowerCase());
      }
    );
    setEstablishmentOpeningDays(e);
    setFilteredOpeningDays(filtered);
  };
  //
  const searchPostal = (e: string) => {
    const filtered = getEstablishmentPostals.fetchResponse.filter(
      (postal: PostalProps) => {
        return postal.postcode.toLowerCase().includes(e.toLowerCase());
      }
    );
    setEstablishmentPostalcodes(e);
    setFilteredPostal(filtered);
  };
  //
  const removePostal = (e: PostalProps) => {
    setSelectedPostal(e);
    postRemovePostal.sendPost(true);
  };
  //
  const addPostal = (e: PostalProps) => {
    setSelectedPostal({
      postcode_id: e.id,
      establishment_id: EstablishmentsData.id
    });
    postAddPostal.sendPost(true);
  };

  return (
    <Container>
      {warning.show && (
        <Warning type={warning.type} close={() => closeWarning()}>
          {warning.message}
        </Warning>
      )}
      {/* <ChartCard width="100%">
        <Title margin="0 0 16px 0">Open</Title>
        <Row>
          <Span>
            <MainButton
              type={enableDelivery ? 'main' : 'ghost'}
              action={() => turnOnDelivery()}
            >
              Yes
            </MainButton>
          </Span>
          <Span>
            <MainButton
              type={enableDelivery ? 'ghost' : 'main'}
              action={() => turnOffDelivery()}
            >
              No
            </MainButton>
          </Span>
        </Row>
      </ChartCard> */}
      <Row AItems="flex-start">
        <Column margin="0 8px 0 0">
          <ChartCard title="Postalcodes" width="100%">
            <Input
              inputValue={establishmentPostalcodes}
              setInputValue={searchPostal}
              placeholder="Search for a tag"
              label="Tags"
              labelColor={ThemeColors.primaryColor}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              containerWidth="100%"
            />
            <List headerItems={['name', '']}>
              {filteredPostals.length > 0 &&
                filteredPostals.map((item: PostalProps) => (
                  <ItemContainer>
                    <ListItem>{item.postcode}</ListItem>
                    <Icon
                      icon="MinusCircle"
                      action={() => removePostal(item)}
                    />
                  </ItemContainer>
                ))}
            </List>
          </ChartCard>
        </Column>
        <Column margin="0 0 0 8px">
          <ChartCard title="Add Postalcode" width="100%">
            <Input
              inputValue={searchPostals}
              setInputValue={setSearchPostals}
              placeholder="Search for a Postal"
              label="Postals"
              labelColor={ThemeColors.primaryColor}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              containerWidth="100%"
            />
            <List headerItems={['name']}>
              {getPostals.fetchResponse &&
                getPostals.fetchResponse.length > 0 &&
                getPostals.fetchResponse.map((item: PostalProps) => {
                  const findPostal =
                    filteredPostals.length > 0 &&
                    filteredPostals.find(
                      (Postal: PostalProps) => Postal.id === item.id
                    );
                  return (
                    <ItemContainer>
                      <ListItem>{item.postcode}</ListItem>
                      {!findPostal ? (
                        <Icon
                          icon="PlusCircle"
                          action={() => addPostal(item)}
                        />
                      ) : (
                        '(already added)'
                      )}
                    </ItemContainer>
                  );
                })}
            </List>
          </ChartCard>
        </Column>
      </Row>
      <Row AItems="flex-start">
        <Column margin="0 8px 0 0">
          <ChartCard title="Open days" width="100%">
            <Input
              inputValue={establishmentOpeningDays}
              setInputValue={searchInputOpeningDays}
              placeholder="Search for a day"
              label="Days"
              labelColor={ThemeColors.primaryColor}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              containerWidth="100%"
            />
            <List headerItems={['day', '']} align="left">
              {filteredOpeningDays.length > 0 &&
                filteredOpeningDays
                  .sort((a: any, b: any) => a.day_id - b.day_id)
                  .map((item: DayProps) => (
                    <ItemContainer>
                      <Column>
                        <Row JContent="space-between">
                          <ListItem align="left">
                            <b>{firstLetterCapital(item.day)}</b>
                          </ListItem>
                          <Icon
                            icon="MinusCircle"
                            action={() => removeOpeningDays(item)}
                          />
                        </Row>
                        <EditOpeningDay
                          establishmentData={EstablishmentsData}
                          day={item}
                          setWarning={setWarning}
                          setAlerts={setAlerts}
                        />
                      </Column>
                    </ItemContainer>
                  ))}
            </List>
          </ChartCard>
        </Column>
        <Column margin="0 0 0 8px">
          <ChartCard title="Add day" width="100%">
            <List headerItems={['day']} align="left">
              {getOpeningDays.fetchResponse &&
                getOpeningDays.fetchResponse.length > 0 &&
                getOpeningDays.fetchResponse
                  .sort((a: any, b: any) => a.id - b.id)
                  .map((item: DayProps) => {
                    const findDay =
                      getEstablishmentOpeningDays.fetchResponse.length > 0 &&
                      getEstablishmentOpeningDays.fetchResponse.find(
                        (day: any) =>
                          day.day.toLowerCase() === item.day.toLowerCase()
                      );
                    return (
                      <ItemContainer>
                        <ListItem align="left">
                          {firstLetterCapital(item.day)}
                        </ListItem>
                        {!findDay ? (
                          <Icon
                            icon="PlusCircle"
                            action={() => addOpeningDays(item)}
                          />
                        ) : (
                          '(already added)'
                        )}
                      </ItemContainer>
                    );
                  })}
            </List>
          </ChartCard>
        </Column>
      </Row>
    </Container>
  );
};

export default OpenDays;
