import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { usePost } from '../../hooks/usePost';
import { Establishment } from '../../interfaces';
import { FetchProps } from '../../interfaces/Fetch.interface';
import { SeatProps } from '../../interfaces/Seat';
import { AlertFields } from '../Alert/Alert';
import ChartCard from '../Charts/ChartCard';
import Grid from '../Grid/Grid';
import { MainButton } from '../shared';
import { Paragraph, Title } from '../Styles/Text';

interface Props {
  seat: SeatProps;
  index: number;
  getSeats: FetchProps;
  EstablishmentsData: Establishment;
  setAlert: Function;
}

const apiHeaderAuth = (token: string) => {
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}` //token
  };
  return header;
};

let newAlert: AlertFields = {
  title: 'Fetching was an success',
  message: 'Loaded the latest table data',
  type: 'success'
};

const Seat: FC<Props> = ({
  seat,
  index,
  getSeats,
  EstablishmentsData,
  setAlert
}) => {
  let history = useHistory();
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');

  const active = seat.status;

  const {
    postResponse,
    postLoading,
    postError,
    sendPost,
    errorResponse
  } = usePost(
    `establishment/${EstablishmentsData.id}/seat/close`,
    apiHeaderAuth(getToken.access_token),
    { session_id: seat.session }
  );

  useEffect(() => {
    if (postResponse) {
      getSeats.getFetch(true);
      setAlert({
        ...newAlert,
        title: 'Set order to paid',
        message: 'System was able to successfully change the payment status'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse]);

  useEffect(() => {
    if (postError) {
      getSeats.getFetch(true);
      setAlert({
        ...newAlert,
        title: 'Oop something went wrong',
        message: errorResponse ? errorResponse : 'Please try again later'
      });
    }
  }, [errorResponse, getSeats, postError, setAlert]);

  /**
   * Calculates total cash  of order
   * @returns number
   */
  const cashTotal = () => {
    let total = 0;
    seat.orders.forEach((order: any) => {
      const getOrder = order[0];
      if (getOrder.payment_method === 'cash') {
        total += Number(getOrder.total);
      }
    });

    return total;
  };

  /**
   * Calculates total price of seat
   * @returns number
   */
  const total = () => {
    let total = 0;
    seat.orders.forEach((order: any) => {
      const getOrder = order[0];

      total += Number(getOrder.total);
    });

    return total;
  };

  /**
   * Check if there are still unfinished orders
   */
  const checkSession = () => {
    const hasOpenOrder = seat.orders.some(
      (order: any) =>
        order[0].status.toLowerCase().includes('waiting') ||
        order[0].status.toLowerCase().includes('prepairing')
    );
    if (hasOpenOrder) {
      alert('Table still has uncompleted orders');
    } else {
      sendPost(true);
    }
  };

  const seatData = () => {
    let paymentsMethods: string = '';
    let activeOrders = 0;
    let hasCash = false;
    if (seat.orders) {
      seat.orders.forEach((order: any) => {
        if (order[0]) {
          if (!paymentsMethods.includes(order[0].payment_method)) {
            paymentsMethods += ` ${order[0].payment_method}`;
            if (order[0].payment_method === 'cash') {
              hasCash = true;
            }
          }

          if (order[0].status !== 'pickedUp') activeOrders++;
        }
      });
    }

    return { paymentsMethods, activeOrders, hasCash };
  };

  const { paymentsMethods, activeOrders, hasCash } = seatData();

  return (
    <>
      <ChartCard active={active} key={index} margin="0">
        <Grid gapCols="gap-x-none" gridCols="grid-cols-2" extraClass="w-full">
          <SeatTitle>Table:</SeatTitle>
          <SeatTable fontSize="60px">{seat.name}</SeatTable>
          <SeatInfoWrapper>
            <Paragraph margin="0 0 4px 0">
              paid:
              {active && (
                <HighLight payed={!hasCash}>
                  <b>{hasCash ? ` Has cash` : ` yes`}</b>
                </HighLight>
              )}
            </Paragraph>
            <Paragraph margin="4px 0">
              Active orders: {active && activeOrders}
            </Paragraph>
            <Paragraph margin="4px 0">
              Total orders: {active && seat.orders.length}
            </Paragraph>
            <Paragraph margin="4px 0">
              Payment methods: {active && paymentsMethods}
            </Paragraph>
          </SeatInfoWrapper>

          {active && (
            <>
              <ButtonWrapper column="1/2">
                <MainButton type="light" action={() => checkSession()}>
                  Close Session
                </MainButton>
              </ButtonWrapper>
              <ButtonWrapper column="2/3">
                <MainButton
                  type="light"
                  action={() =>
                    history.push(`/table/${seat.session}`, {
                      orders: seat.orders
                    })
                  }
                >
                  View
                </MainButton>
              </ButtonWrapper>
            </>
          )}
        </Grid>
      </ChartCard>
    </>
  );
};

export default Seat;

const SeatTitle = styled(Title)`
  grid-row: 1;
  margin: 0;
`;
const SeatTable = styled(Title)`
  margin: 0;
  grid-row: 2;
  text-align: center;
`;

const SeatInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-column: 2/3;
  grid-row: 1/3;
`;

const ButtonWrapper = styled.div<{ column: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  grid-row: 3;
  grid-column: ${props => props.column};
`;

const HighLight = styled.span<{ payed: boolean }>`
  color: ${props => (props.payed ? '#06D98E' : 'black')};
`;
