import { useEffect, useState } from 'react';
import * as Interface from '../../interfaces';
import { usePost } from '../../hooks/usePost';
import { useGet } from '../../hooks/useGet';
import { config } from '../../config';
/**
 *
 * @returns
 */
export const useTableData = (
  EstablishmentsData: Interface.Establishment,
  selectedOpeningDays: any,
  selectedPostal: any,
  selectedUpdate: any,
  searchPostals: string,
  searchOpeningDays: string,
  setAlerts: Function,
  setFilteredOpeningDays: Function,
  setFilteredPostal: Function
) => {
  const apiHeaderAuth = (token: string, contentType: string) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}` //token
    };
    return header;
  };
  //
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const AuthHeader = apiHeaderAuth(getToken.access_token, 'application/json');
  /**
   *
   */
  const getEstablishmentTables = useGet(
    AuthHeader,
    `establishment/${EstablishmentsData.id}/seats/show`
  );
  //
  const getOpeningDays = useGet({}, `establishment/opening_days/show/all`);
  //
  const postAddSeat = usePost(
    `establishment/${EstablishmentsData.id}/seats/add`,
    AuthHeader,
    { name: 'add name', seats: '0', active: '0' }
  );
  //
  const postRemoveSeat = usePost(
    `establishment/${EstablishmentsData.id}/seats/remove`,
    AuthHeader,
    selectedOpeningDays
  );
  //
  const postUpdateSeat = usePost(
    `establishment/${EstablishmentsData.id}/seats/remove`,
    AuthHeader,
    selectedOpeningDays
  );
  //
  const updateEstablishment = usePost(
    `establishment/${EstablishmentsData.id}/update/${selectedUpdate.type}`,
    AuthHeader,
    selectedUpdate.package
  );
  //
  const getEstablishmentPostals = useGet(
    {},
    `establishment/${EstablishmentsData.id}/postalcodes/show`
  );
  //
  const getPostals = useGet({}, `search/postalcode/${searchPostals}`);
  //
  const postAddPostal = usePost(
    `establishment/${EstablishmentsData.id}/postal/add`,
    AuthHeader,
    selectedPostal
  );
  //
  const postRemovePostal = usePost(
    `establishment/${EstablishmentsData.id}/seats/remove`,
    AuthHeader,
    selectedPostal
  );

  const EnableTables = usePost(
    `establishment/${EstablishmentsData.id}/seats/toggle`,
    AuthHeader,
    {}
  );
  //
  const DisableTables = usePost(
    `establishment/${EstablishmentsData.id}/postal/remove`,
    AuthHeader,
    selectedPostal
  );

  useEffect(() => {
    getEstablishmentTables.getFetch(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveSeat.postResponse, postAddSeat.postResponse]);
  //
  useEffect(() => {
    getEstablishmentTables.getFetch(true);
    if (
      postRemoveSeat.postResponse &&
      postRemoveSeat.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to remove an opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postRemoveSeat.errorResponse &&
      postRemoveSeat.errorResponse.response &&
      (postRemoveSeat.errorResponse.response.status === 401 ||
        postRemoveSeat.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postRemoveSeat.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveSeat.postResponse, postRemoveSeat.errorResponse]);
  //
  useEffect(() => {
    getEstablishmentTables.getFetch(true);
    if (postAddSeat.postResponse && postAddSeat.postResponse.status === 200) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to add an opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postAddSeat.errorResponse &&
      postAddSeat.errorResponse.response &&
      (postAddSeat.errorResponse.response.status === 401 ||
        postAddSeat.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postAddSeat.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postAddSeat.postResponse, postAddSeat.errorResponse]);
  //
  useEffect(() => {
    getEstablishmentTables.getFetch(true);
    getOpeningDays.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  useEffect(() => {
    if (getEstablishmentTables.fetchResponse) {
      setFilteredOpeningDays(getEstablishmentTables.fetchResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEstablishmentTables.fetchResponse]);
  //
  useEffect(() => {
    if (searchOpeningDays.length > 0) {
      getOpeningDays.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpeningDays]);
  //

  //
  useEffect(() => {
    updateEstablishment.sendPost(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate]);
  //
  useEffect(() => {
    const establishmentStore = JSON.parse(
      localStorage.getItem('establishment') || '{}'
    );
    if (
      updateEstablishment.postResponse &&
      updateEstablishment.postResponse.status &&
      updateEstablishment.postResponse.status === 200
    ) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEstablishment.postResponse]);

  useEffect(() => {
    getEstablishmentPostals.getFetch(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  useEffect(() => {
    if (getEstablishmentPostals.fetchResponse) {
      setFilteredPostal(getEstablishmentPostals.fetchResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEstablishmentPostals.fetchResponse]);
  //
  useEffect(() => {
    if (searchPostals.length > 0) {
      getPostals.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPostals]);
  //
  useEffect(() => {
    getEstablishmentPostals.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemovePostal.postResponse, postAddPostal.postResponse]);
  return {
    getEstablishmentTables,
    getOpeningDays,
    getEstablishmentPostals,
    postAddSeat,
    postRemoveSeat,
    postUpdateSeat,
    updateEstablishment,
    getPostals,
    postRemovePostal,
    EnableTables,
    DisableTables
  };
};
