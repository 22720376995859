import React from 'react';
import { ThemeColors } from '../../config';
import { OrderProps } from '../../interfaces/Order';
import ChartCard from '../Charts/ChartCard';
import { Column, Row } from '../shared';
import { Paragraph, Title } from '../Styles/Text';

interface OrderCardProps {
  order: OrderProps;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
  const colors = {
    complete: ThemeColors.success,
    prepairing: ThemeColors.warning,
    waiting: ThemeColors.error
  };
  const activeColor = colors[order.status];

  return (
    <ChartCard width="100%" margin="0" borderColor={activeColor}>
      <Row AItems="flex-start" JContent="flex-start">
        <Title margin="0">#{order.code}</Title>
        <Column justify="flex-end" align="flex-end">
          <Paragraph margin="4px 0" align="end">
            {order.created_at.split(' ')[1]}
            <br /> {order.created_at.split(' ')[0]}
          </Paragraph>
          <Paragraph margin="4px 0">
            <b>Time:</b> {order.pick_up_time}
          </Paragraph>
        </Column>
      </Row>
      <Column justify="flex-start" align="flex-start">
        <Paragraph margin="4px 0">
          <b>Client:</b> {order.user_name}
        </Paragraph>
        {order.transport && (
          <Paragraph margin="4px 0">
            <b>Type:</b> {order.transport}
          </Paragraph>
        )}
        <Paragraph margin="4px 0">
          <b>Items:</b> {order.order.length}
        </Paragraph>
        {order.transport === 'delivery' && (
          <Paragraph margin="4px 0">
            <b>Address:</b> {`${order.address} ${order.house_number}`}
          </Paragraph>
        )}
      </Column>
    </ChartCard>
  );
};

export default OrderCard;
