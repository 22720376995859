import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled, { css } from 'styled-components';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import * as Interface from '../../interfaces';
import { Establishment } from '../../interfaces';
import {
  saveEstablishment,
  selectEstablishment,
  FetchOrders
} from '../../redux/actions';
import Card from '../cards/Card';

interface EstablishmentsComponentProps {
  establishments: Establishment[];
  setEstablishmentData: Function;
  EstablishmentsData: Establishment;
  saveEstablishment: Function;
  selectEstablishment: Function;
  FetchOrders: Function;
}

type EstablishmentsRouteProps = {
  establishment: string;
};

type WithRouterProps = RouteComponentProps<EstablishmentsRouteProps>;

type AllProps = WithRouterProps & EstablishmentsComponentProps;

const Establishments: React.FC<AllProps> = ({
  establishments,
  setEstablishmentData,
  EstablishmentsData,
  saveEstablishment,
  selectEstablishment,
  FetchOrders,
  match
}) => {
  useEffect(() => {
    const establishmentParam = match.params.establishment;
    if (establishmentParam) {
      const filteredData = establishments.filter(
        (item: Interface.Establishment) => {
          return (
            item.name.toLocaleLowerCase() ===
            establishmentParam.toLocaleLowerCase()
          );
        }
      );
      filteredData.length === 1 &&
        localStorage.setItem(
          'establishment',
          JSON.stringify(filteredData[0])
        ) &&
        setEstablishmentData(filteredData[0]);
      selectEstablishment({ selectedEstablishment: filteredData[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishments, match.params.establishment, setEstablishmentData]);

  useEffect(() => {
    if (!establishments[1] && establishments[0]) {
      localStorage.setItem('establishment', JSON.stringify(establishments[0]));
      setEstablishmentData(establishments[0]);
      selectEstablishment({ selectedEstablishment: establishments[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishments, setEstablishmentData]);

  //if (fetchLoading) return <p> Fetching {config.activeTheme}s</p>;
  if (establishments.length > 0)
    return (
      <>
        <Title>Select a {config.activeTheme}</Title>

        <Content className="content" direction="row" minHeight="250px">
          {establishments.map(
            (item: Interface.Establishment, index: number) => {
              return (
                <span
                  onClick={() => {
                    localStorage.setItem('establishment', JSON.stringify(item));
                    setEstablishmentData(item);
                    selectEstablishment({ selectedEstablishment: item });
                    FetchOrders(item.id);
                  }}
                >
                  <Card
                    bgImage={item.image}
                    type="Image"
                    hoverEffect="fadeIn"
                    active={
                      EstablishmentsData && EstablishmentsData.id === item.id
                    }
                  >
                    <Content className="card__content">
                      <Title key={index}>{item.name}</Title>
                      <Text>Opens: {item.opening_hours}</Text>
                      <Text>Closes: {item.closing_hours}</Text>
                    </Content>
                    <Overlay className="card__overlay" />
                  </Card>
                </span>
              );
            }
          )}
        </Content>
      </>
    );
  else return <p>No establishment</p>;
};
const mapStateToProps = (state: any) => {
  const { Establishment } = state;
  return {
    establishments: Establishment.response
  };
};
const mapDispatchToProps = {
  saveEstablishment,
  selectEstablishment,
  FetchOrders
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Establishments)
);

const Title = styled.h1``;

const Text = styled.p`
  color: white;
  font-family: 'PT-sans', sans-serif;
  margin: 0;
  font-weight: bold;
`;

const Content = styled.div<{ direction?: string; minHeight?: string }>`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${props => props.direction || 'column'};
  width: 100%;
  height: 100%;
  ${props =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `};
  &.card__content {
    cursor: pointer;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${ThemeColors.translucentBlackBackgroundColor};
  opacity: 0.8;
  transition: all 0.5s ease;
`;
