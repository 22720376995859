import React from 'react';
import { ThemeColors } from '../../config';
import { toCurrency } from '../../helpers';
import { OrderProps } from '../../interfaces/Order';
import ChartCard from '../Charts/ChartCard';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import { Column, MainButton, Row } from '../shared';
import { Paragraph, Title } from '../Styles/Text';

interface OrderDetailCardProps {
  order: OrderProps;
  update: Function;
  btnText?: string;
}

const OrderDetailCard: React.FC<OrderDetailCardProps> = ({
  order,
  update,
  btnText = 'Next'
}) => {
  const colors = {
    complete: ThemeColors.success,
    prepairing: ThemeColors.warning,
    waiting: ThemeColors.error
  };

  const Alert = () => {
    if (
      window.confirm(
        'Are you sure? If you reject this order, the money will be refunded automatically to the customer.'
      )
    ) {
      update(true, 'reject');
    }
  };
  //
  const clubOptions = () => {
    return (
      <>
        {order.comment && (
          <Column justify="flex-start" align="flex-start">
            <Paragraph margin="4px 0">
              <b>Comment:</b>
            </Paragraph>
            <Paragraph margin="4px 0">{order.comment}</Paragraph>
          </Column>
        )}
        <Row margin="8px 0" JContent="space-around">
          <MainButton type="light" action={() => Alert()}>
            Reject
          </MainButton>

          <MainButton type="mainSmall" action={() => update(true)}>
            Next
          </MainButton>
        </Row>
      </>
    );
  };

  const restaurantOptions = () => {
    return (
      <>
        {order.transport === 'delivery' && (
          <Column justify="flex-start" align="flex-start">
            <Paragraph margin="4px 0">
              <b>Adres:</b> {`${order.address} ${order.house_number}`}
            </Paragraph>
            <Paragraph margin="4px 0">
              <b>Postcode:</b> {order.postcode}
            </Paragraph>
          </Column>
        )}
        {order.comment && (
          <Column justify="flex-start" align="flex-start">
            <Paragraph margin="4px 0">
              <b>Comment:</b>
            </Paragraph>
            <Paragraph margin="4px 0">{order.comment}</Paragraph>
          </Column>
        )}

        {btnText.length > 0 && order.transport !== 'delivery' && (
          <Row margin="8px 0" JContent="space-around">
            {order.status === 'waiting' && order.payment_id !== 'none' && (
              <MainButton type="light" action={() => Alert()}>
                Reject
              </MainButton>
            )}

            <MainButton type="mainSmall" action={() => update(true)}>
              {order.status === 'waiting' ? 'accept' : btnText}
            </MainButton>
          </Row>
        )}
        {order.transport === 'delivery' && order.status === 'prepairing' && (
          <Row JContent="space-between">
            <MainButton
              type="mainSmallest"
              action={() => update(true, '15 min')}
            >
              15 min
            </MainButton>
            <MainButton
              type="mainSmallest"
              action={() => update(true, '30 min')}
            >
              30 min
            </MainButton>
            <MainButton
              type="mainSmallest"
              action={() => update(true, '45 min')}
            >
              45 min
            </MainButton>
            <MainButton
              type="mainSmallest"
              action={() => update(true, '60 min')}
            >
              60 min
            </MainButton>
          </Row>
        )}
        {order.transport === 'delivery' && order.status !== 'prepairing' && (
          <Row margin="8px 0" JContent="space-around">
            {order.status === 'waiting' && order.payment_id !== 'none' && (
              <MainButton type="light" action={() => Alert()}>
                Reject
              </MainButton>
            )}

            <MainButton type="mainSmall" action={() => update(true)}>
              {order.status === 'waiting' ? 'accept' : btnText}
            </MainButton>
          </Row>
        )}
      </>
    );
  };
  //
  const activeColor = colors[order.status];
  //
  return (
    <ChartCard
      title={
        order.status === 'prepairing' ? 'PREPARING' : order.status.toUpperCase()
      }
      width="100%"
      margin="8px 0"
      borderColor={activeColor}
    >
      <Row AItems="flex-start" JContent="flex-start">
        <Title margin="0">#{order.code}</Title>
        <Column justify="flex-end" align="flex-end">
          <Paragraph margin="4px 0" align="end">
            {order.created_at.split(' ')[1]}
            <br /> {order.created_at.split(' ')[0]}
          </Paragraph>
          <Paragraph margin="4px 0">
            <b>Time:</b> {order.pick_up_time}
          </Paragraph>
        </Column>
      </Row>
      <Column justify="flex-start" align="flex-start">
        <Paragraph margin="4px 0">
          <b>Client:</b> {order.user_name}
        </Paragraph>
        {order.phonenumber && (
          <Paragraph margin="4px 0">
            <b>Tel:</b> {order.phonenumber}
          </Paragraph>
        )}
        {order.transport && (
          <Paragraph margin="4px 0">
            <b>Type:</b> {order.transport}
          </Paragraph>
        )}
        <Paragraph margin="4px 0">
          <b>Payment method:</b> {order.payment_method}
        </Paragraph>
        <Paragraph margin="4px 0">
          <b>Items:</b> {order.order.length}
        </Paragraph>
      </Column>
      <Row>
        <List headerItems={['products', 'addons']}>
          {order.order.map((item, index: number) => {
            return (
              <ItemContainer key={index}>
                <ListItem align="left">{`${item.pivot.qty}x ${item.name}`}</ListItem>
                <ListItem align="right">{`${item.pivot.addons}`}</ListItem>
              </ItemContainer>
            );
          })}
        </List>
      </Row>
      <Column justify="flex-start" align="flex-start">
        <Paragraph margin="4px 0">
          <b>total: {toCurrency(Number(order.total))}</b>
        </Paragraph>
      </Column>
      {process.env.REACT_APP_THEME === 'club'
        ? clubOptions()
        : restaurantOptions()}
    </ChartCard>
  );
};

export default OrderDetailCard;
