import { Establishment as EstablishmentProps } from '../../interfaces';

export type errorResponse = {
  message: string;
};

export type EstablishmentReducerPayload = {
  loading: boolean;
  error: boolean;
  errorResponse: errorResponse;
  response: EstablishmentProps[] | [];
  selectedEstablishment: EstablishmentProps | {};
};

export type EstablishmentReducerProps = {
  payload: EstablishmentReducerPayload;
  action?: string;
  type?: string;
};

const initialState: EstablishmentReducerPayload = {
  loading: false,
  error: false,
  errorResponse: { message: '' },
  response: [],
  selectedEstablishment: {}
};

const Establishment = (
  state = initialState,
  action: EstablishmentReducerProps
) => {
  switch (action.type) {
    case 'SAVE_ESTABLISHMENT': {
      state = { ...state, ...action.payload };
      return state;
    }
    case 'SELECT_ESTABLISHMENT': {
      state.selectedEstablishment = action.payload.selectedEstablishment;
      return state;
    }
    default:
      return state;
  }
};

export default Establishment;
