import divisionIcon from '@iconify/icons-mdi/division';
import euroSymbol from '@iconify/icons-mdi/euro-symbol';
import silverwareForkKnife from '@iconify/icons-mdi/silverware-fork-knife';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth, toCurrency } from '../../helpers';
import { useGet } from '../../hooks';
import { Establishment } from '../../interfaces';
import { OrderProps } from '../../interfaces/Order';
import { getElementWidth } from '../../utils/Calculation';
import {
  chartNumbers,
  highestChartNumber,
  percentage
} from '../../utils/Chart';
import BarChart from '../Charts/Bar/BarChart';
import ChartCard from '../Charts/ChartCard';
import LineChartContainer from '../Charts/Line/LineChartContainer';
import New from '../News/New';
import { ChartIcon as ChartIconB, Column, Row } from '../shared';
import { Icon as IconB } from '../shared/Icon';
import { Heading } from '../Styles/Text';

interface DashboardProps {
  EstablishmentsData: Establishment;
  updating: boolean;
}

type ChartProps = {
  id: number;
  qty: number;
  name: string;
};

const Dashboard: React.FC<DashboardProps> = ({
  EstablishmentsData,
  updating
}) => {
  /**
   * Required data
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);
  /**
   * States
   */
  const [selectedChart, setSelectedChart] = useState(0);
  const [chartData, setStateChartData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  /**
   * REST requests
   */
  const getProducts = useGet(
    restHeader,
    `establishment/${EstablishmentsData.id}/products/all`
  );
  const getOrders = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${EstablishmentsData.id}/orders/picked_up/date/year/2021`
  );
  const getPost = useGet(
    apiHeaderAuth(getToken.acces_token),
    `post/show/latest-update`
  );
  /**
   * Effects
   */
  useEffect(() => {
    getPost.getFetch(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (EstablishmentsData.id) {
      getOrders.getFetch(true);
      getProducts.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EstablishmentsData]);
  //
  useEffect(() => {
    if (getOrders.fetchResponse.data.length > 0) {
      setStateChartData(chartNumbers(getOrders.fetchResponse.data));
    }
  }, [getOrders.fetchResponse]);
  useEffect(() => {
    if (!getOrders.fetchLoading && getOrders.fetchResponse.data.length > 0) {
      setStateChartData(chartNumbers(getOrders.fetchResponse.data));
      setTotalOrders(getOrders.fetchResponse.data.length);
      let totalProductsCount = 0;
      let totalProductPrice = 0;
      getOrders.fetchResponse.data.forEach((order: OrderProps) => {
        order.order.forEach(product => {
          totalProductsCount += product.pivot.qty;
          totalProductPrice += Number(product.pivot.total);
        });
      });
      setTotalProducts(totalProductsCount);
      setTotalProductPrice(totalProductPrice);
    } else {
      setTotalOrders(0);
      setTotalProducts(0);
      setTotalProductPrice(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrders.fetchResponse, getOrders.fetchLoading]);
  /**
   * Return
   */
  return (
    <Container>
      {getPost.fetchResponse.id && (
        <Row>
          <New updating={updating} post={getPost.fetchResponse} />
        </Row>
      )}

      <Row JContent="flex-start">
        <Heading>Dashboard</Heading>
      </Row>
      <Row>
        <ChartCard width="100%">
          <Row JContent="space-around">
            <ChartIcon color="#e9b07f36">
              <Icon
                icon={euroSymbol}
                color={ThemeColors.primaryColor}
                style={{ fontSize: '24px' }}
              />
            </ChartIcon>
            <Column align="flex-start">
              <TotalOrders>{toCurrency(totalProductPrice)}</TotalOrders>
              <TotalOrdersText>Total revenue</TotalOrdersText>
            </Column>
          </Row>
        </ChartCard>
        <ChartCard width="100%">
          <Row JContent="space-around">
            <ChartIcon color="rgba(67, 146, 241, 0.1)">
              <Icon
                icon={silverwareForkKnife}
                color="#4392F1"
                style={{ fontSize: '24px' }}
              />
            </ChartIcon>
            <Column align="flex-start">
              <TotalOrders>{totalProducts}</TotalOrders>
              <TotalOrdersText>Total products sold</TotalOrdersText>
            </Column>
          </Row>
        </ChartCard>
        <ChartCard width="100%">
          <Row JContent="space-around">
            <ChartIcon color="rgba(67, 146, 241, 0.1)">
              <Icon
                icon={divisionIcon}
                color="#4392F1"
                style={{ fontSize: '24px' }}
              />
            </ChartIcon>
            <Column align="flex-start">
              <TotalOrders>
                {totalProductPrice > 0 && totalOrders > 0
                  ? toCurrency(Math.round(totalProductPrice / totalOrders))
                  : toCurrency(0)}
              </TotalOrders>
              <TotalOrdersText>Average order revenue</TotalOrdersText>
            </Column>
          </Row>
        </ChartCard>
      </Row>
      <Row>
        <ChartCard width="100%" title="Total orders" id="chart_id_container">
          <LineChartContainer
            fetchResponse={getOrders.fetchResponse}
            fetchLoading={getOrders.fetchLoading}
            id={EstablishmentsData.id}
            type="orders"
            svgWidth={getElementWidth('chart_id_container')}
          />
        </ChartCard>
      </Row>
      <Row>
        <ChartCard title="Popular Products" width="100%">
          {getOrders.fetchResponse.data.length > 0 ? (
            chartData
              .sort((a: ChartProps, b: ChartProps) => b.qty - a.qty)
              .slice(0, 5)
              .map((item: ChartProps, index: number) => {
                const highestNumber = highestChartNumber(chartData);
                return (
                  <BarChart
                    subject={item.name}
                    width={`${percentage(item.qty, highestNumber)}%`}
                    count={item.qty}
                    focus={selectedChart === index}
                    action={() => setSelectedChart(index)}
                  />
                );
              })
          ) : (
            <Column>
              <ChartIconB color="rgba(67,146,241,0.1)">
                <IconB getIcon="Hamburger" color="rgb(67, 146, 241)" />
              </ChartIconB>
              <h1> No products found</h1>
            </Column>
          )}
        </ChartCard>
      </Row>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-decoration: none;
  }
`;

const ChartIcon = styled.div<{ color?: string }>`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: ${props => props.color || '#fce5f0'};
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalOrders = styled.h4`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #011627;
  margin: 0;
`;

const TotalOrdersText = styled.p`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #979797;
  margin: 0;
`;
