import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdCancel, MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import Input from '../../components/Form/Input';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import * as Interface from '../../interfaces';
import { ApiUrl } from '../../utils';
import ChartCard from '../Charts/ChartCard';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import Modal from '../modal/Modal';
import { ButtonMenu, MainButton, Row } from '../shared';

type MenuItemObject = {
  id: number;
  name: string;
  type: string;
};

interface MenuItemsProps {
  EstablishmentsData: Interface.Establishment;
}

const MenuItems: React.FC<MenuItemsProps> = ({ EstablishmentsData }) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);

  const [menuItems, setMenuItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('New product');
  const [requestType, setRequestType] = useState('add');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [itemId, setItemId] = useState<number>();

  const getMenuItems = async () => {
    try {
      const response = await axios.get(
        `${ApiUrl}establishment/${EstablishmentsData.id}/menu_items`
      );
      setMenuItems(response.data);
    } catch (error) {}
  };

  const changeMenuItems = async () => {
    try {
      const response = await axios.post(
        `${ApiUrl}establishment/${EstablishmentsData.id}/menu_items/${requestType}`,
        {
          id: itemId,
          name: name,
          type: type,
          icon: 'none'
        },
        { headers: restHeader }
      );
      getMenuItems();
    } catch (error) {}
  };

  useEffect(() => {
    getMenuItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (requestType === 'remove') {
      Alert('Are you sure you want to remove this addon?');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestType]);

  const Alert = (msg: string) => {
    const r = window.confirm('Are you sure you want to' + msg);
    if (r) {
      changeMenuItems();
    } else {
      setRequestType('');
    }
  };

  return (
    <Container>
      <Row JContent="flex-end" margin="16px 48px">
        <ButtonMenu>
          {/* <Input
            label="Search"
            placeholder="enter product name"
            setInputValue={changeSearchValue}
          /> */}
          <MainButton
            type="mainSmall"
            text="Add new menu item"
            action={() => {
              setShowModal(!showModal);
              setModalTitle('Create Menu item');
              setRequestType('add');
              setItemId(0);
              setName('');
              setType('');
            }}
          />
        </ButtonMenu>
      </Row>
      <ChartCard title="Menu Items" width="100%">
        <List headerItems={['name', 'type', 'actions']}>
          {menuItems.length > 0 ? (
            menuItems.map((menuItem: MenuItemObject, index: number) => {
              return (
                <ItemContainer>
                  <ListItem>{menuItem.name}</ListItem>
                  <ListItem>{menuItem.type}</ListItem>
                  <ActionsContainer>
                    <Action
                      onClick={() => {
                        setShowModal(!showModal);
                        setModalTitle('Edit ' + menuItem.name);
                        setRequestType('edit');
                        setItemId(menuItem.id);
                        setName(menuItem.name);
                        setType(menuItem.type ? menuItem.type.toString() : '');
                      }}
                    >
                      <MdEdit size={30} />
                    </Action>
                    <Action
                      onClick={() => {
                        setItemId(menuItem.id);
                        setRequestType('remove');
                      }}
                    >
                      <MdCancel size={30} />
                    </Action>
                  </ActionsContainer>
                </ItemContainer>
              );
            })
          ) : (
            <ItemContainer>
              <p>No Menu items found</p>
            </ItemContainer>
          )}
          {showModal && (
            // true
            <Modal
              closeModal={() => {
                setShowModal(false);
              }}
            >
              <h2>{modalTitle}</h2>
              <Input
                type="name"
                label="name"
                placeholder="Write addon name..."
                inputValue={name}
                setInputValue={setName}
              />
              <Input
                label="type"
                placeholder="Add a type"
                inputValue={type}
                setInputValue={setType}
              />
              <MainButton
                type="mainSmall"
                action={() => {
                  changeMenuItems();
                  setShowModal(false);
                }}
              >
                {requestType === 'add' ? 'create menu item' : 'Edit menu item'}
              </MainButton>
            </Modal>
          )}
        </List>
      </ChartCard>
    </Container>
  );
};

export default MenuItems;

// const ItemContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   border-radius: 27px;
//   margin: 16px 0;
// `;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justtify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Select = styled.select`
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;

const Addons = styled.p`
  list-style: none;
  margin: 8px 0;
  background-color: #e9b07f;
  padding: 8px;
  border-radius: 24px;
  color: white;
  text-align: center;
`;

const AddonsList = styled.div`
  margin: 8px 0;
`;

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 12px;
  width: 200px;
`;
