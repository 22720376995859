import React from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import copy from '../../data/copy.json';
// import { Input } from '../shared/Layout';
import Input from '../Form/Input';
import { MainButton } from '../shared/Buttons';

interface LoginFormProps {
  email: string;
  setEmail: Function;
  password: string;
  setPassword: Function;
  setSend: Function;
  canLogin: boolean;
  message: string;
  loadingLogin: boolean;
  type: string;
  setFormType: Function;
}

const LoginForm: React.FC<LoginFormProps> = ({
  setEmail,
  setPassword,
  setSend,
  canLogin,
  message,
  loadingLogin,
  type = 'login',
  setFormType
  //setLoadingLogin
}) => {
  const texts = copy.loginForm;
  const inputFields = () => {
    switch (type) {
      case 'login':
        return (
          <>
            <Input
              containerWidth="100%"
              label={texts.loginText}
              setInputValue={(e: string) => setEmail(e)}
              placeholder={texts.loginPlaceholder}
              type="email"
              // eslint-disable-next-line react/style-prop-object
              style="full"
              labelColor="white"
            />
            <Input
              containerWidth="100%"
              labelColor="white"
              label={texts.passwordText}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              type="password"
              setInputValue={(e: string) => setPassword(e)}
              placeholder={texts.passwordPlaceholder}
            />
          </>
        );
      case 'forget':
        return (
          <>
            <Input
              containerWidth="100%"
              labelColor="white"
              label="Email"
              setInputValue={(e: string) => setEmail(e)}
              placeholder={texts.loginPlaceholder}
              type="email"
              // eslint-disable-next-line react/style-prop-object
              style="full"
            />
          </>
        );
      default:
        return <p>No input fields</p>;
    }
  };
  return (
    <Container>
      <Form>
        {message && (
          <AlertMessage color={type === 'login' ? 'red' : 'white'}>
            {message}
          </AlertMessage>
        )}
        <InputContainer>{inputFields()}</InputContainer>
        <span style={{ margin: '16px 0' }}>
          <MainButton
            text={!loadingLogin ? 'submit' : 'Loading please wait'}
            disabled={canLogin}
            action={() => {
              setSend(true);
            }}
          />
          <MainButton
            text={type === 'login' ? 'Forgot password?' : 'Login'}
            type="link"
            action={() =>
              setFormType(
                type === 'login'
                  ? { type: 'forget', url: 'password/create' }
                  : { type: 'login', url: 'login' }
              )
            }
          />
        </span>
      </Form>
    </Container>
  );
};

export default LoginForm;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;

const Form = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  flex-direction: column;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px; */
  background-color: ${ThemeColors.menuBackgroundColor};
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AlertMessage = styled.p<{ color: string }>`
  /* color: red; */
  color: ${props => props.color || 'red'};
  font-size: 16px;
`;
