import React from 'react';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';

interface ProfileIcon {
  image?: string;
  action?: Function;
}

const ProfileIcon: React.FC<ProfileIcon> = ({
  children,
  image,
  action = () => {}
}) => {
  return (
    <Circle onClick={() => action()} image={image}>
      {children}
    </Circle>
  );
};

export default ProfileIcon;

const Circle = styled.div<{ image?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-color: ${ThemeColors.primaryColor};
  margin: 0 4px;
  color: white;
  font-weight: bold;
  ${props =>
    props.image &&
    css`
      background-image: ${props.image};
    `};
`;
