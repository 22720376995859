import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  extraClass?: string;
  gapCols?: string;
  gridCols?: string;
  smGridCols?: string;
  mdGridCols?: string;
  lgGridCols?: string;
  xlGridCols?: string;
  xxlGridCols?: string;
  gapRows?: string;
  gridRows?: string;
  smGridRows?: string;
  mdGridRows?: string;
  lgGridRows?: string;
  xlGridRows?: string;
  xxlGridRows?: string;
  //
  direction?: string;
}

const Grid: FC<Props> = ({
  children,
  extraClass,
  //
  gapCols = 'gap-x-9',
  gridCols = 'grid-cols-12',
  smGridCols = 'sm:grid-none',
  mdGridCols = 'md:grid-none',
  lgGridCols = 'lg:grid-none',
  xlGridCols = 'xl:grid-none',
  xxlGridCols = '2xl:grid-',
  //
  gapRows = 'gap-y-none',
  gridRows = 'grid-none',
  smGridRows = 'sm:grid-none',
  mdGridRows = 'md:grid-none',
  lgGridRows = 'lg:grid-none',
  xlGridRows = 'xl:grid-none',
  xxlGridRows = '2xl:grid-',
  //
  direction = 'flex-col'
}) => {
  return (
    <GridLayout
      className={`
            grid 
            ${gapCols}
            ${gridCols} 
            ${smGridCols} 
            ${mdGridCols} 
            ${lgGridCols} 
            ${xlGridCols} 
            ${xxlGridCols}

            ${gapRows}
            ${gridRows} 
            ${smGridRows} 
            ${mdGridRows} 
            ${lgGridRows} 
            ${xlGridRows} 
            ${xxlGridRows}

            max-w-screen-2xl 
            mx-auto flex 
            justify-center 
            ${direction} 
            lg:flex-row 
            
            ${extraClass}
        `}
    >
      {children}
    </GridLayout>
  );
};

export default Grid;
const GridLayout = styled.div``;
