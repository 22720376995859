import React, { useEffect, useState } from 'react';
import { MdCancel, MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { toCurrency, SearchFilter, apiHeaderAuth } from '../../helpers';
import { useGet, useGetData, usePut, usePutData } from '../../hooks';
import { AddonProps, AddonsComponentProps } from '../../interfaces/Addons';
import copy from '../../data/copy.json';
import { AlertProp } from '../../interfaces/Alert';
import { AlertWrapper } from '../../components/Alert/Alert.style';
import Alert from '../../components/Alert/Alert';

import {
  ButtonMenu,
  Column,
  Icon,
  MainButton,
  Row,
  Warning
} from '../../components/shared';
import Input from '../../components/Form/Input';
import ChartCard from '../../components/Charts/ChartCard';
import List, { ItemContainer } from '../../components/List/List';
import ListItem from '../../components/List/ListItem';
import Modal from '../../components/modal/Modal';
import { usePost } from '../../hooks/usePost';
import { Heading, Label } from '../../components/Styles/Text';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions';
const Addons: React.FC<AddonsComponentProps> = ({
  EstablishmentsData,
  setAlert
}) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const restHeader = apiHeaderAuth(getToken.access_token);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [addonPackage, setAddonPackage] = useState({
    id: 0,
    name: '',
    description: '',
    price: 0,
    tax: 0.0,
    required: 0,
    multiple: 0,
    type: ''
  });
  const [modalData, setModalData] = useState({
    show: false,
    title: '',
    url: `establishment/${EstablishmentsData.id}/addon/create`,
    type: 'post'
  });
  /**
   * API requests
   */
  const getAddons = useGet(
    restHeader,
    `establishment/${EstablishmentsData.id}/addons/all`,
    true
  );
  //
  const createAddon = usePost(modalData.url, restHeader, addonPackage);
  //
  const updateAddon = usePut(
    `establishment/${EstablishmentsData.id}/addon/edit/${addonPackage.id}`,
    restHeader,
    addonPackage
  );
  /**
   *
   */
  useEffect(() => {
    if (updateAddon.putResponse) {
      if (
        updateAddon.putResponse.status === 200 &&
        updateAddon.putResponse.data &&
        updateAddon.putResponse.data.length > 0
      ) {
        const newAlert: AlertProp = {
          title: 'Product updated',
          message: `Successfully updated ${addonPackage.name}`,
          type: 'success'
        };
        setAlert(newAlert);
        setData(updateAddon.putResponse.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAddon.putResponse]);
  //
  useEffect(() => {
    if (updateAddon.putError) {
      const newAlert: AlertProp = {
        title: `Unsuccessfully updated ${addonPackage.name}`,
        message: updateAddon.errorResponse.response.data.message,
        type: 'error'
      };
      setAlert(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAddon.putError, updateAddon.errorResponse]);
  //
  useEffect(() => {
    if (createAddon.postResponse) {
      if (
        createAddon.postResponse.status === 200 &&
        createAddon.postResponse.data &&
        createAddon.postResponse.data.length > 0
      ) {
        const newAlert: AlertProp = {
          title: 'Product updated',
          message: `Successfully updated ${addonPackage.name}`,
          type: 'success'
        };
        setAlert(newAlert);
        setData(createAddon.postResponse.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddon.postResponse]);
  //
  useEffect(() => {
    if (createAddon.postError) {
      const newAlert: AlertProp = {
        title: `Unsuccessfully updated ${addonPackage.name}`,
        message: createAddon.errorResponse.response.data.message,
        type: 'error'
      };
      setAlert(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddon.postError, createAddon.errorResponse]);
  //
  useEffect(() => {
    if (getAddons.fetchError) {
      const newAlert: AlertProp = {
        title: `Unsuccessfully finding addons`,
        message: createAddon.errorResponse.response.data.message,
        type: 'error'
      };
      setAlert(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddons.fetchError]);
  /**
   *
   */
  useEffect(() => {
    setData(getAddons.fetchResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddons.fetchResponse]);
  /**
   * Populate filter data
   */
  useEffect(() => {
    setFilterData(data);
  }, [data]);
  /**
   *
   * @param event
   */
  useEffect(() => {
    //
    getAddons.getFetch(true);
    //
  }, [createAddon.postResponse]);

  /**
   * Filters data with the SearchFilter func and updates setFilterData
   * @param {string} event
   */
  const changeSearchValue = (event: string) => {
    setSearch(event);
    const newData = data.filter(SearchFilter(event));
    setFilterData(newData);
  };

  /**
   * pops an Alert  with an confirm button
   * if confirmed change order status
   *
   * @param {Object} item
   * @param {String} value
   * @param {String} msg
   */
  const AlertPopUp = (msg: string) => {
    if (window.confirm('Are you sure you want to' + msg)) {
      //setSend(true);
    }
  };
  /**
   *
   */
  const setName = (name: string) => {
    setAddonPackage({ ...addonPackage, name: name });
  };
  //
  const setPrice = (price: string) => {
    setAddonPackage({ ...addonPackage, price: Number(price) });
  };
  //
  const setTax = (tax: string) => {
    setAddonPackage({ ...addonPackage, tax: Number(tax) });
  };
  //
  const setDescription = (description: string) => {
    setAddonPackage({ ...addonPackage, description: description });
  };
  //
  const setMultiple = (multiple: number) => {
    setAddonPackage({ ...addonPackage, multiple: multiple });
  };
  //
  const setRequired = (required: number) => {
    setAddonPackage({ ...addonPackage, required: required });
  };
  //
  const setType = (type: string) => {
    setAddonPackage({ ...addonPackage, type: type });
  };
  //
  const submitModal = () => {
    if (modalData.type === 'post') {
      createAddon.sendPost(true);
    } else if (modalData.type === 'edit') {
      updateAddon.sendPut(true);
    }
    setModalData({ ...modalData, show: false });
  };
  //
  return (
    <Container>
      <Row JContent="space-between" margin="16px 48px">
        <Heading>Addons</Heading>
      </Row>
      <Row JContent="flex-end" margin="16px 48px">
        <ButtonMenu margin="0 8px 0 0">
          <Input
            label="Search"
            placeholder="enter addon name"
            setInputValue={changeSearchValue}
          />
        </ButtonMenu>
        <ButtonMenu>
          <MainButton
            type="mainSmall"
            text={'New Addon'}
            action={() => {
              setModalData({
                show: true,
                title: `Create addon`,
                url: `establishment/${EstablishmentsData.id}/addon/create`,
                type: 'post'
              });
              setAddonPackage({
                id: 0,
                name: '',
                description: '',
                price: 0,
                tax: 0.0,
                required: 0,
                multiple: 0,
                type: ''
              });
            }}
          />
        </ButtonMenu>
      </Row>
      <ChartCard width="100%">
        <List
          headerItems={[
            'name',
            'description',
            'price',
            'type',
            'required',
            'multiple',
            'actions'
          ]}
        >
          {filterData.length > 0 ? (
            filterData.map((addon: AddonProps, index: number) => {
              return (
                <ItemContainer>
                  <ListItem>{addon.name}</ListItem>
                  <ListItem>{addon.description}</ListItem>
                  <ListItem>{addon.price && toCurrency(addon.price)}</ListItem>
                  <ListItem>{addon.type}</ListItem>
                  <ListItem>{addon.required === 1 ? 'yes' : 'no'}</ListItem>
                  <ListItem>{addon.multiple === 1 ? 'yes' : 'no'}</ListItem>

                  <ActionsContainer>
                    <Action
                      onClick={() => {
                        setModalData({
                          show: true,
                          title: `Edit ${addon.name}`,
                          url: `establishment/${EstablishmentsData.id}/addon/edit/${addon.id}`,
                          type: 'edit'
                        });
                        //
                        setAddonPackage({ ...addon });
                      }}
                    >
                      <MdEdit size={30} />
                    </Action>
                    {/* Remove addon */}
                    <Action
                      onClick={() => {
                        setModalData({
                          show: false,
                          title: `Edit ${addon.name}`,
                          url: `establishment/${EstablishmentsData.id}/addon/remove`,
                          type: 'edit'
                        });
                        setAddonPackage({ ...addon });
                        createAddon.sendPost(true);
                      }}
                    >
                      <MdCancel size={30} />
                    </Action>
                  </ActionsContainer>
                </ItemContainer>
              );
            })
          ) : (
            <ItemContainer>
              <h1>No Addons found</h1>
            </ItemContainer>
          )}
          {modalData.show && (
            // true
            <Modal
              noButton={true}
              closeModal={() => {
                setModalData({ ...modalData, show: false });
              }}
              width="30%"
            >
              <Row JContent="space-between" width="100%">
                <h2>{modalData.title}</h2>
                <IconWrapper
                  onClick={() => setModalData({ ...modalData, show: false })}
                >
                  <Icon
                    color={ThemeColors.primaryColor}
                    icon="Times"
                    size={32}
                  />
                </IconWrapper>
              </Row>
              <Column
                justify="flex-start"
                align="flex-start"
                width="100%"
                margin="0 0 8px 0"
              >
                <Row JContent="flex-start" AItems="flex-start">
                  <Column>
                    <Input
                      type="name"
                      label="name"
                      placeholder="Write addon name..."
                      inputValue={addonPackage.name}
                      setInputValue={setName}
                    />
                    <Input
                      label="price"
                      placeholder="Add a price"
                      inputValue={addonPackage.price}
                      setInputValue={setPrice}
                      type="number"
                    />
                    <Input
                      label="tax"
                      placeholder="Add a tax"
                      inputValue={addonPackage.tax}
                      setInputValue={setTax}
                      type="number"
                    />
                    <Input
                      label="type"
                      placeholder="Add a type"
                      inputValue={addonPackage.type}
                      setInputValue={setType}
                    />
                  </Column>
                  <Column justify="flex-start" align="flex-start">
                    <Column>
                      <Label>Multiple</Label>
                      <Row JContent="flex-start">
                        <MainButton
                          type="toggle"
                          action={() => setMultiple(0)}
                          active={addonPackage.multiple === 0}
                        >
                          no
                        </MainButton>
                        <MainButton
                          type="toggle"
                          action={() => setMultiple(1)}
                          active={addonPackage.multiple === 1}
                        >
                          yes
                        </MainButton>
                      </Row>
                    </Column>
                    <Column>
                      <Label>Required</Label>
                      <Row JContent="flex-start">
                        <MainButton
                          type="toggle"
                          action={() => setRequired(0)}
                          active={addonPackage.required === 0}
                        >
                          no
                        </MainButton>
                        <MainButton
                          type="toggle"
                          action={() => setRequired(1)}
                          active={addonPackage.required === 1}
                        >
                          yes
                        </MainButton>
                      </Row>
                    </Column>
                  </Column>
                </Row>
                <Input
                  label="description"
                  placeholder="Write a descrition..."
                  type="textarea"
                  inputValue={addonPackage.description}
                  setInputValue={setDescription}
                  style="fullTextArea"
                  containerWidth="100%"
                />
              </Column>

              <MainButton type="mainSmall" action={() => submitModal()}>
                {modalData.type === 'post' ? 'create addon' : 'Edit addon'}
              </MainButton>
            </Modal>
          )}
        </List>
      </ChartCard>
    </Container>
  );
};

const mapDispatchToProps = { setAlert };

export default connect(null, mapDispatchToProps)(Addons);

// const ItemContainer = styled.div`
//   position: relative;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 100%;
//   border-radius: 27px;
//   background-color: #f4f4f4;
//   margin: 16px 0;
// `;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);

  svg {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.span`
  cursor: pointer;
`;
