import React from 'react';
import { Cross } from './Icons';
import { WarningAlert, WarningSucces } from './Warning.styles';

const types = {
  alert: WarningAlert,
  succes: WarningSucces
};

interface WarningProps {
  type: keyof typeof types;
  close?: Function;
}

export const Warning: React.FC<WarningProps> = ({
  children,
  type = 'alert',
  close = () => {}
}) => {
  const SelectedWarning = types[type];
  return (
    <SelectedWarning>
      {children}
      {close && <Cross action={close} />}
    </SelectedWarning>
  );
};
