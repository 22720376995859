import 'intl';
import 'intl/locale-data/jsonp/en-ZA';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
//import 'tailwindcss/tailwind.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/Store';
import Auth from './containers/auth/Auth';
//Global declaration for Echo
declare global {
  interface Window {
    Echo: any;
  }
}

ReactDOM.render(
  <Provider store={store}>
    <Auth />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
//serviceWorker.unregister();
serviceWorker.register();
