import { useEffect, useState } from 'react';
import '../styles/sticky.css';

/**
 *
 * @returns
 */
export const useSticky = (elem: any, max: number) => {
  const [sticky, setSticky] = useState(false);
  //
  useEffect(() => {
    if (elem && elem.current) {
      window.addEventListener('scroll', () => {
        //console.log('elem', elem.current.offsetTop);
        if (elem.current) {
          if (!elem.current.classList.contains('sticky')) {
            elem.current.classList.add('sticky');
          }
          let offset = window.pageYOffset;
          if (offset > max) {
            setSticky(true);
            if (!elem.current.classList.contains('sticky-on')) {
              elem.current.classList.add('sticky-on');
            }
          } else {
            setSticky(false);
            elem.current.classList.remove('sticky-on');
          }
        }
      });
    }
  }, [elem, max]);
  return { sticky, setSticky };
};
