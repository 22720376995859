import React, { FC } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions';
import { useHistory } from 'react-router';

import {
  Container,
  MainButton,
  Row,
  Heading,
  Seat,
  Grid,
  Input,
  ChartCard
} from '../../components';

import { ThemeColors } from '../../config';
import { Establishment } from '../../interfaces';
import { useSeatData } from './Seats.Data';
import { useSeat } from './Seats.Hooks';

import { FaArrowRight, FaSpinner } from 'react-icons/fa';

interface Props {
  updating: boolean;
  EstablishmentsData: Establishment;
  setAlert: Function;
}

const Seats: FC<Props> = ({ EstablishmentsData, updating, setAlert }) => {
  const { getSeats } = useSeatData(EstablishmentsData, updating, setAlert);
  const {
    //
    filterData,
    filterItem,
    setFilterItem,
    search,
    setSearch
  } = useSeat(EstablishmentsData, getSeats.fetchResponse);
  //
  let history = useHistory();
  //
  return (
    <>
      <Container AItems="flex-start">
        <Row>
          <Heading>Tables</Heading>
          <Row JContent="flex-end">
            <MainButton
              extraClass="mr-3"
              type="light"
              action={() => history.push('/settings/tables')}
            >
              Edit tables <FaArrowRight style={{ marginLeft: '16px' }} />
            </MainButton>
            <MainButton
              type="light"
              action={() => history.push('/settings/tables')}
            >
              View orders <FaArrowRight style={{ marginLeft: '16px' }} />
            </MainButton>
          </Row>
        </Row>

        <Row JContent="flex-start">
          <MainButton
            type="tab"
            active={filterItem === 'all'}
            action={() => setFilterItem('all')}
          >
            All
          </MainButton>
          <MainButton
            type="tab"
            active={filterItem === 'cash'}
            action={() => setFilterItem('cash')}
          >
            Cash
          </MainButton>
          <MainButton
            type="tab"
            active={filterItem === 'Ideal'}
            action={() => setFilterItem('Ideal')}
          >
            Ideal
          </MainButton>
        </Row>
        <Row JContent="space-between" margin="16px">
          <ChartCard margin="0">
            <Input
              label="Tables"
              labelColor={ThemeColors.primaryColor}
              placeholder="Search for a table..."
              inputValue={search}
              setInputValue={(e: string) => setSearch(e)}
            />
          </ChartCard>
          <MainButton
            type="mainSmall"
            action={() => getSeats.getFetch(true)}
            disabled={getSeats.fetchLoading}
          >
            {getSeats.fetchLoading ? (
              <>
                <FaSpinner style={{ marginRight: '8px' }} /> Refreshing
              </>
            ) : (
              'Refresh'
            )}
          </MainButton>
        </Row>
        <Grid
          gapCols="gap-x-none"
          direction="flex-col"
          gridCols="grid-cols-1"
          mdGridCols="md:grid-cols-2"
          lgGridCols="lg:grid-cols-3"
          xxlGridCols="2xl:grid-cols-4"
          extraClass="w-full gap-4"
        >
          {filterData &&
            filterData.length > 0 &&
            filterData?.map((seat, index) => (
              <Seat
                seat={seat}
                index={index}
                getSeats={getSeats}
                EstablishmentsData={EstablishmentsData}
                setAlert={setAlert}
              />
            ))}
        </Grid>
      </Container>
    </>
  );
};

const mapDispatchToProps = { setAlert };

export default connect(null, mapDispatchToProps)(Seats);
