import { config } from './config';
import { Colors } from '../theming/colors';

const themeMap = {
  club: 'club',
  restaurant: 'restaurant'
};

export const ThemeColors =
  Colors[config.activeTheme as keyof typeof themeMap].colors.dark;
export const ThemeIcons =
  Colors[config.activeTheme as keyof typeof themeMap].icons;
