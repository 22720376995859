import { useEffect, useState } from 'react';
import { config } from '../../config';
//
import { useGet } from '../../hooks/useGet';
import { usePost } from '../../hooks/usePost';
//
import { Establishment } from '../../interfaces';
import { AlertProp } from '../../interfaces/Alert';
import { ProductDataProps } from './Products.Interface';

//
const formData = new FormData();
/**
 *
 * @returns
 */

const useProductData = (
  EstablishmentsData: Establishment,
  setAlert: Function,
  data: any,
  setData: Function,
  setAddonsData: Function,
  setSelectedAddons: Function,
  setRemoveAddons: Function,
  setModalData: Function,
  match: any
) => {
  //
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '{}');
  /**
   *
   * @param token
   * @param contentType
   * @returns
   */
  const apiHeaderAuth = (token: string, contentType?: string) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}` //token
    };
    return header;
  };
  //
  const restHeader = apiHeaderAuth(getToken.access_token, 'application/json');
  /**
   * States
   */
  const [apiPackage, setApiPackage] = useState<any>(formData);
  //
  const [editUrl, setEditUrl] = useState(
    `establishment/${EstablishmentsData.id}/product/create`
  );
  //
  const [productData, setProductData] = useState<ProductDataProps | any>({
    id: 0,
    name: '',
    description: '',
    price: '',
    image: '',
    type: '',
    brand: '',
    qty: 0,
    addons: [],
    removeAddons: [],
    sold_out: 0,
    tax: 0.9
  });
  //
  const [firstLoad, setFirstLoad] = useState(true);
  /**
   * API CALLS
   *
   */
  const getAddons = useGet(
    restHeader,
    `establishment/${EstablishmentsData.id}/addons/all`,
    true
  );
  //
  const getProducts = useGet(
    restHeader,
    `establishment/${EstablishmentsData.id}/products/all`,
    true
  );
  //
  const {
    postError,
    errorResponse,
    postLoading,
    postResponse,
    sendPost
  } = usePost(editUrl, restHeader, productData);
  //
  const putProduct = usePost(
    `establishment/${EstablishmentsData.id}/product/edit/${productData.id}`,
    apiHeaderAuth(getToken.access_token, 'multipart/form-data'),
    apiPackage
    //{ ...productData, addons: selectedAddons, removeAddons: removeAddons }
  );
  //
  const postProduct = usePost(
    `establishment/${EstablishmentsData.id}/product/create`,
    apiHeaderAuth(getToken.access_token, 'multipart/form-data'),
    apiPackage
  );
  //
  /**
   * Use Effects
   */
  useEffect(() => {
    setAddonsData(getAddons.fetchResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddons.fetchResponse]);
  //
  useEffect(() => {
    setData(getProducts.fetchResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts.fetchResponse]);
  //
  useEffect(() => {
    if (putProduct.postResponse) {
      if (
        putProduct.postResponse.status === 200 &&
        putProduct.postResponse.data &&
        putProduct.postResponse.data.length > 0
      ) {
        const newAlert: AlertProp = {
          title: 'Product updated',
          message: `Successfully updated ${productData.name}`,
          type: 'success'
        };
        setAlert(newAlert);
        setData(putProduct.postResponse.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [putProduct.postResponse]);
  //
  useEffect(() => {
    if (
      postProduct.postResponse &&
      postProduct.postResponse.data &&
      postProduct.postResponse.data.length > 0
    ) {
      const newAlert: AlertProp = {
        title: 'New product added',
        message: `Successfully added ${productData.name}`,
        type: 'success'
      };
      setAlert(newAlert);
      setData(postProduct.postResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postProduct.postResponse]);
  //
  useEffect(() => {
    if (postProduct.postError) {
      const newAlert: AlertProp = {
        title: `Unsuccessfully updated ${productData.name}`,
        message: postProduct.errorResponse.response.data.message,
        type: 'error'
      };
      setAlert(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postProduct.postError, postProduct.errorResponse]);
  /**
   * If product param has
   */
  useEffect(() => {
    const id: number = Number(match.params.product);
    if (firstLoad) {
      if (match.params.product && id > 0) {
        if (data.length > 0) {
          const findProduct: any = data.find(
            (item: ProductDataProps) => item.id === id
          );
          if (findProduct) {
            const soldOut: any = findProduct.sold_out;
            setProductData({
              ...findProduct,
              sold_out: soldOut === 1 || soldOut ? 1 : 0,
              price: findProduct.price ? findProduct.price.toString() : '',
              removeAddons: []
            });
            setSelectedAddons(findProduct.addons);
            setRemoveAddons([]);
            setModalData({
              show: true,
              title: `Edit ${findProduct.name}`,
              url: `establishment/${EstablishmentsData.id}/product/edit/${findProduct.id}`
            });
            setFirstLoad(false);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.product, data]);
  /**
   * Return
   */
  return {
    // states
    apiPackage,
    setApiPackage,
    editUrl,
    setEditUrl,
    productData,
    setProductData,
    // api calls
    getAddons,
    getProducts,
    postError,
    errorResponse,
    postLoading,
    postResponse,
    sendPost,
    putProduct,
    postProduct
  };
};

export default useProductData;
