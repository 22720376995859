import { useEffect } from 'react';
import { ApiUrl } from '../utils/CreatUrl';
import axios from 'axios';
import PropTypes from 'prop-types';

/**
 * custom post hook that returns the API response and the API post function
 * @param {string} url
 * @param {object} Package
 * @param {boolean} send
 * @returns {array} and @param {function}
 */

export const usePost = (
  header,
  url,
  Package,
  send,
  setData,
  setSend,
  setResend,
  setMessage,
  setError,
  setErrorMessage
) => {
  // console.log(url);
  // console.log(JSON.stringify(Package));

  useEffect(() => {
    send && postData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]); //this will run when url or Package changes

  async function postData() {
    const API = ApiUrl + url;
    //this will have the updated input Package

    await axios
      .post(API, Package, { headers: header })
      .then(function (response) {
        setData && setData(response);
        setSend && setSend(false);
        //setResend && setResend(false);
      })
      .catch(function (error) {
        //setResend && setResend(true);
        console.log('API', API);
        console.log('Package', Package);
        console.log('header', header);

        setSend && setSend(false);
        console.log('error post', error);
        setData && setData(error.response);
        if (error.response && typeof error.response.data === 'string') {
          console.log('error', error.response.data);
          setMessage && setMessage(error.response.data);
        } else if (
          error.response &&
          typeof error.response.data === 'object' &&
          error.response.data.errors &&
          error.response.data.errors.email
        ) {
          setMessage && setMessage(error.response.data.errors.email[0]);
        } else if (error.response.data.message) {
          setMessage && setMessage(error.response.data.message);
        } else if (
          error.response &&
          typeof error.response.data === 'object' &&
          error.response.data.errors.password
        ) {
          setMessage && setMessage(error.response.data.password[0]);
        }
        if (setError || setErrorMessage) {
          const errorState =
            error.response.status === 404
              ? `Oops! Request not found, status ${error.response.status} returned. Please try again or contact us at info@rapio.nl`
              : error.response.data.message.length > 0
              ? `Something went wrong error: ${error.response.data.message} was given.`
              : 'Something went wrong please try again.';
          setError(true);
          setErrorMessage(errorState);
        }
      });
  }
};

usePost.PropTypes = {
  url: PropTypes.string,
  package: PropTypes.object,
  send: PropTypes.bool,
  cartItems: PropTypes.array,
  header: PropTypes.object
};
