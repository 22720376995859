import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import { OrderProps } from '../../interfaces/Order';
import List from '../List/List';
import { ChartIcon, Column } from '../shared';
import { Icon } from '../shared/Icon';
import FinanceItem from './FinanceItem';

interface FinanceProductsProps {
  id: number;
  orders: [OrderProps];
}
const FinanceProducts: React.FC<FinanceProductsProps> = ({ id, orders }) => {
  const [activePage, setActivePage] = useState(0);
  const renderPages: any = () => {
    let pagesArray: any = [];
    orders.forEach((order, index) => {
      if (order.order.length > 0) {
        if (index % 5 === 0) {
          pagesArray = [...pagesArray, [order]];
        } else {
          pagesArray[pagesArray.length - 1] = [
            ...pagesArray[pagesArray.length - 1],
            order
          ];
        }
      }
    });
    return pagesArray;
  };
  const pages = renderPages();

  return (
    <>
      <Column align="flex-start">
        <p>
          <b>Total:</b> {orders.length}
        </p>
        <p>
          <b>Shown:</b>
          {pages.length > 0 ? pages.reverse()[activePage].length : 0}
        </p>
      </Column>
      <Container>
        {pages.map((item: any, index: number) => (
          <Count
            onClick={() => setActivePage(index)}
            active={index === activePage}
          >
            {index + 1}
          </Count>
        ))}
      </Container>
      <List
        headerItems={['id', 'date', 'Total excl. (€)', 'Total inc. (€)', '']}
      >
        {orders.length > 0 ? (
          pages[activePage]
            .filter((filter: OrderProps) => filter.order.length > 0)
            .map((order: OrderProps, index: number) => {
              return <FinanceItem order={order} />;
            })
        ) : (
          <Column>
            <ChartIcon color="#dd016b36">
              <Icon getIcon="ChartBar" color={ThemeColors.primaryColor} />
            </ChartIcon>
            <h1> No orders found</h1>
          </Column>
        )}
      </List>
      <Container>
        {pages.map((item: any, index: number) => (
          <Count
            onClick={() => setActivePage(index)}
            active={index === activePage}
          >
            {index + 1}
          </Count>
        ))}
      </Container>
    </>
  );
};

export default FinanceProducts;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;
  margin: 8px auto;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const Count = styled.p<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  background-color: ${props =>
    props.active ? ThemeColors.primaryColor : 'rgba(221, 0, 107, 0.25)'};
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: all 0.5s ease;
  margin: 8px;
  cursor: pointer;
  position: relative;
  &:before {
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: rgba(221, 0, 107, 0.25);
    transition: all 0.5s ease;
    width: 0px;
    height: 0px;
    border-radius: 50%;
  }
  &:hover {
    background-color: ${ThemeColors.primaryColor};
    width: 35px;
    height: 35px;
    &:before {
      width: 50px;
      height: 50px;
    }
  }
`;
