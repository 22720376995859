import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { usePost } from '../../hooks/usePost';
import * as Interface from '../../interfaces';
import ChartCard from '../Charts/ChartCard';
import Input from '../Form/Input';
import { Container, MainButton, Row, Warning } from '../shared';
import { Icon } from '../shared/Icons';
import { Title } from '../Styles/Text';

const typesProps = {
  alert: 'alert',
  succes: 'succes'
};

type AlertProps = {
  type: keyof typeof typesProps;
  show: boolean;
  message: string;
};

interface PickUpProps {
  EstablishmentsData: Interface.Establishment | any;
  setEstablishmentData: Function;
  getEstablishment: Function;
  setAlerts: Function;
}

const PickUp: React.FC<PickUpProps> = ({
  EstablishmentsData,
  setEstablishmentData,
  getEstablishment,
  setAlerts
}) => {
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const apiHeaderAuth = (token: string, contentType: string) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}` //token
    };
    return header;
  };
  /**
   * States
   */
  const [warning, setWarning] = useState<AlertProps>({
    show: false,
    type: 'succes',
    message: ''
  });
  const [enablePickup, setEnablePickup] = useState(EstablishmentsData.pickup);
  const [pickupTime, setPickupTime] = useState(
    EstablishmentsData.pick_up_serve_time
  );
  const [pickupStartTime, setPickupStartTime] = useState(
    EstablishmentsData.start_times.pick_up
  );
  const [selectedUpdate, setSelectedUpdate] = useState<any>({
    package: {},
    type: ''
  });
  /**
   *
   */

  //
  const updateEstablishment = usePost(
    `establishment/${EstablishmentsData.id}/update/${selectedUpdate.type}`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedUpdate.package
  );
  /**
   *
   *
   */
  //
  useEffect(() => {
    if (selectedUpdate.type.length > 0) {
      updateEstablishment.sendPost(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate]);
  //
  useEffect(() => {
    if (
      updateEstablishment.postResponse &&
      updateEstablishment.postResponse.status &&
      updateEstablishment.postResponse.status === 200
    ) {
      getEstablishment(true);
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to update your pick up settings',
        type: 'success'
      };
      setAlerts(newAlert);
    } else if (
      updateEstablishment.errorResponse &&
      updateEstablishment.errorResponse.response &&
      (updateEstablishment.errorResponse.response.status === 401 ||
        updateEstablishment.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: updateEstablishment.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEstablishment.postResponse, updateEstablishment.errorResponse]);
  /**
   * Functions
   */
  //
  const closeWarning = () => {
    setWarning({ ...warning, show: false });
  };
  //
  const turnOnPickup = () => {
    if (!enablePickup) {
      setEnablePickup(true);
      setSelectedUpdate({
        type: 'general',
        package: {
          pick_up: 1
        }
      });
    }
  };
  //
  const turnOffPickup = () => {
    if (enablePickup) {
      setEnablePickup(false);
      setSelectedUpdate({
        type: 'general',
        package: {
          pick_up: 0
        }
      });
    }
  };
  //
  const updatePickupTime = () => {
    setSelectedUpdate({
      type: 'general',
      package: {
        pick_up_serve_time: pickupTime
      }
    });
  };
  //
  const updatePickupStartTime = () => {
    setSelectedUpdate({
      type: 'general',
      package: {
        start_time_pick_up: pickupStartTime
      }
    });
  };
  //
  //
  return (
    <Container>
      {warning.show && (
        <Warning type={warning.type} close={() => closeWarning()}>
          {warning.message}
        </Warning>
      )}
      <ChartCard width="100%">
        <Title margin="0 0 16px 0">Enable pick up</Title>
        <Row>
          <Span>
            <MainButton
              type={enablePickup ? 'ghost' : 'main'}
              action={() => turnOnPickup()}
            >
              {updateEstablishment.postLoading ? (
                <Icon full={true} type="spinning" icon="Spinner" />
              ) : (
                'yes'
              )}
            </MainButton>
          </Span>
          <Span>
            <MainButton
              type={enablePickup ? 'main' : 'ghost'}
              action={() => turnOffPickup()}
            >
              {updateEstablishment.postLoading ? (
                <Icon full={true} type="spinning" icon="Spinner" />
              ) : (
                'no'
              )}
            </MainButton>
          </Span>
        </Row>
      </ChartCard>
      {enablePickup && (
        <Row>
          <ChartCard title="Pick up time" width="100%">
            <Row AItems="flex-end" JContent="space-between">
              <Input
                inputValue={pickupTime}
                setInputValue={setPickupTime}
                placeholder="45"
                label="In minutes"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="70%"
                labelColor={ThemeColors.primaryColor}
              />
              <MainButton type="mainSmallest" action={() => updatePickupTime()}>
                {updateEstablishment.postLoading ? (
                  <Icon full={true} type="spinning" icon="Spinner" />
                ) : (
                  'Save'
                )}
              </MainButton>
            </Row>
          </ChartCard>
          <ChartCard title="Pick up start time" width="100%">
            <Row AItems="flex-end" JContent="space-between">
              <Input
                inputValue={pickupStartTime}
                setInputValue={setPickupStartTime}
                placeholder="17:00"
                label="Time pick up is possible"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                type="time"
                containerWidth="70%"
                labelColor={ThemeColors.primaryColor}
              />
              <MainButton
                type="mainSmallest"
                action={() => updatePickupStartTime()}
              >
                {updateEstablishment.postLoading ? (
                  <Icon full={true} type="spinning" icon="Spinner" />
                ) : (
                  'Save'
                )}
              </MainButton>
            </Row>
          </ChartCard>
        </Row>
      )}
    </Container>
  );
};

export default PickUp;

const Span = styled.span`
  margin: 0 16px;
  width: 100%;
`;
