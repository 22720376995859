import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config } from '../../config/config';
// Json
import copy from '../../data/copy.json';
import { apiHeaderAuth } from '../../helpers';
// Function
import { useGetData } from '../../hooks/useGetData';
import { Row } from '../shared/Layout';
import OrdersTotal from './OrdersTotal';

const OrdersDate = ({ type, EstablishmentsData }) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token'));
  // Date
  const [day, setDay] = useState(24);
  const [week, setWeek] = useState(8);
  const [month, setMonth] = useState(8);
  const [year, setYear] = useState(2019);

  const [url, setUrl] = useState(
    `establishment/${EstablishmentsData.id}/orders/picked_up`
  );

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [resend, setResend] = useState(true);
  /**
   * Get data
   */
  useGetData(
    apiHeaderAuth(getToken.access_token),
    url,
    resend,
    setResend,
    setData,
    setLoading
  );
  //

  //
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  /**
   * setDates
   */
  useEffect(() => {
    const d = new Date();
    const onejan = new Date(d.getFullYear(), 0, 1);
    const week = Math.ceil(
      ((d.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) / 7
    );
    setDay(d.getDate());
    setWeek(week);
    setMonth(d.getMonth() + 1);
    setYear(d.getFullYear());
    setResend(true);
  }, []);

  useEffect(() => {
    setUrl(
      `establishment/${EstablishmentsData.id}/orders/date/day/` +
        day +
        '/' +
        month +
        '/' +
        year
    );
  }, [EstablishmentsData.id, day, month, year]);
  /**
   * If orders exist change loading state
   */
  useEffect(() => {
    if (data.data && data.data.length > 0) {
      setLoading(false);
      setTotalOrders(data.data.length);
      let total = 0;
      data.data.forEach(item => {
        total += Number(item.total) - config.drinks.transactionFee;
      });
      setTotalPrice(total.toFixed(2));
    }
  }, [data]);
  /*
   * if order has changed set resend to false
   */
  useEffect(() => {
    setResend(false);
  }, [resend]);
  /**
   *
   */
  if (loading) {
    return (
      <Container>
        <h1>{copy.general.loading}</h1>
      </Container>
    );
  } else {
    return (
      <Container>
        <DatesContainer>
          <Row JContent="space-between">
            {type === 'tax' && (
              <OrdersTotal
                totalOrders={totalOrders}
                totalPrice={totalPrice}
                title="Today"
                getToken={getToken}
                url={`establishment/${EstablishmentsData.id}/orders/picked_up/date/day/${day}/${month}/${year}`}
              />
            )}
          </Row>
        </DatesContainer>
        <Row>
          <OrdersTotal
            totalOrders={totalOrders}
            totalPrice={totalPrice}
            title="This Week"
            getToken={getToken}
            url={`establishment/${EstablishmentsData.id}/orders/picked_up/date/week/${week}/${month}/${year}`}
          />
        </Row>
        <Row>
          <OrdersTotal
            totalOrders={totalOrders}
            totalPrice={totalPrice}
            title="This Month"
            getToken={getToken}
            url={`establishment/${EstablishmentsData.id}/orders/picked_up/date/month/${month}/${year}`}
          />
        </Row>
        <Row>
          <OrdersTotal
            totalOrders={totalOrders}
            totalPrice={totalPrice}
            title="This Year"
            getToken={getToken}
            url={`establishment/${EstablishmentsData.id}/orders/picked_up/date/year/2021`}
          />
        </Row>
      </Container>
    );
  }
};

OrdersDate.propTypes = {
  type: PropTypes.string,
  showCompleted: PropTypes.bool,
  setShowCompleted: PropTypes.func,
  EstablishmentsData: PropTypes.object
};

export default OrdersDate;

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 16px;
`;

const DatesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 16px;
  @media (max-width: 1024px) {
    a {
      margin: 16px 16px;
    }
  }
`;
