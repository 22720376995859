import { randomString } from '../../utils';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REDUCE_ALERT = 'REDUCE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

/**
 * Save passed on data as establishment
 * @param establishment
 * @returns void
 */
export const setAlert = (alert: any) => {
  return function (dispatch: Function) {
    const id = randomString(5);
    dispatch({ type: SET_ALERT, payload: { ...alert, id } });

    setTimeout(() => {
      dispatch({ type: REMOVE_ALERT, payload: id });
    }, 3000);
  };

  //return { type: SET_ALERT, payload: alert };
};
/**
 *
 * @param alertId
 * @returns
 */
export const reduceAlert = () => {
  return { type: REDUCE_ALERT };
};
/**
 *  Pass on alert ID to be removed
 */
export const removeAlert = (alertId: any) => {
  //console.log('removeAlert', alertId);
  return { type: REMOVE_ALERT, payload: alertId };
};

/**
 *
 * @param alertId
 * @returns
 */
export const clearAlert = () => {
  return { type: CLEAR_ALERT };
};
