import { OrderProps } from '../interfaces/Order';

export type ChartProps = {
  id: number;
  qty: number;
  name: string;
};

/**
 *
 */
export const highestChartNumber = (chartData: ChartProps[]) => {
  let highestNumber = 0;
  chartData.forEach((item: ChartProps) =>
    highestNumber < item.qty ? (highestNumber = item.qty) : item
  );
  return highestNumber;
};
/**
 *
 * @param partialValue
 * @param totalValue
 */
export const percentage = (partialValue: number, totalValue: number) => {
  return (100 * partialValue) / totalValue;
};
/**
 *
 */
/**
 *
 */
export const chartNumbers = (Orders: OrderProps[]) => {
  let Chart: any = [];
  Orders.forEach((order: OrderProps) => {
    order.order.forEach(item => {
      const newChartObject: ChartProps = {
        id: item.pivot.product_id,
        qty: item.pivot.qty,
        name: item.name
      };
      const elementExists = Chart.find(
        (item: ChartProps) => item.id === newChartObject.id
      );

      if (elementExists && elementExists.id) {
        Chart = Chart.map((item: ChartProps) =>
          item.id === newChartObject.id
            ? { ...item, qty: item.qty + newChartObject.qty }
            : item
        );
      } else {
        Chart = [...Chart, newChartObject];
      }
    });
  });
  return Chart;
};
