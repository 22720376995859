import { useEffect, useState } from 'react';
import { AlertFields } from '../../components/Alert/Alert';
import { config } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { useGet } from '../../hooks';
import { Establishment } from '../../interfaces';

export const useSeatData = (
  EstablishmentsData: Establishment,
  updating: boolean,
  setAlert: Function
) => {
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const [firstLoad, setFirstLoad] = useState(true);
  const getSeats = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${EstablishmentsData.id}/seats/show`,
    true
  );

  useEffect(() => {
    if (updating) {
      getSeats.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  //
  useEffect(() => {
    if (getSeats.fetchResponse.length > 0) {
      if (!firstLoad) {
        const newAlert: AlertFields = {
          title: 'Fetching was an success',
          message: 'Loaded the latest table data',
          type: 'success'
        };
        setAlert(newAlert);
      } else {
        setFirstLoad(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeats.fetchResponse]);

  useEffect(() => {
    if (getSeats.fetchError) {
      const newAlert: AlertFields = {
        title: 'Oops something went wrong',
        message:
          getSeats.fetchErrorMessage.length > 0
            ? getSeats.fetchErrorMessage
            : 'Please try again later',
        type: 'error'
      };
      setAlert(newAlert);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSeats.fetchError]);

  return { getSeats };
};
