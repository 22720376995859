import React, { useState } from 'react';
import { MdChevronRight } from 'react-icons/md';
import styled from 'styled-components';
import { tax, toCurrency } from '../../helpers';
import { OrderProps } from '../../interfaces/Order';
import { ActionsContainer, ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import { Row } from '../shared';
interface FinanceItemProps {
  order: OrderProps;
}
const FinanceItem: React.FC<FinanceItemProps> = ({ order }) => {
  const [open, setOpen] = useState(true);
  const priceEx = tax(false, Number(order.total), 7);
  const date = order.created_at.split(' ');
  let total = 0;
  return (
    <Container>
      <ItemContainer>
        <ListItem># {order.id}</ListItem>
        <ListItem>
          <b>{date[1]}</b>
          <br />
          <i>{date[0]}</i>
        </ListItem>
        <ListItem>{toCurrency(priceEx)}</ListItem>
        <ListItem>{toCurrency(Number(total))}</ListItem>
        <ActionsContainer>
          <Action onClick={() => setOpen(!open)} open={open}>
            <MdChevronRight size={30} />
          </Action>
        </ActionsContainer>
      </ItemContainer>
      {open && (
        <Collapse>
          <CollapseHeader>
            <Row>
              <ListItem color="white">
                <b>#id</b>
              </ListItem>
              <ListItem color="white">
                <b>name</b>
              </ListItem>
              <ListItem color="white">
                <b>price</b>
              </ListItem>
              <ListItem color="white">
                <b>qty</b>
              </ListItem>
              <ListItem color="white">
                <b>total</b>
              </ListItem>
              <HR />
            </Row>
          </CollapseHeader>

          {order.order.map((item, index) => {
            const priceEx = tax(false, Number(order.total), 7);
            total += Number(item.pivot.total);
            return (
              <Row margin="0 90px 0 0">
                <ListItem># {item.id}</ListItem>
                <ListItem> {item.name}</ListItem>
                <ListItem> {toCurrency(Number(item.price))}</ListItem>
                <ListItem> {item.pivot.qty}</ListItem>
                <ListItem> {toCurrency(Number(item.pivot.total))}</ListItem>
                <HR />
              </Row>
            );
          })}
          <OrderItem>
            <b>Total: {toCurrency(Number(total))}</b>{' '}
          </OrderItem>
        </Collapse>
      )}
    </Container>
  );
};

export default FinanceItem;

const Container = styled.div``;
const Collapse = styled.div`
  margin-top: -16px;
  background-color: #f5f5f5;
  padding-bottom: 24px;
`;

const HR = styled.span`
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: #80808038;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const CollapseHeader = styled.div`
  color: white;
  background: #011627;
`;

const Action = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: background-color 0.5s ease;
  position: relative;
  transform: ${props => (props.open ? 'rotateZ(-90deg)' : 'rotateZ(90deg)')};

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: #d3d3d373;
    transition: all 0.5s ease;
    width: 0;
    height: 0;
    border-radius: 50%;
  }

  &:hover {
    background-color: #d3d3d373;

    &:after {
      width: 50px;
      height: 50px;
    }
  }
`;

const OrderItem = styled.p`
  width: 100%;
  text-align: right;
  color: #011627;
  font-weight: normal;
  padding-right: 32px;
`;
