import React from 'react';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import { ListProps } from '../../interfaces/List';
import { Content } from '../shared';
import ListHeader from './ListHeader';

const List: React.FC<ListProps> = ({ children, headerItems, align }) => {
  return (
    <Content width="100%" padding="0" margin="0">
      <ListHeader headerItems={headerItems} align={align} />
      <ListLayout>{children}</ListLayout>
    </Content>
  );
};

export default List;

const ListLayout = styled.ul`
  width: 100%;
`;

export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #d8d8d8;
  margin: 16px 0;
`;

export const ActionsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

export const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justtify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;
