import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Icon as IconB } from '../shared/Icon';

import { apiHeaderAuth, toCurrency } from '../../helpers';
import { useGet } from '../../hooks/useGet';
import { config, ThemeColors } from '../../config';
import BarChart from '../Charts/Bar/BarChart';
import { Column, Row } from '../shared/Layout';
import { OrderProps } from '../../interfaces/Order';
import * as Interface from '../../interfaces';
import ChartCard from '../Charts/ChartCard';
import silverwareForkKnife from '@iconify/icons-mdi/silverware-fork-knife';
import euroSymbol from '@iconify/icons-mdi/euro-symbol';
import chefHat from '@iconify/icons-mdi/chef-hat';
import divisionIcon from '@iconify/icons-mdi/division';
import LineChartContainer from '../Charts/Line/LineChartContainer';
import StatProducts from '../products/StatProducts';
import { MainButton, ChartIcon as ChartIconB } from '../shared';
import FinanceProducts from '../products/FinanceProducts';
import {
  chartNumbers,
  ChartProps,
  highestChartNumber,
  percentage
} from '../../utils/Chart';
import { Heading } from '../Styles/Text';
//import LineChart from '../Charts/Line/LineChart';

interface StatsProps {
  EstablishmentsData: Interface.Establishment;
  updating: boolean;
}

const Stats: React.FC<StatsProps> = ({ EstablishmentsData, updating }) => {
  const newDate = new Date();
  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const onejan = new Date(newDate.getFullYear(), 0, 1);
  const week = Math.ceil(
    ((newDate.getTime() - onejan.getTime()) / 86400000 + onejan.getDay() + 1) /
      7
  );
  const day = newDate.getDate();
  const [selectedChart, setSelectedChart] = useState(0);
  const [chartData, setStateChartData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [activeTab, setActiveTab] = useState('orders');
  const [date, setDate] = useState('year/2021');
  const [inputDate, setInputDate] = useState('');
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const {
    fetchResponse,
    fetchLoading,
    getFetch,
    fetchError,
    fetchErrorMessage
  } = useGet(
    apiHeaderAuth(getToken.access_token),
    `establishment/${EstablishmentsData.id}/orders/picked_up/date/${date}`
  );

  /**
   *
   */
  useEffect(() => {
    if (updating) {
      getFetch(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);
  /**
   *
   */
  useEffect(() => {
    getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  useEffect(() => {
    getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  /**
   *
   */
  useEffect(() => {
    if (!fetchLoading && fetchResponse.data.length > 0) {
      setStateChartData(chartNumbers(fetchResponse.data));
      setTotalOrders(fetchResponse.data.length);
      let totalProductsCount = 0;
      let totalProductPrice = 0;
      fetchResponse.data.forEach((order: OrderProps) => {
        order.order.forEach(product => {
          totalProductsCount += product.pivot.qty;
          totalProductPrice += Number(product.pivot.total);
        });
      });
      setTotalProducts(totalProductsCount);
      setTotalProductPrice(totalProductPrice);
    } else {
      setTotalOrders(0);
      setTotalProducts(0);
      setTotalProductPrice(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResponse, fetchLoading]);

  const Orders = () => {
    return (
      <>
        <Row AItems="flex-start">
          <Column size="30">
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="#e9b07f36">
                  <Icon
                    icon={chefHat}
                    color={ThemeColors.primaryColor}
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>{totalOrders}</TotalOrders>
                  <TotalOrdersText>Total orders completed</TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="rgba(67, 146, 241, 0.1)">
                  <Icon
                    icon={silverwareForkKnife}
                    color="#4392F1"
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>{totalProducts}</TotalOrders>
                  <TotalOrdersText>Total products sold</TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="rgba(67, 146, 241, 0.1)">
                  <Icon
                    icon={divisionIcon}
                    color="#4392F1"
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>
                    {totalProducts > 0 && totalOrders > 0
                      ? Math.round(totalProducts / totalOrders)
                      : 0}
                  </TotalOrders>
                  <TotalOrdersText>
                    Average amount of products per order
                  </TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
          </Column>
          <ChartCard
            title="Popular Products"
            width="65%"
            btnAction={setDate}
            btnText={date}
          >
            {fetchResponse.data.length > 0 ? (
              chartData
                .sort((a: ChartProps, b: ChartProps) => b.qty - a.qty)
                .slice(0, 5)
                .map((item: ChartProps, index) => {
                  const highestNumber = highestChartNumber(chartData);
                  return (
                    <BarChart
                      subject={item.name}
                      width={`${percentage(item.qty, highestNumber)}%`}
                      count={item.qty}
                      focus={selectedChart === index}
                      action={() => setSelectedChart(index)}
                    />
                  );
                })
            ) : (
              <Column>
                <ChartIconB color="rgba(67,146,241,0.1)">
                  <IconB getIcon="Hamburger" color="rgb(67, 146, 241)" />
                </ChartIconB>
                <h1> No products found</h1>
              </Column>
            )}
          </ChartCard>
        </Row>
        <Row>
          <ChartCard
            width="100%"
            title="Total orders"
            btnAction={setDate}
            btnText={date}
          >
            <LineChartContainer
              fetchResponse={fetchResponse}
              fetchLoading={fetchLoading}
              id={EstablishmentsData.id}
              type={activeTab}
            />
          </ChartCard>
        </Row>
        <Row>
          <ChartCard
            width="100%"
            title="Stock inventory"
            btnAction={setDate}
            btnText={date}
          >
            <StatProducts
              orders={fetchResponse.data}
              id={EstablishmentsData.id}
            />
          </ChartCard>
        </Row>
      </>
    );
  };

  const Finances = () => {
    return (
      <>
        <Row>
          <Row>
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="#e9b07f36">
                  <Icon
                    icon={euroSymbol}
                    color={ThemeColors.primaryColor}
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>{toCurrency(totalProductPrice)}</TotalOrders>
                  <TotalOrdersText>Total revenue</TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="rgba(67, 146, 241, 0.1)">
                  <Icon
                    icon={silverwareForkKnife}
                    color="#4392F1"
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>{totalProducts}</TotalOrders>
                  <TotalOrdersText>Total products sold</TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
            <ChartCard width="100%">
              <Row JContent="space-around">
                <ChartIcon color="rgba(67, 146, 241, 0.1)">
                  <Icon
                    icon={divisionIcon}
                    color="#4392F1"
                    style={{ fontSize: '24px' }}
                  />
                </ChartIcon>
                <Column align="flex-start">
                  <TotalOrders>
                    {totalProductPrice > 0 && totalOrders > 0
                      ? toCurrency(Math.round(totalProductPrice / totalOrders))
                      : toCurrency(0)}
                  </TotalOrders>
                  <TotalOrdersText>Average order revenue</TotalOrdersText>
                </Column>
              </Row>
            </ChartCard>
          </Row>
        </Row>
        <Row>
          <ChartCard
            width="100%"
            title="Total orders"
            btnAction={setDate}
            btnText={date}
          >
            <LineChartContainer
              fetchResponse={fetchResponse}
              fetchLoading={fetchLoading}
              id={EstablishmentsData.id}
              type={activeTab}
            />
          </ChartCard>
        </Row>
        <Row>
          <ChartCard
            width="100%"
            title="Orders"
            btnAction={setDate}
            btnText={date}
          >
            <FinanceProducts
              orders={fetchResponse.data}
              id={EstablishmentsData.id}
            />
          </ChartCard>
        </Row>
      </>
    );
  };

  if (fetchLoading) {
    return <p>loading</p>;
  } else {
    return (
      <Column>
        <Heading extraClasses=" w-full text-left">Statistics</Heading>
        <Row JContent="flex-start">
          <Row JContent="flex-start">
            <MainButton
              action={() => setActiveTab('orders')}
              type="tab"
              active={activeTab === 'orders'}
            >
              Orders
            </MainButton>
            <MainButton
              action={() => setActiveTab('finances')}
              type="tab"
              active={activeTab === 'finances'}
            >
              Finances
            </MainButton>
          </Row>
          <Row JContent="flex-end">
            <input
              className="mr-4 rounded py-1 px-2"
              type="date"
              id="start"
              name="trip-start"
              value={inputDate}
              onChange={e => {
                setInputDate(e.target.value);
                setDate(
                  `day/${e.target.value
                    .split('-')
                    .reverse()
                    .map(
                      (item, i) =>
                        `${i >= 2 ? item : item.replaceAll('0', '')}${
                          i >= 2 ? '' : '/'
                        }`
                    )
                    .toString()
                    .replaceAll(',', '')}`
                );
              }}
            />
            <Select
              name="Pick date"
              onChange={(e: any) => {
                const value = e.target.value;
                setDate(value);
              }}
            >
              <option
                selected={date === `day/${day}/${month + 1}/${year}`}
                value={`day/${day}/${month + 1}/${year}`}
              >
                Today
              </option>
              <option
                selected={date === `week/${week - 1}/${month + 1}/${year}`}
                value={`week/${week - 1}/${month + 1}/${year}`}
              >
                Last week
              </option>
              <option
                selected={date === `month/${month + 1}/${year}`}
                value={`month/${month + 1}/${year}`}
              >
                Last Month
              </option>
              <option selected={date === `year/${year}`} value={`year/${year}`}>
                Last Year
              </option>
            </Select>
          </Row>
        </Row>
        {activeTab === 'orders' && Orders()}
        {activeTab === 'finances' && Finances()}
      </Column>
    );
  }
};

export default Stats;

const ChartIcon = styled.div<{ color?: string }>`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: ${props => props.color || '#fce5f0'};
  margin-right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalOrders = styled.h4`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  color: #011627;
  margin: 0;
`;

const TotalOrdersText = styled.p`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #979797;
  margin: 0;
`;

export const PageTitle = styled.h1`
  width: 100%;
  text-align: left;
  padding-left: 16px;
`;

const Select = styled.select`
  border-radius: 4px;
  padding: 8px;
  width: 200px;
`;
