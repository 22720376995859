import axios from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
  Container,
  Row,
  ProductSearch,
  ProductModal,
  ProductList,
  ChartCard,
  Heading
} from '../../components';

import { config } from '../../config';
import { apiHeaderAuth, SearchFilter } from '../../helpers';
import { AlertProp } from '../../interfaces/Alert';
import { ProductProps } from '../../interfaces/Product';
import { setAlert } from '../../redux/actions';
import { ApiUrl } from '../../utils';
import useProductData from './Products.Data';
import useProduct from './Products.Hooks';
import { AllProps, ProductDataProps } from './Products.Interface';

//
const getToken = JSON.parse(sessionStorage.getItem('user_token') || '{}');
//
const Products: React.FC<AllProps> = ({
  EstablishmentsData,
  match,
  setAlert
}) => {
  //
  const {
    image,
    setImage,
    search,
    setSearch,
    data,
    setData,
    addonsData,
    setAddonsData,
    filterData,
    setFilterData,
    selectedAddons,
    setSelectedAddons,
    removeAddons,
    setRemoveAddons,
    error,
    setError,
    modalData,
    setModalData
  } = useProduct(EstablishmentsData);
  //
  const {
    // states
    apiPackage,
    setApiPackage,
    editUrl,
    setEditUrl,
    productData,
    setProductData,
    // api calls
    getAddons,
    getProducts,
    postError,
    errorResponse,
    postLoading,
    postResponse,
    sendPost,
    putProduct,
    postProduct
  } = useProductData(
    EstablishmentsData,
    setAlert,
    data,
    setData,
    setAddonsData,
    setSelectedAddons,
    setRemoveAddons,
    setModalData,
    match
  );
  /**
   * Filters data with the SearchFilter func and updates setFilterData
   * @param {string} event
   */
  const changeSearchValue = (event: string) => {
    setSearch(event);
    const newData = data.filter(SearchFilter(event));
    setFilterData(newData);
  };
  /**
   * pops an Alert  with an confirm button
   * if confirmed change order status
   *
   * @param {Object} item
   * @param {String} value
   * @param {String} msg
   */
  const AlertPrompt = (msg: string) => {
    if (window.confirm('Are you sure you want to' + msg)) {
      putProduct.sendPost(true);
    }
  };
  //
  const selectProduct = (product: ProductProps) => {
    const soldOut: any = product.sold_out;
    setProductData({
      ...product,
      sold_out: soldOut === 1 || soldOut ? 1 : 0,
      price: product.price ? product.price.toString() : '',
      removeAddons: []
    });
    setSelectedAddons(product.addons);
    setRemoveAddons([]);
    setModalData({
      show: true,
      title: `Edit ${product.name}`,
      url: `establishment/${EstablishmentsData.id}/product/edit/${product.id}`
    });
  };
  //
  const closeModal = () => {
    setModalData({
      show: false,
      title: '',
      url: ''
    });
  };
  /**
   *
   */
  const submitData = () => {
    const json = JSON.stringify(productData);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const packageData = productData.file.append('document', blob);
    console.log('blob', blob);
    console.log(productData.file);
    console.log(packageData);
  };
  //
  const removeProduct = (product: ProductDataProps) => {
    const remove = async () => {
      try {
        const response = await axios.post(
          `${ApiUrl}establishment/${EstablishmentsData.id}/product/remove`,
          product,
          {
            headers: apiHeaderAuth(getToken.access_token)
          }
        );
        //
        const newAlert: AlertProp = {
          title: 'Product removed',
          message: `Successfully removed ${product.name}`,
          type: 'success'
        };
        setAlert(newAlert);
        getProducts.getFetch(true);
        //
        return response;
      } catch (error) {
        console.log('error', error);
        const newAlert: AlertProp = {
          title: 'Oeps. Something went wrong',
          message: `${error.message}`,
          type: 'error'
        };
        setAlert(newAlert);
        return error;
      }
    };
    if (window.confirm(`Are you sure you want to remove ${product.name}`)) {
      // Save it!
      remove();
    } else {
      // Do nothing!
      //console.log('Thing was not saved to the database.');
    }
  };
  //
  const submitModal = (type: string) => {
    const json = JSON.stringify({
      ...productData,
      addons: selectedAddons,
      removeAddons: removeAddons
    });
    const blob = new Blob([json], {
      type: 'application/json'
    });
    const formData = new FormData();
    formData.append('document', blob);

    if (image && image.imageFile) {
      const findImage: any = document.getElementById('myFile');
      if (findImage && findImage.files) {
        formData.append('file', findImage.files[0], findImage.files[0].name);
      }
    }

    setApiPackage(formData);
    if (type === 'put') {
      //
      putProduct.sendPost(true);
      //submitData();
    } else if (type === 'post') {
      postProduct.sendPost(true);
    }
  };
  //
  const newProduct = () => {
    setModalData({
      show: true,
      title: 'Create new product',
      url: `establishment/${EstablishmentsData.id}/product/create`
    });
    setProductData({
      id: 0,
      name: '',
      description: '',
      price: '',
      type: '',
      brand: '',
      qty: 0,
      addons: [],
      removeAddons: [],
      sold_out: 0
    });
    setSelectedAddons([]);
    setRemoveAddons([]);
  };
  //
  const selectImage = async (imageFile: any) => {
    //
    if (imageFile.target.files[0]) {
      const reader = new FileReader();
      // const newImage = new Blob([imageFile.target.files[0]], {
      //   type: imageFile.target.files[0].type
      // });
      //
      const json = JSON.stringify({
        ...productData,
        addons: selectedAddons,
        removeAddons: removeAddons
      });
      const blob = new Blob([json], {
        type: 'application/json'
      });
      //
      const formData = new FormData();
      formData.append('document', blob);
      //
      formData.append(
        'file',
        imageFile.target.files[0],
        imageFile.target.files[0].name
      );
      setApiPackage(formData);
      //
      reader.readAsDataURL(imageFile.target.files[0]);
      //
      const imageType: string = imageFile.target.files[0].type;
      const imageName: string = imageFile.target.files[0].name;
      const imageSize: number = imageFile.target.files[0].size;
      //
      reader.onloadend = function (e) {
        const result: string | any = reader.result;
        //
        setImage({
          ...image,
          contentType: imageType,
          image_name: imageName,
          data: {
            type: imageType,
            name: imageName,
            size: imageSize
          },
          imageFile
        });
        //
        //console.log('formData', formData);
        setProductData({
          ...productData,
          file: formData,
          image: result
        });
      };
    }
  };
  //
  return (
    <Container>
      {/* {error.show && (
        <Warning
          type={error.type}
          close={() => setError({ ...error, show: false })}
        >
          {error.message}
        </Warning>
      )} */}
      <Row JContent="space-between" margin="16px 48px">
        <Heading>Products</Heading>
      </Row>
      <Row JContent="flex-end" margin="16px 48px">
        <ProductSearch
          changeSearchValue={changeSearchValue}
          newProduct={newProduct}
        />
      </Row>
      <ChartCard width="100%">
        <ProductList
          products={filterData}
          setProduct={selectProduct}
          removeProduct={removeProduct}
        />
      </ChartCard>
      {modalData.show && (
        <ProductModal
          closeModal={closeModal}
          title={modalData.title}
          product={productData}
          editProduct={setProductData}
          submit={submitModal}
          selectedAddons={selectedAddons}
          setSelectedAddons={setSelectedAddons}
          setRemoveAddons={setRemoveAddons}
          removeAddons={removeAddons}
          addonsData={addonsData}
          selectImage={selectImage}
        />
      )}
    </Container>
  );
};

const mapDispatchToProps = { setAlert };

export default withRouter(connect(null, mapDispatchToProps)(Products));
