import { useEffect } from 'react';
import { ApiUrl } from '../utils/CreatUrl';
import axios from 'axios';
import PropTypes from 'prop-types';

/**
 * custom post hook that returns the API response and the API post function
 * @param {string} url
 * @param {object} Package
 * @param {boolean} send
 * @returns {array} and @param {function}
 */

export const usePutData = (
  header,
  url,
  Package,
  send,
  setData,
  setResend,
  setError,
  setErrorMessage,
  setSend
) => {
  useEffect(() => {
    //
    send && putData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]); //this will run when url or Package changes

  const putData = async () => {
    //this will have the updated input Package
    const API = ApiUrl + url;
    await axios
      .put(API, Package, { headers: header })
      .then(function (response) {
        //console.log("put", response);
        setSend && setSend(false);
        setResend && setResend(false);
        setData && setData(response);
      })
      .catch(function (error) {
        setSend && setSend(false);
        setResend && setResend(false);
        if (setError || setErrorMessage) {
          console.log('trigger error message');
          const errorState =
            error.response.status === 404
              ? `Oops! Request not found, status ${error.response.status} returned. Please try again or contact us at info@rapio.nl`
              : error.response.data.message.length > 0
              ? `Something went wrong error: ${error.response.data.message} was given.`
              : error.response.status === 401
              ? error.response.data.message
              : 'Something went wrong please try again.';
          setError(true);
          setErrorMessage(errorState);
        }
      });
  };
};

usePutData.propTypes = {
  url: PropTypes.string,
  package: PropTypes.object,
  send: PropTypes.bool
};
