import React, { FC, useState } from 'react';
import { FaArrowLeft, FaSearch } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { Button, Heading } from '../../../components';
import { toCurrency } from '../../../helpers';
import { Establishment } from '../../../interfaces';
import { OrderProps } from '../../../interfaces/Order';
import { OrdersProps } from '../Orders.Interface';
import { useOrderHistory } from './OrdersHistory.Hooks';

interface Props {
  EstablishmentsData: Establishment;
  updating: boolean;
}

const OrdersHistory: FC<Props> = ({ EstablishmentsData, updating }) => {
  const date = new Date();
  const [month, day, year] = [
    date.getMonth(),
    date.getDate(),
    date.getFullYear()
  ];
  //
  const [search, setSearch] = useState('');
  //
  const [searchDate, setSearchDate] = useState({
    day: day,
    month: month + 1,
    year: year
  });
  //
  const { getFetch, fetchResponse, fetchLoading } = useOrderHistory(
    EstablishmentsData.id,
    searchDate
  );
  //
  const response = fetchResponse.data || [];
  //
  const filterResponse = response.filter(
    (item: OrderProps) =>
      item.code.toLowerCase().includes(search.toLowerCase()) ||
      item.user_name.toLowerCase().includes(search.toLowerCase()) ||
      item.total.toString().toLowerCase().includes(search.toLowerCase())
  );
  const { push } = useHistory();

  return (
    <>
      <section className="history__section flex flex-col justify-start items-start w-full h-full">
        <div className="flex justify-between items-center w-full">
          <Heading>Order History</Heading>
          <div className="flex">
            <Button
              type="light"
              extraClasses="flex items-center mr-4"
              action={() => push('/orders')}
            >
              <FaArrowLeft className="mr-4" /> orders
            </Button>
            <Button action={() => getFetch(true)} disabled={fetchLoading}>
              {fetchLoading ? 'fetching' : 'Refresh'}
            </Button>
            <input
              className="ml-4 rounded px-2"
              type="date"
              id="start"
              name="trip-start"
              onChange={e =>
                setSearchDate({
                  day: Number(e.target.value.split('-')[2]),
                  month: Number(e.target.value.split('-')[1]),
                  year: Number(e.target.value.split('-')[0])
                })
              }
              max={`${year}-${month}-${day}`}
            />
          </div>
        </div>
        <div className="flex flex-col my-8 w-full">
          <label className="font-bold" htmlFor="search__history">
            Search
          </label>
          <div className="w-full relative mt-4">
            <input
              className="p-4 shadow w-full"
              placeholder="Enter order code, date, price, recipient name"
              id="search__history"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <FaSearch className="text-primary absolute right-4 top-1/2 transform -translate-y-1/2" />
          </div>
        </div>
        <div className="history__container grid grid-cols-2 gap-9 w-full">
          {fetchResponse.data &&
            filterResponse.map((receipt: OrderProps, i: number) => {
              const dates = receipt.created_at && receipt.created_at.split(' ');
              return (
                <>
                  <div
                    className="grid gap-2 grid-cols-2 bg-white shadow my-8  p-6 "
                    key={i + receipt.code}
                  >
                    <p className="font-bold">
                      Name:
                      <br /> {receipt.user_name}
                    </p>
                    {dates && (
                      <p className="italic text-right">
                        {`${dates[1] ? dates[1] : ''} `}
                        <br /> {dates[0]}
                      </p>
                    )}
                    <p className="font-bold">
                      Code: <br /> {receipt.code}
                    </p>
                    <div className="col-start-1 col-end-3 border-t pt-2">
                      {receipt.order.map((order, i) => {
                        return (
                          <>
                            <ul>
                              <li className="flex justify-between my-2">
                                <p>
                                  {order.pivot.qty} x {order.name}
                                </p>
                                <p>{toCurrency(Number(order.pivot.total))}</p>
                              </li>
                            </ul>
                          </>
                        );
                      })}
                      <p className="mt-8">
                        Total: {toCurrency(Number(receipt.total))}
                      </p>
                      <p className="italic opacity-60">
                        excl. service and payment fee
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </section>
    </>
  );
};

export default OrdersHistory;
