import React from 'react';
import * as FontAwesome from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import copy from '../../data/copy.json';
import { Establishment } from '../../interfaces';
import { OrderReducerPayload } from '../../redux/reducers/Orders.Reducer';
import { Icon } from '../shared';

interface NavProps {
  open: boolean;
  activeAll: boolean;
  updating: boolean;
  orders: OrderReducerPayload;
  EstablishmentsData: Establishment;
}

const Nav: React.FC<NavProps> = ({
  open,
  activeAll,
  updating,
  orders,
  EstablishmentsData
}) => {
  const location = useLocation();

  const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('user_token');
    localStorage.removeItem('establishment');
    document.location.reload();
  };
  return (
    <Container id="nav">
      <NavList>
        {copy.menuItems
          .filter(filter =>
            filter.text === 'Tables' ? EstablishmentsData.tables : filter
          )
          .map((item, index) => {
            const active =
              `${item.url.toLowerCase()}` === location.pathname.toLowerCase();

            return (
              <NavItem key={index} active={active}>
                <Link to={activeAll ? item.url : 'select_establishment'}>
                  <IconContainer active={active}>
                    <Icon icon={item.icon} size={25} color="white" />
                  </IconContainer>
                  <p>{item.text}</p>
                </Link>
                {item.text === 'Orders' && orders.response.length > 0 && (
                  <OrderIndicator src="/assets/svg/indicator.svg" />
                )}
              </NavItem>
            );
          })}
        <NavItem>
          <Link to="/logout" onClick={() => logout()}>
            <IconContainer>
              {React.createElement(FontAwesome['FaSignOutAlt'], {
                size: 25,
                color: 'white'
              })}
            </IconContainer>
            <p>Logout</p>
          </Link>
        </NavItem>
      </NavList>
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  const { Orders } = state;
  return {
    orders: Orders
  };
};

export default connect(mapStateToProps)(Nav);

const Container = styled.nav`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: ${ThemeColors.menuBackgroundColor};
  position: absolute;
  z-index: 10;
  transition: all 0.2s ease;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-top: 100px;
  max-height: 100vh;
  overflow-y: scroll;

  a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    color: black;
    text-decoration: none;
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const NavItem = styled.li<{ active?: boolean }>`
  margin: 10px 0;
  width: 100%;
  transition: opacity 0.5s ease;
  position: relative;

  p {
    color: white;
    margin: 8px 20px;
    min-width: 100px;
    text-align: center;
    font-weight: bold;
    transition: opacity 0.5s ease;
    opacity: ${props => (props.active ? '1' : '0.5')};
  }
`;

const IconContainer = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 70px;
  margin: 0 20px;
  border-radius: 8px;
  transition: all 1s ease;
  opacity: ${props => (props.active ? '1' : '0.5')};
  background-color: ${props =>
    props.active ? ThemeColors.primaryColor : ThemeColors.menuItemInactive};
  position: relative;
`;

const OrderIndicator = styled.img`
  position: absolute;
  left: 106px;
  top: -8px;
`;
