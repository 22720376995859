export const apiHeaderAuth = token => {
  const header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}` //token
  };
  return header;
};

export const apiHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};
