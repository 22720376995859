import { useEffect, useState } from 'react';
import { AlertProps } from './Table.Interfaces';
import * as Interface from '../../interfaces';

/**
 *
 * @returns
 */
export const useTable = (EstablishmentsData: Interface.Establishment) => {
  /**
   * States
   */
  const [warning, setWarning] = useState<AlertProps>({
    show: false,
    type: 'succes',
    message: ''
  });
  const [establishmentOpeningDays, setEstablishmentOpeningDays] = useState('');
  const [searchOpeningDays, setSearchOpeningDays] = useState('');
  const [filteredOpeningDays, setFilteredOpeningDays] = useState([]);
  const [enableDelivery, setEnableDelivery] = useState(
    EstablishmentsData.delivery
  );
  const [selectedOpeningDays, setSelectedOpeningDays] = useState<any>();
  const [selectedUpdate, setSelectedUpdate] = useState<any>({
    package: {},
    type: ''
  });
  const [establishmentPostalcodes, setEstablishmentPostalcodes] = useState('');
  const [searchPostals, setSearchPostals] = useState('');
  const [filteredPostals, setFilteredPostal] = useState([]);
  const [selectedPostal, setSelectedPostal] = useState<any>();
  const [enableTables, setEnableTables] = useState(EstablishmentsData.tables);
  return {
    warning,
    setWarning,
    establishmentOpeningDays,
    setEstablishmentOpeningDays,
    searchOpeningDays,
    setSearchOpeningDays,
    filteredOpeningDays,
    setFilteredOpeningDays,
    enableDelivery,
    setEnableDelivery,
    selectedOpeningDays,
    setSelectedOpeningDays,
    selectedUpdate,
    setSelectedUpdate,
    establishmentPostalcodes,
    setEstablishmentPostalcodes,
    searchPostals,
    setSearchPostals,
    filteredPostals,
    setFilteredPostal,
    selectedPostal,
    setSelectedPostal,
    enableTables,
    setEnableTables
  };
};
