/**
 * Changes every first letter of a word in a sentence into a capital
 * @param {String} word
 * @returns {String}
 */
export const firstLetterCapital = (sentence: string) => {
  let newSentence: string = '';
  if (sentence) {
    newSentence = sentence
      .toLowerCase()
      .split(' ')
      .map(item => item.charAt(0).toUpperCase() + item.slice(1) + ' ')[0];
  } else {
    newSentence = 'Sentence is undefined';
  }

  return newSentence;
};
/**
 *
 */
export const formatText = (sentance: string) => {
  let sentances: any = [];
  if (sentance) {
    sentances = sentance.split('/break/').map(item => {
      const format = item.split('/title/');
      return {
        title: format.length > 1 ? format[0] : '',
        text: format.length > 1 ? format[1] : format[0]
      };
    });
  }
  return sentances;
};
