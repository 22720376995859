import React from 'react';
import { MdCancel, MdEdit } from 'react-icons/md';
import styled from 'styled-components';
import { ThemeColors } from '../../config';
import { toCurrency } from '../../helpers';
import { ProductProps } from '../../interfaces/Product';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';

interface ProductListProps {
  products: [ProductProps] | any;
  setProduct: Function;
  removeProduct: Function;
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  setProduct,
  removeProduct
}) => {
  return (
    <List
      headerItems={[
        'name',
        'description',
        'type',
        'addons',
        'qty',
        'sold out',
        'price',
        'tax',
        'actions'
      ]}
    >
      {products.length > 0 ? (
        products.map((product: ProductProps, index: number) => {
          let addonDescription: string = '';
          for (let i = 0; i < product.addons.length; i++) {
            addonDescription += `${product.addons[i].name}, `;
          }
          return (
            <ItemContainer>
              <ListItem>{product.name}</ListItem>
              <ListItem>{product.description}</ListItem>
              <ListItem>{product.type}</ListItem>
              <ListItem>{addonDescription}</ListItem>
              <ListItem>
                <span
                  style={{
                    color:
                      product.qty > 10 && product.qty < 100
                        ? 'black'
                        : product.qty > 100
                        ? 'green'
                        : 'red'
                  }}
                >
                  {product.qty}
                </span>
              </ListItem>
              <ListItem color={product.sold_out ? 'red' : 'green'}>
                {product.sold_out ? 'yes' : 'no'}
              </ListItem>
              <ListItem>
                {product.price && toCurrency(Number(product.price))}
              </ListItem>
              <ListItem>{product.price && product.tax}</ListItem>
              <ActionsContainer>
                <Action onClick={() => setProduct(product)}>
                  <MdEdit size={30} />
                </Action>
                <Action onClick={() => removeProduct(product)}>
                  <MdCancel size={30} />
                </Action>
              </ActionsContainer>
            </ItemContainer>
          );
        })
      ) : (
        <ItemContainer>
          <p>No products found</p>
        </ItemContainer>
      )}
    </List>
  );
};

export default ProductList;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const Action = styled.div<{ large?: boolean; cancelled?: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: ${props =>
    props.cancelled ? '#2E4052' : ThemeColors.primaryColor};
  padding: 10px;
  margin: 10px;
  height: ${props => (props.large ? '75px' : '40px')};
  width: ${props => (props.large ? '75px' : '40px')};
  border-radius: 50%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  svg {
    width: 100%;
  }
`;
