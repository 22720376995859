import React, { useEffect, useState } from 'react';
import { config, ThemeColors } from '../../config';
import { apiHeaderAuth } from '../../helpers';
import { usePost } from '../../hooks/usePost';
import { Establishment } from '../../interfaces';
import Input from '../Form/Input';
//
import { MainButton, Row } from '../shared';
import { Icon } from '../shared/Icons';
import { DayProps } from './OpenDays';

interface EditOpeningDaysProps {
  day: DayProps;
  establishmentData: Establishment;
  setWarning: Function;
  setAlerts: Function;
}

const EditOpeningDay: React.FC<EditOpeningDaysProps> = ({
  day,
  establishmentData,
  setWarning,
  setAlerts
}) => {
  /**
   * Get stored data
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  /**
   * set states
   */
  const [openingHours, setOpeningHours] = useState(day.opening_hours);
  const [closingHours, setClosingHours] = useState(day.closing_hours);
  const [pickUpTime, setPickUpTime] = useState(day.start_time_pick_up);
  const [deliveryTime, setDeliveryTime] = useState(day.start_time_delivery);
  /**
   * define api calls
   */
  const { sendPost, postResponse, errorResponse, postLoading } = usePost(
    `establishment/${establishmentData.id}/opening_days/edit`,
    apiHeaderAuth(getToken.access_token),
    {
      id: day.id,
      opening_hours: openingHours,
      closing_hours: closingHours,
      start_time_pick_up: pickUpTime,
      start_time_delivery: deliveryTime
    }
  );
  /**
   *
   */
  useEffect(() => {
    if (postResponse && postResponse.status && postResponse.status === 200) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to update the opening day',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      errorResponse &&
      errorResponse.response &&
      (errorResponse.response.status === 401 ||
        errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postResponse, errorResponse]);
  /**
   *
   */
  return (
    <Row JContent="space-between" margin="0 0 8px 0" AItems="center">
      <div className="flex flex-col content-between w-full">
        <div className="grid grid-cols-2 gap-x-8 w-full pr-8">
          <Input
            inputValue={openingHours}
            setInputValue={setOpeningHours}
            placeholder="enter your opening time"
            label="Opening hours"
            labelColor={ThemeColors.primaryColor}
            // eslint-disable-next-line react/style-prop-object
            style="full"
            containerWidth="100%"
            type="time"
          />
          <Input
            inputValue={closingHours}
            setInputValue={setClosingHours}
            placeholder="enter your closing time"
            label="Closing hour"
            labelColor={ThemeColors.primaryColor}
            // eslint-disable-next-line react/style-prop-object
            style="full"
            containerWidth="100%"
            type="time"
          />
        </div>
        {(establishmentData.pickup || establishmentData.delivery) && (
          <div className="grid grid-cols-2 gap-x-8 w-full pr-8 pt-8">
            {establishmentData.pickup && (
              <Input
                inputValue={pickUpTime}
                setInputValue={setPickUpTime}
                placeholder="enter your starting pick up time"
                label="Start pickup time"
                labelColor={ThemeColors.primaryColor}
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="100%"
                type="time"
              />
            )}
            {establishmentData.delivery && (
              <Input
                inputValue={deliveryTime}
                setInputValue={setDeliveryTime}
                placeholder="enter your starting delivery time"
                label="start delivery time"
                labelColor={ThemeColors.primaryColor}
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="100%"
                type="time"
              />
            )}
          </div>
        )}
      </div>

      <MainButton
        type="mainSmallest"
        action={() => sendPost(true)}
        disabled={postLoading}
      >
        {postLoading ? (
          <Icon full={true} type="spinning" icon="Spinner" />
        ) : (
          'Save'
        )}
      </MainButton>
    </Row>
  );
};

export default EditOpeningDay;
