import { Establishment, getTokenProps, userProps } from '../../interfaces';

type ActionProps = {
  type: string;
  payload: userProps | getTokenProps | Establishment[];
};

const initialState: userProps = {
  name: '',
  email: '',
  role: '',
  establishment: [],
  auth: { access_token: '', expires_in: 0, refresh_token: '', token_type: '' }
};

const User = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    //
    case 'SET_USER': {
      state = { ...state, ...action.payload };
      return state;
    }
    //
    case 'SET_AUTH': {
      state.auth = { ...state.auth, ...action.payload };
      return state;
    }
    //
    case 'SET_ESTABLISHMENT': {
      const payload: any = action.payload;
      state.establishment = [...state.establishment, ...payload];
      return state;
    }
    //
    default:
      return state;
  }
};

export default User;
