import { OrderProps } from '../../interfaces/Order';

export const parseData = (data: OrderProps[]) => {
  //
  let createGroup: any = {
    waiting: [],
    preparing: [],
    ready: [],
    all: data
  };

  data.forEach((item: OrderProps) => {
    switch (item.status) {
      case 'waiting':
        createGroup = {
          ...createGroup,
          waiting:
            createGroup.waiting && createGroup.waiting.length > 0
              ? [...createGroup.waiting, item]
              : [item]
        };
        break;
      case 'prepairing':
        createGroup = {
          ...createGroup,
          prepairing:
            createGroup.prepairing && createGroup.prepairing.length > 0
              ? [...createGroup.prepairing, item]
              : [item]
        };
        break;
      case 'complete':
        createGroup = {
          ...createGroup,
          ready:
            createGroup.ready && createGroup.ready.length > 0
              ? [...createGroup.ready, item]
              : [item]
        };
        break;
    }
  });
  return createGroup;
};

export const determinStatus = (status?: string) => {
  let nexStatus = '';

  switch (status) {
    case 'waiting':
      nexStatus = 'prepairing';
      break;
    case 'prepairing':
      nexStatus = 'complete';
      break;
    case 'complete':
      nexStatus = 'pickedUp';
      break;
  }
  return nexStatus;
};
