import Echo from 'laravel-echo';
import { config } from '../config';
import { createBaseUrl } from '../helpers';
window.Pusher = require('pusher-js');

const createPort = () => {
  const port = {
    localIp: '6001',
    local: '6001',
    test: '6002',
    live: '6001'
  };
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_BROADCASTING;
  } else {
    return process.env.REACT_APP_BROADCASTING;
  }
};

const broadcastingConfig = () => {
  const basic = {
    broadcaster: config.broadcasting.broadcaster,
    key: config.broadcasting.key,
    wsHost: createBaseUrl(),
    wsPort: createPort(),
    cluster: config.broadcasting.cluster,
    authEndpoint: config.broadcasting.endpoint,
    auth: {
      headers: {
        Accept: config.broadcasting.accept
      }
    }
  };

  const secure = {
    broadcaster: config.broadcasting.broadcaster,
    key: config.broadcasting.key,
    wsHost: createBaseUrl(),
    wsPort: createPort(),
    wssPort: createPort(),
    cluster: config.broadcasting.cluster,
    authEndpoint: config.broadcasting.endpoint,
    auth: {
      headers: {
        // Authorization: `Bearer ${token}`,
        Accept: config.broadcasting.accept
      }
    },
    //enableStats: true,
    disableStats: true,
    forceTLS: true
    //encrypted: true,
  };

  return config.security === 'https' ? secure : basic;
};
/**
 * Connect to broadcasting service from the backend
 */
export const broadcasting = () => {
  window.Echo = new Echo(broadcastingConfig());
};
