import React from 'react';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';
import {
  IconContainerBase,
  IconContainerCircle,
  IconContainerCircleFull,
  IconContainerSpinning
} from './Icon.style';
import * as GetIcon from './Icon';

interface CrossProps {
  action: Function;
}

export const Cross: React.FC<CrossProps> = ({ action = () => {} }) => {
  return <CrossLayout onClick={() => action()} />;
};

const IconType = {
  base: IconContainerBase,
  circle: IconContainerCircle,
  circleFull: IconContainerCircleFull,
  spinning: IconContainerSpinning
};

interface IconProps {
  action?: Function;
  color?: string;
  open?: boolean;
  type?: keyof typeof IconType;
  icon: string;
  altIcon?: string;
  full?: boolean;
  margin?: string;
  size?: number;
}

export const Icon: React.FC<IconProps> = ({
  action = () => {},
  color,
  open = true,
  type = 'base',
  icon,
  altIcon,
  full,
  margin,
  size = 25
}) => {
  const hasAltIcon = open ? icon : altIcon;
  const getIcon = altIcon ? hasAltIcon : icon;

  const SelectedIcon = IconType[type];

  return (
    <SelectedIcon full={full} onClick={() => action()} margin={margin}>
      <GetIcon.Icon getIcon={getIcon} color={color} size={size} />
    </SelectedIcon>
  );
};

const CrossLayout = styled.button<{ BgColor?: string }>`
  ${({ BgColor }) =>
    css`
      background-color: ${ThemeColors.primaryColor || BgColor};
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  ::before,
  ::after {
    content: ' ';
    display: block;
    width: 4px;
    height: 24px;
    border-radius: 24px;
    background-color: white;
    position: relative;
  }
  ::after {
    transform: rotate(45deg);
    left: -2px;
  }
  ::before {
    transform: rotate(-45deg);
    right: -2px;
  }
  &:focus {
    outline: 0;
  }
`;
