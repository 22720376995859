import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { config, ThemeColors } from '../../config';
import { useGet } from '../../hooks';
import { usePost } from '../../hooks/usePost';
import * as Interface from '../../interfaces';
import ChartCard from '../Charts/ChartCard';
import Input from '../Form/Input';
import List, { ItemContainer } from '../List/List';
import ListItem from '../List/ListItem';
import { Column, Container, MainButton, Row, Warning } from '../shared';
import { Icon } from '../shared/Icons';
import { Title } from '../Styles/Text';

type tagProps = {
  id: number;
  tag: string;
};

const typesProps = {
  alert: 'alert',
  succes: 'succes'
};

type AlertProps = {
  type: keyof typeof typesProps;
  show: boolean;
  message: string;
};

interface EstablishmentProps {
  EstablishmentsData: Interface.Establishment | any;
  setEstablishmentData: Function;
  getEstablishment: Function;
  setAlerts: Function;
}

const Establishment: React.FC<EstablishmentProps> = ({
  EstablishmentsData,
  setEstablishmentData,
  getEstablishment,
  setAlerts
}) => {
  /**
   *
   */
  const getToken = JSON.parse(sessionStorage.getItem('user_token') || '');
  const apiHeaderAuth = (token: string, contentType: string) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}` //token
    };
    return header;
  };
  /**
   * States
   */
  const [warning, setWarning] = useState<AlertProps>({
    show: false,
    type: 'succes',
    message: ''
  });
  const [establishment, setEstablishment] = useState<Interface.Establishment>(
    EstablishmentsData
  );
  const [display, setDisplay] = useState(EstablishmentsData.name);
  const [slug, setSlug] = useState(EstablishmentsData.slug);
  const [image, setImage] = useState<any>({ contentType: '' });
  const [establishmentTags, setEstablishmentTags] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);
  const [searchTags, setSearchTags] = useState('');
  const [selectedTag, setSelectedTag] = useState({});
  const [selectedUpdate, setSelectedUpdate] = useState<any>({
    package: {},
    type: ''
  });
  const [information, setInformation] = useState({
    email: establishment.email,
    address: establishment.address ? establishment.address.address : '',
    house_number: establishment.address
      ? establishment.address.house_number
      : '',
    city: establishment.address ? establishment.address.city : '',
    postcode: establishment.address ? establishment.address.postcode : '',
    phone_number: establishment.address
      ? establishment.address.phone_number
      : ''
  });

  //
  const getEstablishmentTags = useGet(
    {},
    `establishment/${EstablishmentsData.id}/tag/show/all`
  );
  //
  const getTags = useGet({}, `search/tag/${searchTags}`);
  //
  const postAddTag = usePost(
    `establishment/${EstablishmentsData.id}/tag/add`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedTag
  );
  //
  const postRemoveTag = usePost(
    `establishment/${EstablishmentsData.id}/tag/remove`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedTag
  );
  const updateEstablishment = usePost(
    `establishment/${EstablishmentsData.id}/update/${selectedUpdate.type}`,
    apiHeaderAuth(getToken.access_token, 'application/json'),
    selectedUpdate.package
  );

  const updateImageRequest = usePost(
    `establishment/${EstablishmentsData.id}/update/${selectedUpdate.type}`,
    apiHeaderAuth(getToken.access_token, 'multipart/form-data'),
    selectedUpdate.package
  );

  /**
   *  Effects
   */
  useEffect(() => {
    getEstablishment(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEstablishment.postResponse]);
  //
  useEffect(() => {
    getEstablishmentTags.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //
  useEffect(() => {
    if (selectedUpdate.package && selectedUpdate.package.image) {
      updateImageRequest.sendPost(true);
    } else {
      updateEstablishment.sendPost(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUpdate]);

  //
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      getEstablishmentTags.fetchResponse &&
      getEstablishmentTags.fetchResponse.length > 0
    ) {
      setFilteredTags(getEstablishmentTags.fetchResponse);
    }
  }, [getEstablishmentTags.fetchResponse]);
  //
  useEffect(() => {
    if (searchTags.length > 0) {
      getTags.getFetch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTags]);
  //
  useEffect(() => {
    getEstablishmentTags.getFetch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveTag.postResponse, postAddTag.postResponse]);
  //
  useEffect(() => {
    if (EstablishmentsData) {
      setInformation({
        email: EstablishmentsData.email,
        address: EstablishmentsData.address
          ? EstablishmentsData.address.address
          : '',
        house_number: EstablishmentsData.address
          ? EstablishmentsData.address.house_number
          : '',
        city: EstablishmentsData.address ? EstablishmentsData.address.city : '',
        postcode: EstablishmentsData.address
          ? EstablishmentsData.address.postcode
          : '',
        phone_number: EstablishmentsData.address
          ? EstablishmentsData.address.phone_number
          : ''
      });
    }
  }, [EstablishmentsData, setEstablishmentData]);
  /**
   *
   *  Handle responses
   */
  useEffect(() => {
    if (
      updateEstablishment.postResponse &&
      updateEstablishment.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to update your Company information',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      updateEstablishment.errorResponse &&
      updateEstablishment.errorResponse.response &&
      (updateEstablishment.errorResponse.response.status === 401 ||
        updateEstablishment.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: updateEstablishment.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEstablishment.postResponse, updateEstablishment.errorResponse]);
  //
  useEffect(() => {
    if (
      updateImageRequest.postResponse &&
      updateImageRequest.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to update your Company information',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      updateImageRequest.errorResponse &&
      updateImageRequest.errorResponse.response &&
      (updateImageRequest.errorResponse.response.status === 401 ||
        updateImageRequest.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: updateImageRequest.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateImageRequest.postResponse, updateImageRequest.errorResponse]);
  //
  useEffect(() => {
    if (
      postRemoveTag.postResponse &&
      postRemoveTag.postResponse.status === 200
    ) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to remove a tag',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postRemoveTag.errorResponse &&
      postRemoveTag.errorResponse.response &&
      (postRemoveTag.errorResponse.response.status === 401 ||
        postRemoveTag.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postRemoveTag.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postRemoveTag.postResponse, postRemoveTag.errorResponse]);
  //
  useEffect(() => {
    if (postAddTag.postResponse && postAddTag.postResponse.status === 200) {
      const newAlert: any = {
        title: 'Update was an success',
        message: 'Our system was able to add a new tag',
        type: 'success'
      };

      setAlerts(newAlert);
    } else if (
      postAddTag.errorResponse &&
      postAddTag.errorResponse.response &&
      (postAddTag.errorResponse.response.status === 401 ||
        postAddTag.errorResponse.response.status === 500)
    ) {
      const newAlert: any = {
        title: 'Update unsuccessful',
        message: postAddTag.errorResponse.response.data.message,
        type: 'error'
      };

      setAlerts(newAlert);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postAddTag.postResponse, postAddTag.errorResponse]);
  /**
   *
   *
   */
  const setEmail = (e: string) => {
    setInformation({ ...information, email: e });
  };
  const setaddress = (e: string) => {
    setInformation({ ...information, address: e });
  };
  const setHouseNumber = (e: string) => {
    setInformation({ ...information, house_number: e });
  };
  const setCity = (e: string) => {
    setInformation({ ...information, city: e });
  };
  const setPostalcode = (e: string) => {
    setInformation({ ...information, postcode: e });
  };
  const setPhoneNumber = (e: string) => {
    setInformation({ ...information, phone_number: e });
  };
  const searchTag = (e: string) => {
    const filtered = getEstablishmentTags.fetchResponse.filter(
      (tag: tagProps) => {
        return tag.tag.toLowerCase().includes(e.toLowerCase());
      }
    );
    setEstablishmentTags(e);
    setFilteredTags(filtered);
  };
  //
  const removeTag = (e: tagProps) => {
    setSelectedTag(e);
    postRemoveTag.sendPost(true);
  };
  //
  const addTag = (e: tagProps) => {
    setSelectedTag({ tag_id: e.id, establishment_id: EstablishmentsData.id });
    postAddTag.sendPost(true);
  };
  //
  const updateInformation = () => {
    setSelectedUpdate({ type: 'information', package: information });
  };
  //
  const updateDisplayName = () => {
    setSelectedUpdate({ type: 'general', package: { name: display } });
  };
  //
  const updateSlug = () => {
    setSelectedUpdate({ type: 'general', package: { slug: slug } });
  };
  //
  const updateImage = () => {
    setSelectedUpdate({ type: 'image', package: image.file });
  };
  //
  const selectImage = async (imageFile: any) => {
    //
    if (imageFile.target.files[0]) {
      const reader = new FileReader();
      // const newImage = new Blob([imageFile.target.files[0]], {
      //   type: imageFile.target.files[0].type
      // });
      //
      const formData = new FormData();
      formData.append(
        'file',
        imageFile.target.files[0],
        imageFile.target.files[0].name
      );
      //
      reader.readAsDataURL(imageFile.target.files[0]);
      //
      const imageType: string = imageFile.target.files[0].type;
      const imageName: string = imageFile.target.files[0].name;
      const imageSize: number = imageFile.target.files[0].size;
      //
      reader.onloadend = function (e) {
        const result: string | any = reader.result;
        //
        setImage({
          ...image,
          contentType: imageType,
          image_name: imageName,
          data: {
            type: imageType,
            name: imageName,
            size: imageSize
          },
          file: formData
        });
        //
        setEstablishment({
          ...establishment,
          image: result || establishment.image
        });
      };
    }
  };
  //
  const closeWarning = () => {
    setWarning({ ...warning, show: false });
  };
  //
  const validateImage = () => {
    const imageElement = document.getElementById(
      'upload_image'
    ) as HTMLImageElement;
    let valid: boolean = false;
    let errors: string[] = [];
    let warnings: string[] = [];

    if (image.data) {
      if (image.data.size < 2500000) {
        valid = true;
      } else {
        errors = [...errors, 'File size is to big, max size is 2.5MB'];
      }
    }

    if (
      image.data &&
      imageElement &&
      imageElement.naturalWidth &&
      imageElement.naturalWidth > 1200
    ) {
      warnings = [
        ...warnings,
        "we recommend you don't use an element wider then 1200px"
      ];
    }
    if (
      image.data &&
      imageElement &&
      imageElement.naturalHeight &&
      imageElement.naturalHeight > 800
    ) {
      warnings = [
        ...warnings,
        "we recommend you don't use an element heigher then 800px"
      ];
    }
    return {
      valid: valid,
      error: errors,
      warning: warnings
    };
  };

  return (
    <Container>
      {warning.show && (
        <Warning type={warning.type} close={() => closeWarning()}>
          {warning.message}
        </Warning>
      )}
      <Row AItems="flex-start">
        <Column width="50%" margin="0 8px 0 0">
          <ChartCard title="Display name" width="100%">
            <Row JContent="space-between" AItems="flex-end">
              <Input
                inputValue={display}
                setInputValue={setDisplay}
                placeholder="Your company name"
                label="Companies display name"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="60%"
                labelColor={ThemeColors.primaryColor}
                disabled={true}
              />
              <MainButton
                type="mainSmall"
                action={() => updateDisplayName()}
                disabled={true}
              >
                {updateEstablishment.postLoading ? (
                  <Icon full={true} type="spinning" icon="Spinner" />
                ) : (
                  'Update'
                )}
              </MainButton>
            </Row>
          </ChartCard>
          <ChartCard title="Information" width="100%">
            <Row JContent="flex-start">
              <Input
                inputValue={information.email}
                setInputValue={setEmail}
                placeholder="yourcompany@email.com"
                label="Email"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="100%"
                labelColor={ThemeColors.primaryColor}
              />
            </Row>
            <Row JContent="space-between">
              <Input
                inputValue={information.address}
                setInputValue={setaddress}
                placeholder="Enter your companies address"
                label="address"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="65%"
                labelColor={ThemeColors.primaryColor}
              />
              <Input
                inputValue={information.house_number}
                setInputValue={setHouseNumber}
                placeholder="Your companies house number"
                label="House number"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="30%"
                labelColor={ThemeColors.primaryColor}
              />
            </Row>
            <Row JContent="space-between">
              <Input
                inputValue={information.city}
                setInputValue={setCity}
                placeholder="New York City"
                label="City"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="65%"
                labelColor={ThemeColors.primaryColor}
              />
              <Input
                inputValue={information.postcode}
                setInputValue={setPostalcode}
                placeholder="1234AB"
                label="Postalcode"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="30%"
                labelColor={ThemeColors.primaryColor}
              />
            </Row>
            <Row JContent="flex-start">
              <Input
                inputValue={information.phone_number}
                setInputValue={setPhoneNumber}
                placeholder="+31 44 333 443"
                label="Phone number"
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="100%"
                labelColor={ThemeColors.primaryColor}
              />
            </Row>
            <Row margin="16px 0">
              <MainButton type="mainSmall" action={() => updateInformation()}>
                {updateEstablishment.postLoading ? (
                  <Icon full={true} type="spinning" icon="Spinner" />
                ) : (
                  'Update'
                )}
              </MainButton>
            </Row>
          </ChartCard>
          <ChartCard title="Tags" width="100%">
            <Input
              inputValue={establishmentTags}
              setInputValue={searchTag}
              placeholder="Search for a tag"
              label="Tags"
              labelColor={ThemeColors.primaryColor}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              containerWidth="100%"
            />
            <List headerItems={['name', '']}>
              {filteredTags.length > 0 &&
                filteredTags.map((item: tagProps) => (
                  <ItemContainer>
                    <ListItem>{item.tag}</ListItem>
                    <Icon icon="MinusCircle" action={() => removeTag(item)} />
                  </ItemContainer>
                ))}
            </List>
          </ChartCard>
        </Column>
        <Column width="50%" margin="0 0 0 8px">
          <ChartCard title="Slug" width="100%">
            <Row JContent="space-between" AItems="flex-end">
              <Input
                inputValue={slug}
                setInputValue={setSlug}
                placeholder="your-company-name"
                label="Your sites url"
                labelColor={ThemeColors.primaryColor}
                // eslint-disable-next-line react/style-prop-object
                style="full"
                containerWidth="60%"
                disabled={true}
              />
              <MainButton
                type="mainSmall"
                action={() => updateSlug()}
                disabled={true}
              >
                {updateEstablishment.postLoading ? (
                  <Icon full={true} type="spinning" icon="Spinner" />
                ) : (
                  'Update'
                )}
              </MainButton>
            </Row>
          </ChartCard>
          <ChartCard title="Image (recommend 1200x720)" width="100%">
            <Column>
              <Title>Upload an image</Title>
              <ul>
                {validateImage().error.map(item => {
                  return <ErrorItem>* {item}</ErrorItem>;
                })}
                {validateImage().warning.map(item => (
                  <WarningItem>* {item}</WarningItem>
                ))}
              </ul>

              <Image
                id="upload_image"
                src={
                  establishment.image
                    ? establishment.image
                    : '/assets/svg/image.svg'
                }
              />
              <Row JContent="center" margin="16px 0">
                <input
                  type="file"
                  id="myFile"
                  name="filename"
                  accept="image/*"
                  onChange={e => selectImage(e)}
                />
              </Row>

              <Row JContent="center" margin="16px 0">
                <MainButton
                  type="mainSmall"
                  action={() => updateImage()}
                  disabled={!validateImage().valid}
                >
                  {updateEstablishment.postLoading ? (
                    <Icon full={true} type="spinning" icon="Spinner" />
                  ) : (
                    'Update'
                  )}
                </MainButton>
              </Row>
            </Column>
          </ChartCard>
          <ChartCard title="Add tags" width="100%">
            <Input
              inputValue={searchTags}
              setInputValue={setSearchTags}
              placeholder="Search for a tag"
              label="Tags"
              labelColor={ThemeColors.primaryColor}
              // eslint-disable-next-line react/style-prop-object
              style="full"
              containerWidth="100%"
              //disabled={true}
            />
            <List headerItems={['name']}>
              {getTags.fetchResponse &&
                getTags.fetchResponse.length > 0 &&
                getTags.fetchResponse.map((item: tagProps) => {
                  const findTag = filteredTags.find(
                    (tag: tagProps) => tag.id === item.id
                  );
                  return (
                    <ItemContainer>
                      <ListItem>{item.tag}</ListItem>
                      {!findTag ? (
                        <Icon icon="PlusCircle" action={() => addTag(item)} />
                      ) : (
                        '(already added)'
                      )}
                    </ItemContainer>
                  );
                })}
            </List>
          </ChartCard>
        </Column>
      </Row>
    </Container>
  );
};

export default Establishment;

const Image = styled.img`
  width: 100%;
`;

const ErrorItem = styled.li`
  color: red;
`;

const WarningItem = styled.li`
  color: orange;
`;
