import { OrderProps } from '../../interfaces/Order';
import { errorResponse } from './Establishment.Reducer';

export type OrderReducerPayload = {
  loading: boolean;
  error: boolean;
  errorResponse: errorResponse;
  response: OrderProps[] | [];
  firstLoad: boolean;
};

export type OrderReducerProps = {
  payload: OrderReducerPayload;
  action?: string;
  type?: string;
};

const initialState: OrderReducerPayload = {
  loading: false,
  error: false,
  errorResponse: { message: '' },
  response: [],
  firstLoad: true
};

const Orders = (state = initialState, action: OrderReducerProps) => {
  switch (action.type) {
    case 'SAVE_ORDERS': {
      state = { ...state, ...action.payload, firstLoad: false };
      return state;
    }
    default:
      return state;
  }
};

export default Orders;
