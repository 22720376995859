import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColors } from '../../config';

interface CardProps {
  active?: boolean;
  headerContent?: Function;
  collapseContent?: Function;
  type?: string;
  bgImage?: String;
  hoverEffect?: string;
}

const Card: React.FC<CardProps> = ({
  active,
  headerContent,
  collapseContent,
  type = 'order',
  bgImage,
  children,
  hoverEffect
}) => {
  const [open, setOpen] = useState(false);

  const cardTypes: any = {
    default: CardBase,
    order: CardOrder,
    Image: CardImage,
    tile: CardTile
  };

  const CardType = cardTypes[type];

  return (
    <CardType
      active={active}
      onClick={() => setOpen(!open)}
      bgImage={bgImage}
      hoverEffect={hoverEffect}
      className="card"
    >
      {headerContent && <CardHeader>{headerContent()}</CardHeader>}
      {children && children}
      {collapseContent && (
        <CardCollapse open={open}>{collapseContent()}</CardCollapse>
      )}
    </CardType>
  );
};

export default Card;

const CardBase = styled.div<{ hoverEffect: string }>`
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  background-color: ${ThemeColors.primaryColor};
  border-radius: 4px;
  flex-direction: column;
  ${props =>
    props.hoverEffect === 'fadeIn' &&
    css`
      &:hover {
        .card__overlay {
          opacity: 0;
        }
      }
    `}
`;

const CardOrder = styled(CardBase)<{ active: boolean }>`
  justify-content: space-around;
  background-color: #011627;
  padding: 8px 16px;
  width: 100%;
  margin: 16px 0;
  ${({ active }) =>
    active &&
    css`
      border: solid 4px #8bbf9f;
    `};
`;

const CardImage = styled(CardBase)<{ bgImage: string }>`
  background-image: url(${props => props.bgImage});
  background-size: cover;
  min-width: 200px;
  height: 200px;
  margin: 8px;
`;

const CardTile = styled(CardBase)`
  justify-content: center;
  align-items: center;
  margin: 8px;
  padding: 8px;
  color: white;
  min-width: 200px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
`;

const CardCollapse = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 0px;
  overflow: hidden;
  flex-direction: row;
  transition: max-height 1s ease;
  width: 100%;

  span {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  ${({ open }) =>
    open &&
    css`
      max-height: 500px;
    `};
`;
