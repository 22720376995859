import { useState } from 'react';
import { SeatProps } from '../../interfaces/Seat';

const useEditSeat = (seat: SeatProps) => {
  /**
   * set states
   */
  const [name, setName] = useState(seat.name || 'none');
  const [seats, setSeats] = useState(seat.seats || '0');
  const [active, setActive] = useState(
    seat.active || seat.active === 1 ? 1 : 0
  );

  return { name, setName, seats, setSeats, active, setActive };
};

export default useEditSeat;
